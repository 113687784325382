import React, { useCallback, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CardsSearchFilters, SearchBar } from 'components/core';
import { useDivRef, useGalleryClass } from 'hooks';
import { cardsActions, RootState } from 'store';

import './styles.scss';

type Props = ConnectedProps<typeof connector> & {
  styles?: string[];
  toggleCallback?: () => void;
};

const CardsSearchBar: React.FC<Props> = ({
  mostSearchedCards,
  mostViewedCards,
  searchFiltersShown,
  searching,
  styles = [],
  getCards,
  toggleCallback,
  toggleSearchFilters,
}) => {
  const navigate = useNavigate();

  useGalleryClass(
    !searchFiltersShown &&
      (mostSearchedCards.length > 0 || mostViewedCards.length > 0)
  );

  const ref = useDivRef();

  useEffect(() => {
    if (ref?.current) {
      if (searchFiltersShown) {
        if (!ref.current.classList.contains('filters')) {
          ref.current.classList.add('filters');
        }
      } else {
        if (ref.current.classList.contains('filters')) {
          ref.current.classList.remove('filters');
        }
      }
    }
  }, [ref, searchFiltersShown]);

  const callback = useCallback(() => navigate('/search'), [navigate]);

  const searchCards = useCallback(
    (keywords: string) => {
      getCards({
        page: 0,
        query: [
          { name: 'artist', value: keywords },
          { name: 'flavorText', value: keywords },
          { name: 'name', value: keywords },
          { name: 'text', value: keywords },
        ],
      });
    },
    [getCards]
  );

  const toggleAdvancedFilters = useCallback(() => {
    toggleCallback?.();
    toggleSearchFilters();
  }, [toggleCallback, toggleSearchFilters]);

  return (
    <div
      className={`cardsSearchBar${styles.length > 0 ? ` ${styles.join(' ')}` : ''}`}
    >
      <SearchBar
        callback={callback}
        placeholder="Enter card name, flavor text, text or artist name"
        ref={ref}
        search={searchCards}
        SearchFilterPopup={CardsSearchFilters}
        searching={searching}
        searchFiltersShown={searchFiltersShown}
        toggleSearchFilters={toggleAdvancedFilters}
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  mostSearchedCards: state.events.mostSearchedCards?.data ?? [],
  mostViewedCards: state.events.mostViewedCards?.data ?? [],
  searchFiltersShown: state.cards.searchFiltersShown,
  searching: state.cards.searching,
});

const mapDispatchToProps = {
  getCards: cardsActions.getCards,
  toggleSearchFilters: cardsActions.toggleSearchFilters,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(CardsSearchBar);
