import { compact } from 'lodash';
import { RESPONSE_CODES } from 'appConstants';
import { QueryParams, Response } from 'types';

/**
 * Gets the an object or Response type with the provided error message
 * @param message
 * @returns a Response type object
 */
export const getFailure = <T>(message: string) => {
  return {
    code: RESPONSE_CODES.FAILED,
    message,
    data: null,
  } as Response<T>;
};

/**
 * Returns the query string derived from the QueryParams object.
 * @param params a Query params object from which the query string would be derived.
 * @returns a query string containing they query params key-value pair.
 */
export const getQueryString = (params: QueryParams) => {
  if (params.length === 0) {
    return '';
  }
  const encodedParams = compact(
    params.map((queryParam, i) =>
      !queryParam.value
        ? null
        : i === 0
          ? `${queryParam.name}=${encodeURIComponent(queryParam.value)}`
          : `&${queryParam.name}=${encodeURIComponent(queryParam.value)}`
    )
  );
  return encodedParams.length === 0 ? '' : `?${encodedParams.join('')}`;
};

/**
 * Returns an object containing the query params key-value pairs extracted from a query string.
 * @param queryString a string containing the query params key-value pair.
 * @returns an object containing the query params key-value pair.
 */
export const parseQueryString = (queryString?: string) => {
  if (queryString && !queryString.includes('?')) {
    return {}; // Handle malformed query strings
  }

  const result: { [key: string]: string } = {};
  const queryParams = (!queryString ? window.location.search : queryString)
    .replace(/^\?/, '')
    .split('&'); // Remove "?" and split by "&"

  for (let i = 0; i < queryParams.length; i++) {
    const pair = queryParams[i].split('='); // Split key=value pairs
    if (pair.length === 2) {
      const key = decodeURIComponent(pair[0]);
      const value = decodeURIComponent(pair[1]);
      result[key] = value;
    }
  }

  return result;
};
