import { CARD_SYMBOLS } from 'config';

import { getArrayOfUniqueStrings } from './arrays';

/**
 * Escapes strings with regex characters with backslashes
 * @param value - A string that may or may not contain regex special characters.
 * @returns The string with escaped regex characters.
 */
export const escapeRegExp = (value: string) =>
  value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape special characters

/**
 * Converts a string, which may contains card symbols, to its equivalent ReactNode.
 * @param value - A string that may contain card symbols
 * @returns A React.Node with the converted card symbols to icons, if any.
 */
export const convertSymbolsToIcons = (value: string | null) => {
  if (!value?.trim()) {
    return '';
  }

  const regex =
    /\{(?:T|Q|E|P|PW|CHAOS|A|TK|X|Y|Z|0|½|[1-9]|1[0-9]|20|100|1000000|∞|W\/U|W\/B|B\/R|B\/G|U\/B|U\/R|R\/G|R\/W|G\/W|G\/U|B\/G\/P|B\/R\/P|G\/U\/P|G\/W\/P|R\/G\/P|R\/W\/P|U\/B\/P|U\/R\/P|W\/B\/P|W\/U\/P|C\/W|C\/U|C\/B|C\/R|C\/G|2\/W|2\/U|2\/B|2\/R|2\/G|H|W\/P|U\/P|B\/P|R\/P|G\/P|C\/P|HW|HR|W|U|B|R|G|C|S|L|D)\}/g;
  const matchedSymbols = value.match(regex);
  if (!matchedSymbols) {
    return `${value.replace(/\n/g, '<br />')}`;
  }
  const uniqueSymbols =
    matchedSymbols.length > 1
      ? getArrayOfUniqueStrings(matchedSymbols)
      : matchedSymbols;
  return uniqueSymbols.reduce(
    (previousNodeString, value) => {
      const match = CARD_SYMBOLS.find((x) => x.symbol === value);
      if (!match) {
        return previousNodeString;
      }
      const replaced = previousNodeString.replace(
        new RegExp(escapeRegExp(match.symbol), 'g'),
        `<span class="cardSymbol" style="background-image: url('${match.svg}')"></span>`
      );
      return replaced;
    },
    `<span>${value.replace(/\n/g, '<br />')}</span>`
  );
};
