import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useLocation, Navigate } from 'react-router-dom';
import { RootState } from 'store/configureStore';

type Props = ConnectedProps<typeof connector>;

const RequireAuth: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  token,
  user,
}) => {
  const location = useLocation();

  if (!(user && token)) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

const mapStateToProps = (state: RootState) => ({
  token: state.users.token,
  user: state.users.user,
});

const connector = connect(mapStateToProps);

export default connector(RequireAuth);
