import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useMatch, useNavigate } from 'react-router-dom';
import { SEARCH_CONSTANTS } from 'appConstants';
import {
  Button,
  CardImage,
  CardsPagination,
  CardsSearchBar,
  Footer,
  Loader,
} from 'components/core';
import {
  DivRefProvider,
  useErrorMessage,
  usePrevious,
  useScrollTop,
} from 'hooks';
import { cardsActions, RootState } from 'store';

import './styles.scss';

type Props = ConnectedProps<typeof connector>;

const CardsSearchResults: React.FC<Props> = ({
  searching,
  previousSearchRequest,
  searchFiltersShown,
  searchRequest,
  searchTotal,
  searchResult,
  getCards,
  setCards,
}) => {
  const navigate = useNavigate();

  const { isScrolling } = useScrollTop();

  const previousIsScrolling = usePrevious(isScrolling);

  const ref = useRef<HTMLDivElement | null>(null);

  const onCloseErrorMessage = useCallback(
    () => navigate('/', { replace: true }),
    [navigate]
  );

  useErrorMessage('cards', onCloseErrorMessage);

  const match = useMatch({ path: '/search', end: true });

  const hasFetchedPrevious = useRef(false);

  const [searchBarShown, setSearchBarShown] = useState(searchFiltersShown);

  const { data: cards = [] } = searchResult ?? {};

  useEffect(() => {
    if (searchRequest === null) {
      navigate('/', { replace: true });
    }
  }, [searchRequest, navigate]);

  useEffect(() => {
    if (match && previousSearchRequest && !hasFetchedPrevious.current) {
      setCards(null);
      hasFetchedPrevious.current = true;
      getCards({ ...previousSearchRequest, usePreviousRequest: true });
    }
  }, [match, previousSearchRequest, getCards, setCards]);

  const onToggleSearch = useCallback(
    () => setSearchBarShown((prev) => !prev),
    []
  );

  const onPressAdvancedFilters = useCallback(() => {
    document.querySelector('#app')?.scrollTo({ top: 0 });
  }, []);

  return (
    <>
      <div className="cardsSearchResults">
        <>
          {!searchFiltersShown && !isScrolling && previousIsScrolling && (
            <Button onClick={onToggleSearch} styles={['searchButton']}>
              <span className="searchIcon icon-search" />
            </Button>
          )}
          {searchBarShown && (
            <DivRefProvider value={ref}>
              <CardsSearchBar
                styles={!searchFiltersShown ? ['simple'] : undefined}
                toggleCallback={onPressAdvancedFilters}
              />
            </DivRefProvider>
          )}
          <div
            className={`container${!searchFiltersShown ? '' : ' advancedSearch'}`}
          >
            {searchTotal > SEARCH_CONSTANTS.FETCH_COUNT ? (
              <CardsPagination styles={['topPagination']} />
            ) : (
              <div className="pagination topPagination" />
            )}
            <div className="resultsContainer">
              {cards.map((card) => (
                <div className="column" key={card.uuid}>
                  <CardImage card={card} />
                </div>
              ))}
            </div>
            {searchTotal > SEARCH_CONSTANTS.FETCH_COUNT ? (
              <CardsPagination styles={['bottomPagination']} />
            ) : (
              <div className="pagination bottomPagination" />
            )}
          </div>
          <Footer />
        </>
      </div>
      {searching && <Loader type="screen" />}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  searching: state.cards.searching,
  previousSearchRequest: state.cards.previousSearchRequest,
  searchFiltersShown: state.cards.searchFiltersShown,
  searchRequest: state.cards.searchRequest,
  searchResult: state.cards.searchResult,
  searchTotal: state.cards.searchResult?.searchTotal ?? 0,
});

const mapDispatchToProps = {
  getCards: cardsActions.getCards,
  setCards: cardsActions.setCards,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(CardsSearchResults);
