import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { CardsGallery } from 'components/core';
import { useGalleryClass } from 'hooks';
import { eventsActions, RootState } from 'store';

type Props = ConnectedProps<typeof connector>;

const MostViewedCards: React.FC<Props> = ({
  fetching,
  lastMostViewedCardsFetch,
  mostViewedCards,
  searchFiltersShown,
  getMostViewedCards,
}) => {
  useGalleryClass(mostViewedCards.length > 0 && !searchFiltersShown);

  useEffect(() => {
    getMostViewedCards(lastMostViewedCardsFetch);
  }, [lastMostViewedCardsFetch, getMostViewedCards]);

  return (
    <CardsGallery
      cards={mostViewedCards}
      fetching={fetching}
      label="Trending Cards"
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  fetching: state.events.fetchingMostViewedCards,
  lastMostViewedCardsFetch: state.events.lastMostViewedCardsFetch,
  mostViewedCards: state.events.mostViewedCards?.data ?? [],
  searchFiltersShown: state.cards.searchFiltersShown,
});

const mapDispatchToProps = {
  getMostViewedCards: eventsActions.getMostViewedCards,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(MostViewedCards);
