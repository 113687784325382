import React, { memo } from 'react';

import './styles.scss';

type Props = {
  height?: number;
  startTimestamp?: number;
  title: string;
  videoId: string;
  width?: number;
};

const YouTubeIframe: React.FC<Props> = ({
  height = 197,
  startTimestamp = 0,
  title,
  videoId,
  width = 350,
}) => (
  <iframe
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
    className="youTubeIframe"
    height={height}
    loading="lazy"
    referrerPolicy="no-referrer"
    src={`https://www.youtube.com/embed/${videoId}?start=${startTimestamp}`}
    title={title}
    width={width}
  />
);

export default memo(YouTubeIframe);
