import { load } from '@fingerprintjs/fingerprintjs';

/**
 * Gets the unique device ID that can be used for tracking events (views, searches).
 * @returns The unique device ID.
 */
export const getDeviceFingerprint = async (): Promise<string> => {
  const fp = await load();
  const result = await fp.get();
  return result.visitorId; // Unique device fingerprint
};
