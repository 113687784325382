import { isEqual } from 'lodash';
import React, { memo } from 'react';

import './styles.scss';

type Props = {
  current: number;
  disabled?: boolean;
  nextGroupDisabled?: boolean;
  previousGroupDisabled?: boolean;
  styles?: string[];
  total: number;
  onFirst: () => void;
  onLast: () => void;
  onNext: () => void;
  onPrevious: () => void;
};

const Pagination: React.FC<Props> = ({
  current,
  disabled = false,
  nextGroupDisabled = false,
  previousGroupDisabled = false,
  styles = [],
  total,
  onFirst,
  onLast,
  onNext,
  onPrevious,
}) => {
  return (
    <div
      className={`pagination${styles.length > 0 ? ` ${styles.join(' ')}` : ''}`}
    >
      <div className="buttonGroup left">
        <button disabled={disabled || previousGroupDisabled} onClick={onFirst}>
          <span>&laquo;</span>
        </button>
        <button
          disabled={disabled || previousGroupDisabled}
          onClick={onPrevious}
        >
          <span>&lt; Previous</span>
        </button>
      </div>
      <div className="indicators">
        <span>{current}</span>
        <span>/</span>
        <span>{total}</span>
      </div>
      <div className="buttonGroup right">
        <button disabled={disabled || nextGroupDisabled} onClick={onNext}>
          <span>Next &gt;</span>
        </button>
        <button disabled={disabled || nextGroupDisabled} onClick={onLast}>
          <span>&raquo;</span>
        </button>
      </div>
    </div>
  );
};

export default memo(Pagination, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps)
);
