import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';
import { ERROR_MESSAGES, RESPONSE_CODES } from 'appConstants';
import {
  getUserById,
  login,
  logout,
  refresh,
  register,
  update,
} from 'services';
import { usersActions } from 'store';
import {
  AuthenticatedUser,
  GetUserPayload,
  Response,
  UpdateUserPayload,
  User,
} from 'types';

/**
 * Saga generator function called to get a user.
 * @param {PayloadAction<GetUserPayload>} data - a PayloadAction object containing a user's access token and ID.
 * @returns {Response} Response object.
 */
export function* getUser(data: PayloadAction<GetUserPayload>) {
  const { payload } = data;

  try {
    yield put(usersActions.request());

    const response: Response<User> = yield call(getUserById, { payload });
    const { code, data } = response;
    if (code === RESPONSE_CODES.SUCCESS && !!data) {
      yield put(usersActions.setUser(data as User));
      return response;
    }

    const { message } = response;
    yield put(usersActions.failure(message));
    return response;
  } catch (error) {
    yield put(usersActions.failure(ERROR_MESSAGES.UNKNOWN_ERROR));
    console.log('[DEBUG:SAGAS] getUser', error);
    return null;
  }
}

/**
 * Saga generator function called to log in a user.
 * @param {PayloadAction<User>} credentials - a PayloadAction object containing a user's credentials including password and email/username.
 * @returns {Response} Response object.
 */
export function* loginUser(credentials: PayloadAction<User>) {
  try {
    yield put(usersActions.request());

    const response: Response<AuthenticatedUser> = yield call(
      login,
      credentials
    );
    const { code, data } = response;
    if (code === RESPONSE_CODES.SUCCESS && !!data) {
      if (!!data && (data as AuthenticatedUser)) {
        const tokens = data as AuthenticatedUser;
        yield put(usersActions.setTokens({ ...tokens }));
        const { token } = tokens;
        if (token && credentials.payload.id) {
          yield getUser({
            payload: {
              token,
              userId: credentials.payload.id,
            },
            type: usersActions.getUser.type,
          });
        }
      }
      return response;
    }

    const { message } = response;
    yield put(usersActions.failure(message));
    return response;
  } catch (error) {
    yield put(usersActions.failure(ERROR_MESSAGES.UNKNOWN_ERROR));
    console.log('[DEBUG:SAGAS] loginUser', error);
    return null;
  }
}

/**
 * Saga generator function called to log out a user.
 * @param {PayloadAction<string>} data - a PayloadAction object containing an access token.
 * @returns {Response} Response object.
 */
export function* logoutUser(data: PayloadAction<string>) {
  const { payload } = data;

  try {
    yield put(usersActions.request());

    const response: Response<User> = yield call(logout, { payload });
    const { code, data } = response;
    if (code === RESPONSE_CODES.SUCCESS && !!data) {
      yield put(usersActions.setTokens({ refreshToken: null, token: null }));
      return response;
    }

    const { message } = response;
    yield put(usersActions.failure(message));
    return response;
  } catch (error) {
    yield put(usersActions.failure(ERROR_MESSAGES.UNKNOWN_ERROR));
    console.log('[DEBUG:SAGAS] logoutUser', error);
    return null;
  }
}

/**
 * Saga generator function called to refresh a user's existing refresh token.
 * @param {PayloadAction<string>} data - a PayloadAction object containing an existing refresh token.
 * @returns {Response} Response object.
 */
export function* refreshToken(data: PayloadAction<string>) {
  const { payload } = data;

  try {
    yield put(usersActions.request());

    const response: Response<AuthenticatedUser> = yield call(refresh, {
      payload,
    });
    const { code, data } = response;
    if (code === RESPONSE_CODES.SUCCESS && !!data) {
      yield put(usersActions.setTokens(data as AuthenticatedUser));
      return response;
    }

    const { message } = response;
    yield put(usersActions.failure(message));
    return response;
  } catch (error) {
    yield put(usersActions.failure(ERROR_MESSAGES.UNKNOWN_ERROR));
    console.log('[DEBUG:SAGAS] refreshToken', error);
    return null;
  }
}

/**
 * Saga generator function called to register a user.
 * @param {PayloadAction<User>} data - a PayloadAction object containing a user's registration information.
 * @returns {Response} Response object.
 */
export function* registerUser(data: PayloadAction<User>) {
  const { payload } = data;

  try {
    yield put(usersActions.request());

    const response: Response<User> = yield call(register, { payload });
    const { code, data } = response;
    if (code === RESPONSE_CODES.SUCCESS) {
      yield put(usersActions.setUser(data as User));
      return response;
    }

    const { message } = response;
    yield put(usersActions.failure(message));
  } catch (error) {
    yield put(usersActions.failure(ERROR_MESSAGES.UNKNOWN_ERROR));
    console.log('[DEBUG:SAGAS] registerUser', error);
    return null;
  }
}

/**
 * Saga generator function called to update a user.
 * @param {PayloadAction<UpdateUserPayload>} data - a PayloadAction object containing a user's previous and updated information.
 * @returns {Response} Response object.
 */
export function* updateUser(data: PayloadAction<UpdateUserPayload>) {
  const { payload } = data;

  try {
    yield put(usersActions.request());

    const response: Response<User> = yield call(update, { payload });
    const { code, data } = response;
    if (code === RESPONSE_CODES.SUCCESS) {
      yield put(usersActions.setUser(data as User));
      return response;
    }

    const { message } = response;
    yield put(usersActions.failure(message));
  } catch (error) {
    yield put(usersActions.failure(ERROR_MESSAGES.UNKNOWN_ERROR));
    console.log('[DEBUG:SAGAS] updateUser', error);
    return null;
  }
}
