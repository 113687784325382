import { MouseEventHandler } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Params } from 'react-router-dom';

export type GridConfig = {
  componentName: string;
  props: Params<string>;
};

/* eslint-disable no-unused-vars */
export enum MODAL_TYPES {
  COMPONENT_MODAL,
  MESSAGE_MODAL,
  PROMPT_MODAL,
}

export interface ComponentModalState {
  contentWrapStyles?: string[];
  gridConfig: GridConfig;
  isHeaderHidden?: boolean;
  isPopupOnly?: boolean;
  modalStyles?: string[];
  redirectUrl?: string;
  shown?: boolean;
  title: string;
}

export interface MessageModalState {
  title: string;
  message: string;
  shown?: boolean;
  cbClose?: () => void;
}

export interface PromptModalState {
  message: string;
  shown?: boolean;
  textNo?: string;
  textYes?: string;
  title: string;
  cbOk?: MouseEventHandler<HTMLButtonElement>;
}

// Define a type for the slice state
export type ModalState = {
  componentModal: ComponentModalState;
  messageModal: MessageModalState;
  promptModal: PromptModalState;
};

// Define the initial state using that type
const initialState: ModalState = {
  componentModal: {} as ComponentModalState,
  messageModal: {} as MessageModalState,
  promptModal: {} as PromptModalState,
};

export const modalSlices = createSlice({
  name: 'modal',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    renderComponentModal: (
      state,
      action: PayloadAction<ComponentModalState>
    ) => {
      const { payload } = action;
      const {
        contentWrapStyles,
        gridConfig,
        isHeaderHidden,
        isPopupOnly = false,
        modalStyles,
        redirectUrl,
        title,
      } = payload;
      const { componentModal } = state;
      return {
        ...state,
        componentModal: {
          ...componentModal,
          contentWrapStyles,
          gridConfig,
          isHeaderHidden,
          isPopupOnly,
          modalStyles,
          redirectUrl,
          shown: true,
          title,
        },
      };
    },
    updateComponentModalTitle: (
      state,
      action: PayloadAction<ComponentModalState>
    ) => {
      const { payload } = action;
      const { title } = payload;
      const { componentModal } = state;
      return {
        ...state,
        componentModal: {
          ...componentModal,
          title,
        },
      };
    },
    renderMessageModal: (state, action: PayloadAction<MessageModalState>) => {
      const { payload } = action;
      const { message, title, cbClose } = payload;
      const { messageModal } = state;
      return {
        ...state,
        messageModal: {
          ...messageModal,
          message,
          shown: true,
          title,
          cbClose,
        },
      };
    },
    renderPromptModal: (state, action: PayloadAction<PromptModalState>) => {
      const { payload } = action;
      const { cbOk, message, textNo, textYes, title } = payload;
      const { promptModal } = state;
      return {
        ...state,
        promptModal: {
          ...promptModal,
          cbOk: cbOk,
          message,
          shown: true,
          textNo,
          textYes,
          title,
        },
      };
    },
    hideComponentModal: (state) => {
      state.componentModal.shown = false;
    },
    hideMessageModal: (state) => {
      state.messageModal.shown = false;
    },
    hidePromptModal: (state) => {
      state.promptModal.shown = false;
    },
  },
});

export const modalActions = {
  ...modalSlices.actions,
};

export default modalSlices.reducer;
