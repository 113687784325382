import React from 'react';

export type RouteParams = {
  cardDetails: {
    side?: string;
    uuid: string;
  };
};

const routeParamsContext = React.createContext<RouteParams>({} as RouteParams);
export const { Provider: RouteParamsProvider } = routeParamsContext;
export const useRouteParams = () => React.useContext(routeParamsContext);
