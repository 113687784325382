import { call, put } from 'redux-saga/effects';
import { ERROR_MESSAGES, RESPONSE_CODES } from 'appConstants';
import { getSearchedCards, getViewedArticles, getViewedCards } from 'services';
import { Articles, Cards, eventsActions } from 'store';
import { Response, SearchResult } from 'types';
import { PayloadAction } from '@reduxjs/toolkit';

/**
 * Saga generator function called to get the most searched cards.
 * @param {PayloadAction<number | null>} data - a PayloadAction object containing the
 * timestamp, which indicates the last time that this function is called.
 * @returns {Response} Response object.
 */
export function* getMostSearchedCards(data: PayloadAction<number | null>) {
  const { payload } = data;
  if (!checkShouldFetch(payload)) {
    return;
  }

  try {
    yield put(eventsActions.requestMostSearchedCards());

    const response: Response<SearchResult<Cards>> =
      yield call(getSearchedCards);
    const { code, data } = response;
    if (code === RESPONSE_CODES.SUCCESS && !!data && typeof data === 'object') {
      const cards = data as SearchResult<Cards>;
      yield put(
        eventsActions.setMostSearchedCards({
          ...cards,
        })
      );
      return response;
    }

    const { message } = response;
    if (!data && message !== 'Success') {
      yield put(eventsActions.failure(message));
    }
    return response;
  } catch (error) {
    yield put(eventsActions.failure(ERROR_MESSAGES.UNKNOWN_ERROR));
    console.log('[DEBUG:SAGAS] getMostSearchedCards', error);
    return null;
  }
}

/**
 * Saga generator function called to get the most viewed articles.
 * @param {PayloadAction<number | null>} data - a PayloadAction object containing the
 * timestamp, which indicates the last time that this function is called.
 * @returns {Response} Response object.
 */
export function* getMostViewedArticles(data: PayloadAction<number | null>) {
  const { payload } = data;
  if (!checkShouldFetch(payload)) {
    return;
  }

  try {
    yield put(eventsActions.requestMostViewedArticles());

    const response: Response<SearchResult<Articles>> =
      yield call(getViewedArticles);
    const { code, data } = response;
    if (code === RESPONSE_CODES.SUCCESS && !!data && typeof data === 'object') {
      const articles = data as SearchResult<Articles>;
      yield put(
        eventsActions.setMostViewedArticles({
          ...articles,
        })
      );
      return response;
    }

    const { message } = response;
    if (!data && message !== 'Success') {
      yield put(eventsActions.failure(message));
    }
    return response;
  } catch (error) {
    yield put(eventsActions.failure(ERROR_MESSAGES.UNKNOWN_ERROR));
    console.log('[DEBUG:SAGAS] getMostViewedArticles', error);
    return null;
  }
}

/**
 * Saga generator function called to get the most viewed cards.
 * @param {PayloadAction<number | null>} data - a PayloadAction object containing the
 * timestamp, which indicates the last time that this function is called.
 * @returns {Response} Response object.
 */
export function* getMostViewedCards(data: PayloadAction<number | null>) {
  const { payload } = data;
  if (!checkShouldFetch(payload)) {
    return;
  }

  try {
    yield put(eventsActions.requestMostViewedCards());

    const response: Response<SearchResult<Cards>> = yield call(getViewedCards);
    const { code, data } = response;
    if (code === RESPONSE_CODES.SUCCESS && !!data && typeof data === 'object') {
      const cards = data as SearchResult<Cards>;
      yield put(
        eventsActions.setMostViewedCards({
          ...cards,
        })
      );
      return response;
    }

    const { message } = response;
    if (!data && message !== 'Success') {
      yield put(eventsActions.failure(message));
    }
    return response;
  } catch (error) {
    yield put(eventsActions.failure(ERROR_MESSAGES.UNKNOWN_ERROR));
    console.log('[DEBUG:SAGAS] getMostViewedCards', error);
    return null;
  }
}

const checkShouldFetch = (lastFetch: number | null) => {
  if (lastFetch && new Date().getTime() < lastFetch + 60 * 60 * 1000) {
    return false;
  }
  return true;
};
