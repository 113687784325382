import InputText from 'components/core/InputText';
import React, {
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Button } from 'components/core';
import { normalizeText } from 'helpers';
import { RootState, Sets } from 'store';

import './styles.scss';

type SortedSets = {
  [block: string]: Sets;
};

type Props = ConnectedProps<typeof connector> & {
  defaultCode?: string;
  /* eslint-disable-next-line no-unused-vars */
  setCode: (code: string) => void;
};

const SetsDropdown: React.FC<Props> = ({ defaultCode, sets, setCode }) => {
  const defaultSet = sets.find((value) => value.code === defaultCode);

  const [hasSelected, setHasSelected] = useState(false);
  const [keyrune, setKeyrune] = useState(defaultSet?.keyruneCode || '');
  const [searchInput, setSearchInput] = useState(defaultSet?.name || '');
  const [showFilters, setShowFilters] = useState(false);

  const sortedSets = useMemo(
    () =>
      sets.reduce((currentSorted, set) => {
        const copy = { ...currentSorted };
        const block = normalizeText(set.block || set.type || 'Others');
        if (searchInput.trim()) {
          const regex = new RegExp(searchInput, 'gi');
          const filtered = (copy[block] ?? []).filter((value) =>
            regex.test(value.name)
          );
          if (regex.test(set.name)) {
            copy[block] = [...filtered, set];
          } else {
            copy[block] = filtered;
          }
          if (copy[block] && copy[block].length) {
            return copy;
          }
          delete copy[block];
          return copy;
        } else {
          copy[block] = [...(copy[block] ?? []), set];
          return copy;
        }
      }, {} as SortedSets),
    [searchInput, sets]
  );

  const onCloseDropdown = useCallback(() => {
    setShowFilters(false);
  }, []);

  const onFocus = useCallback(() => {
    setShowFilters(true);
  }, []);

  const onInput = useCallback(
    (value: string) => {
      if (!value.trim()) {
        setHasSelected(false);
        setCode('');
        setKeyrune('');
        setSearchInput('');
        return;
      }
      setSearchInput(value);
    },
    [setCode]
  );

  const onKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      const value = (event.target as HTMLInputElement)?.value || '';
      if (event.key === 'Backspace') {
        if (!value) {
          event.preventDefault(); // Prevent navigating back if input is empty
          return;
        }
        if (hasSelected) {
          setHasSelected(false);
          setCode('');
          setKeyrune('');
          setSearchInput('');
          return;
        }
      }
    },
    [hasSelected, setCode]
  );

  const onSelectSet = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      const target = event.currentTarget as HTMLButtonElement;
      if (!target) {
        return;
      }
      const code = target.dataset.setCode || '';
      const keyruneCode = target.dataset.keyrune || '';
      const setName = target.dataset.setName || '';
      setCode(code);
      setKeyrune(keyruneCode);
      setSearchInput(setName);
      setShowFilters(false);
      setHasSelected(true);
    },
    [setCode]
  );

  return (
    <div className="setsDropdown">
      <InputText
        label="Set"
        name="sets"
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        required={false}
        setValue={onInput}
        type="search"
        value={searchInput}
      >
        {!!keyrune && <i className={`ss ss-${keyrune.toLowerCase()}`} />}
        {showFilters && (
          <div role="search" className="filters" key="filters">
            <Button onClick={onCloseDropdown} styles={['closeButton']}>
              <span className="icon-cross" />
            </Button>
            {Object.keys(sortedSets).length === 0 && <p>No matches found.</p>}
            {Object.keys(sortedSets).map((block, i) => (
              <ul key={`${block}-${i}`}>
                <span>{block}</span>
                {sortedSets[block].map((set, j) => (
                  <li key={`${j}-${i}-${set.code}`}>
                    <button
                      data-keyrune={set.keyruneCode.toLowerCase()}
                      data-set-code={set.code}
                      data-set-name={set.name}
                      onClick={onSelectSet}
                      type="button"
                    >
                      <i className={`ss ss-${set.keyruneCode.toLowerCase()}`} />
                      <p>{set.name}</p>
                    </button>
                  </li>
                ))}
              </ul>
            ))}
          </div>
        )}
      </InputText>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  sets: state.sets.sets,
});

const connector = connect(mapStateToProps);

export default connector(SetsDropdown);
