import { getQueryStringValue } from 'helpers';
import { useEffect } from 'react';

export const useResetScrollTop = (checkQueryString: boolean = false) => {
  useEffect(() => {
    const appDiv = document.getElementById('app');
    if (appDiv) {
      if (!checkQueryString) {
        appDiv.scrollTop = 0;
        return;
      }
      const scrollTop = getQueryStringValue('scroll');
      if (!scrollTop) {
        return;
      }
      setTimeout(() => {
        appDiv.scrollTop = parseInt(scrollTop) || 0;
      }, 250);
    }
  }, [checkQueryString]);
};
