import React, { useRef } from 'react';
import {
  CardsSearch,
  Footer,
  ImageBackground,
  MostSearchedCards,
  MostViewedCards,
} from 'components/core';
import { getCdnAssetUrl } from 'helpers';
import { DivRefProvider, useErrorMessage, useResetScrollTop } from 'hooks';

const bgImage = getCdnAssetUrl('collage.webp');

import './styles.scss';

const Dashboard: React.FC = () => {
  useErrorMessage('cards');
  useResetScrollTop();

  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <div className="dashboard">
      <ImageBackground bgImage={bgImage} />
      <DivRefProvider value={ref}>
        <CardsSearch ref={ref} />
        <MostSearchedCards />
        <MostViewedCards />
      </DivRefProvider>
      <Footer />
    </div>
  );
};

export default Dashboard;
