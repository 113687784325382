import { RESPONSE_CODES } from 'appConstants';
import { call, put } from 'redux-saga/effects';
import { getProducts } from 'services/affiliates/amazon';
import { AmazonAffiliateProducts, amazonAffiliateProductsActions } from 'store';
import { AmazonAffiliateProduct, Response, SearchResult } from 'types';

/**
 * Saga function to fetch Amazon affiliate products and update the store.
 *
 * - Dispatches a request action to indicate loading.
 * - Calls the `getProducts` API function to fetch data.
 * - If the response is successful and contains valid data, it updates the store with the product list.
 * - Logs errors and returns `null` in case of failure.
 *
 * @yields {PutEffect} Dispatches actions to update the Redux store.
 * @yields {CallEffect} Calls the `getProducts` API function.
 * @returns {Generator<Effect, Response<AmazonAffiliateProducts> | null, any>}
 * A generator yielding Redux effects and resolving to the API response or `null` on failure.
 */
export function* getAmazonAffiliateProducts() {
  try {
    yield put(amazonAffiliateProductsActions.request());

    const response: Response<AmazonAffiliateProducts> = yield call(getProducts);
    const { code, data } = response;
    if (code === RESPONSE_CODES.SUCCESS && !!data && typeof data === 'object') {
      const responseData = data as SearchResult<AmazonAffiliateProducts>;
      yield put(
        amazonAffiliateProductsActions.setProducts(
          (responseData?.data ?? []).map((value) => {
            const copy = { ...value } as Partial<AmazonAffiliateProduct>;
            delete copy.id;
            return copy as AmazonAffiliateProduct;
          })
        )
      );
      return response;
    }

    return response;
  } catch (error) {
    console.log('[DEBUG:SAGAS] getAmazonAffiliateProducts', error);
    return null;
  }
}
