import React, { useCallback } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { CardImage } from 'components/core';
import { cardsActions } from 'store';
import { CardSynergy } from 'types';

import './styles.scss';

type Props = ConnectedProps<typeof connector> & {
  card: CardSynergy;
  hideBackground?: boolean;
};

const CardSynergyItem: React.FC<Props> = ({
  card,
  hideBackground = false,
  getCards,
}) => {
  const { faceName, name, setCode, synergy } = card;

  const onPress = useCallback(
    () =>
      getCards({
        cardPrint: {
          name,
          setCode,
        },
        page: 0,
        query: [
          { name: 'name', value: faceName || name || '' },
          { name: 'setCode', value: setCode || '' },
        ],
      }),
    [faceName, name, setCode, getCards]
  );

  return (
    <div className={`cardSynergyItem${hideBackground ? ' noBackground' : ''}`}>
      <CardImage card={card} onPress={onPress} renderAs="details" />
      {!!synergy?.description?.trim() && (
        <span className="description">
          <p>{synergy.description}</p>
        </span>
      )}
    </div>
  );
};

const mapDispatchToProps = {
  getCards: cardsActions.getCards,
};

const connector = connect(undefined, mapDispatchToProps);

export default connector(CardSynergyItem);
