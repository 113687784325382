export const STORAGE_KEY = 'persist:tapthat.land';

export const deleteStore = () => localStorage.removeItem(STORAGE_KEY);

export const getStoreItemFromStorage = <T>(keys: {
  sliceName: string;
  property: string;
}) => {
  const storeValueString = localStorage.getItem(STORAGE_KEY);
  if (!storeValueString) {
    return null;
  }
  const storeValue = JSON.parse(storeValueString) as { [key: string]: any };
  if (!storeValue) {
    return null;
  }
  const parsedObject = JSON.parse(storeValue[keys.sliceName] || null)?.[
    keys.property
  ] as T;
  return parsedObject ?? null;
};

export const getStorageItem = <T>(key: string) => {
  return JSON.parse(localStorage.getItem(key) ?? 'null') as T;
};

export const setStorageItem = (key: string, value: any) => {
  localStorage.setItem(key, value);
};
