import React from 'react';
import { Params, useMatch } from 'react-router-dom';
import { CardItem } from 'components/core';
import { RouteParamsProvider } from 'hooks';

const CardDetails: React.FC = () => {
  const routeMatch = useMatch({ path: 'card/:uuid/:side?', end: true });
  const { side, uuid = '' } =
    routeMatch?.params ?? ({} as Params<'uuid' | 'side'>);

  return (
    <RouteParamsProvider value={{ cardDetails: { side, uuid } }}>
      <CardItem />
    </RouteParamsProvider>
  );
};

export default CardDetails;
