import React, { ChangeEvent, memo, useCallback } from 'react';
import { isEqual } from 'lodash';

import './styles.scss';

type Props = {
  label?: string;
  options: string[];
  value: string;
  // eslint-disable-next-line no-unused-vars
  setValue: (value: string) => void;
};

const RadioButtons: React.FC<Props> = ({ label, options, value, setValue }) => {
  const getColumnClass = useCallback(() => {
    switch (options.length) {
      case 2:
        return 'col2';
      case 3:
        return 'col3';
      case 4:
        return 'col4';
      case 1:
      default:
        return 'col1';
    }
  }, [options.length]);

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const input = (event.target as HTMLInputElement)?.value || '';
      setValue(input);
    },
    [setValue]
  );

  return (
    <div className="radioButtons">
      {!!label && <label>{label}</label>}
      <div className="buttonGroup">
        {options.map((option) => (
          <div className={`container ${getColumnClass()}`} key={option}>
            <input
              id={option}
              name="radioGroup"
              checked={value === option}
              onChange={onChange}
              type="radio"
              value={option}
            />
            <label htmlFor={option}>{option}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(
  RadioButtons,
  (prevProps, nextProps) =>
    prevProps.value === nextProps.value &&
    isEqual(prevProps.setValue, nextProps.setValue)
);
