import React, { FocusEventHandler, KeyboardEventHandler } from 'react';
import { InputText } from 'components/core';

import './styles.scss';

type Props = {
  disabled?: boolean;
  disableKeypress?: boolean;
  id?: string;
  maxLength?: number;
  placeholder?: string;
  readOnly?: boolean;
  resizable?: boolean;
  searchInputStyles?: string | string[];
  value: string;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  onPressEnter?: KeyboardEventHandler<HTMLInputElement>;
  // eslint-disable-next-line no-unused-vars
  setValue: (value: string) => void;
};

const SearchInput: React.ForwardRefRenderFunction<
  HTMLInputElement,
  React.PropsWithChildren<Props>
> = (searchInputProps, ref) => {
  const { children, searchInputStyles } = searchInputProps;
  return (
    <div
      className={
        !searchInputStyles
          ? `searchInput${!children ? '' : ' withChildren'}`
          : Array.isArray(searchInputStyles)
            ? `searchInput ${searchInputStyles.join(' ')} ${!children ? '' : ' withChildren'}`
            : `searchInput ${searchInputStyles}${!children ? '' : ' withChildren'}`
      }
    >
      <InputText
        placeholder="Search"
        {...searchInputProps}
        label=""
        maxLength={50}
        name="searchInput"
        ref={ref as React.ForwardedRef<HTMLInputElement>}
        required={false}
        type="search"
      >
        {!children ? null : (
          <div className="searchButtonContainer">{children}</div>
        )}
      </InputText>
    </div>
  );
};

export default React.forwardRef(SearchInput);
