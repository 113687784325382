import React, { useCallback } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { SearchBar } from 'components/core';
import { articlesActions, RootState } from 'store';

import './styles.scss';

type Props = ConnectedProps<typeof connector>;

const ArticlesSearchBar: React.FC<Props> = ({ searching, getArticles }) => {
  const searchArticles = useCallback(
    (keywords: string) => {
      getArticles({
        page: 0,
        query: [
          { name: 'body', value: keywords },
          { name: 'title', value: keywords },
        ],
      });
    },
    [getArticles]
  );

  return (
    <div className="articlesSearchBar">
      <SearchBar
        placeholder="Enter content or title keywords"
        search={searchArticles}
        searching={searching}
        showActivity={false}
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  searching: state.articles.searching,
});

const mapDispatchToProps = {
  getArticles: articlesActions.getArticles,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ArticlesSearchBar);
