import { useEffect } from 'react';
import {
  amazonAffiliateProductsActions,
  metaActions,
  MetaState,
  setsActions,
  SetsState,
} from 'store';
import { getStoreItemFromStorage } from 'helpers';
import { Meta } from 'types';

import { useReduxHooks } from './useReduxHooks';

export const usePrefetch = () => {
  const { useReduxDispatch, useReduxSelector } = useReduxHooks() ?? {};
  const dispatch = useReduxDispatch?.();
  const storeState = useReduxSelector<{ meta: MetaState; sets: SetsState }>?.(
    (state) => ({ meta: state.meta, sets: state.sets })
  );
  const { meta: storeMeta, sets } = storeState ?? {};
  const storageMeta = getStoreItemFromStorage<Meta>({
    sliceName: 'meta',
    property: 'meta',
  });
  const shouldFetch =
    (storeMeta?.meta?.version ?? '0.0.0') !== storageMeta?.version ||
    !!sets?.errors;

  useEffect(() => {
    if (!dispatch) {
      return;
    }
    dispatch({ type: metaActions.getMetaData.type });
  }, [dispatch]);

  useEffect(() => {
    if (!dispatch) {
      return;
    }
    dispatch({
      type: amazonAffiliateProductsActions.getAmazonAffiliateProducts.type,
    });
    if (shouldFetch) {
      dispatch({
        type: setsActions.getAllSets.type,
      });
    }
  }, [shouldFetch, dispatch]);
};
