import React, { useCallback } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Modal } from 'components/core';
import { modalActions, MessageModalState, RootState } from 'store';

type Props = ConnectedProps<typeof connector> & MessageModalState;

const MessageModal: React.FC<Props> = ({
  message,
  hideModal,
  isMessageModalShown,
  title,
  cbClose,
}) => {
  const onClose = useCallback(() => {
    cbClose?.();
    hideModal();
  }, [cbClose, hideModal]);

  return isMessageModalShown ? (
    <Modal title={title} cbClose={onClose}>
      <p>{message}</p>
    </Modal>
  ) : null;
};

const mapStateToProps = (state: RootState) => ({
  isMessageModalShown: state.modal.messageModal.shown,
});

const mapDispatchToProps = {
  hideModal: modalActions.hideMessageModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(MessageModal);
