import React from 'react';

import './styles.scss';

const Footer: React.FC = () => {
  return (
    <div className="footer">
      <div className="disclaimer">
        <p>
          Tap That Land is an unofficial card database website permitted under
          Wizards of the Coast’s Fan Content Policy.
        </p>
        <p>
          Tap That Land is not affiliated with, endorsed, sponsored, or
          specifically approved by Wizards of the Coast.
        </p>
        <p>
          Portions of the materials used are property of Wizards of the Coast.
          ©Wizards of the Coast LLC.
        </p>
        <p>
          Our card data and image database can be accessed free of charge. Card
          images provided by Scryfall.
        </p>
      </div>
      <div className="disclaimer">
        <p>
          © <span>{new Date().getFullYear()}</span> Tap That Land. All rights
          reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
