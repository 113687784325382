import React, { useCallback, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import { ERROR_MESSAGES } from 'appConstants';
import {
  Button,
  AnimatedCards,
  ErrorMessages,
  ImageBackground,
  ImageLoader,
  InputText,
  Loader,
} from 'components/core';
import { getCdnAssetUrl, isValidEmail } from 'helpers';
import { RootState, usersActions } from 'store';
import { User } from 'types';

import './styles.scss';

const bgImage = getCdnAssetUrl('forest.webp');
const tapthatlandLogo = getCdnAssetUrl('tapthatland.webp');

type Props = ConnectedProps<typeof connector>;

const Login: React.FC<Props> = ({ isLoggingIn, loginErrors, login, reset }) => {
  const emailRef = useRef<HTMLInputElement | null>(null);
  const passwordRef = useRef<HTMLInputElement | null>(null);

  const [email, setEmail] = useState('');
  const [emailErrors, setEmailErrors] = useState('');
  const [password, setPassword] = useState('');
  const [passwordErrors, setPasswordErrors] = useState('');

  const onInputEmail = useCallback(
    (value: string) => {
      if (emailErrors) {
        setEmailErrors('');
      }
      if (loginErrors) {
        reset();
      }
      setEmail(value);
    },
    [emailErrors, loginErrors, reset]
  );

  const onInputPassword = useCallback(
    (value: string) => {
      if (passwordErrors) {
        setPasswordErrors('');
      }
      if (loginErrors) {
        reset();
      }
      setPassword(value);
    },
    [loginErrors, passwordErrors, reset]
  );

  const validateCredentials = useCallback(() => {
    let isValid = true;
    if (!isValidEmail(email)) {
      isValid = false;
      setEmailErrors(ERROR_MESSAGES.INVALID_EMAIL_ADDRESS);
    }
    if (!password.length) {
      isValid = false;
    }
    return isValid;
  }, [email, password]);

  const onLogin = useCallback(() => {
    if (loginErrors) {
      reset();
    }
    if (!validateCredentials()) {
      return;
    }
    login({
      id: null,
      accountType: null,
      businessAddress: null,
      email,
      emailVerification: null,
      isBusinessUser: false,
      lastName: null,
      name: null,
      password,
      username: null,
    } as User);
  }, [email, login, loginErrors, password, reset, validateCredentials]);

  return (
    <div className="login">
      <ImageBackground bgImage={bgImage} />
      <div className="columnContainer">
        <div className="column">
          <div className="links backToHome">
            <Link to="/">
              <span className="icon-back" />
              Home
            </Link>
          </div>
          <div className="loginSection">
            <div className="loginContainer">
              <div className="logo">
                <ImageLoader
                  src={tapthatlandLogo}
                  styles={{ container: 'imgContainer' }}
                />
              </div>
              <div className="inputFields">
                <InputText
                  errors={emailErrors}
                  label="Email"
                  maxLength={50}
                  name="email"
                  ref={emailRef}
                  required
                  setValue={onInputEmail}
                  type="input"
                  value={email}
                />
                <InputText
                  errors={passwordErrors}
                  label="Password"
                  maxLength={20}
                  name="password"
                  onPressEnter={onLogin}
                  ref={passwordRef}
                  required
                  setValue={onInputPassword}
                  type="password"
                  value={password}
                />
              </div>
              <div className="submitBtnContainer">
                {!isLoggingIn ? (
                  <Button
                    disabled={!!emailErrors || !!passwordErrors || isLoggingIn}
                    label="Log in"
                    onClick={onLogin}
                  />
                ) : (
                  <Loader type="component" />
                )}
                {!!loginErrors && (
                  <div className="authErrors">
                    <ErrorMessages errors={loginErrors} />
                  </div>
                )}
              </div>
            </div>

            <div className="links signup">
              {"Don't have an account yet?"}
              <Link to="/signup">Signup</Link>
            </div>
          </div>
        </div>
        <div className="column">
          <div />
          <div className="signupInfo">
            <p>
              {'Step into the multiverse! Join our '}
              <b>Magic: The Gathering</b>
              {
                ' community to discuss strategies, share deck ideas, and connect with fellow Planeswalkers.'
              }
            </p>
          </div>
          <AnimatedCards />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoggingIn: state.users.fetching,
  loginErrors: state.users.errors,
});

const mapDispatchToProps = {
  login: usersActions.login,
  reset: usersActions.reset,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Login);
