import { config } from 'config';

/**
 * Generates the full URL for an asset stored in the Cloudflare R2 CDN.
 *
 * @param {string} assetName - The relative path or filename of the asset in the CDN.
 * @returns {string} The full URL of the asset.
 *
 * @example
 * ```ts
 * const imageUrl = getCdnAssetUrl("logo.png");
 * console.log(imageUrl); // Outputs: "https://cdn.example.com/logo.png"
 * ```
 */
export const getCdnAssetUrl = (assetName: string) =>
  `${config.cdnUrl}${assetName}`;
