import React, { memo } from 'react';
import { getCdnAssetUrl } from 'helpers';

import './styles.scss';

const chandra = getCdnAssetUrl('chandra.webp');
const elspeth = getCdnAssetUrl('elspeth.webp');
const jace = getCdnAssetUrl('jace.webp');
const karn = getCdnAssetUrl('karn.webp');
const liliana = getCdnAssetUrl('liliana.webp');
const nicolBolas = getCdnAssetUrl('nicol-bolas.webp');
const sorin = getCdnAssetUrl('sorin.webp');

const AnimatedCards: React.FC = () => {
  return (
    <div className="animatedCards">
      <div>
        <img alt="Karn Liberated" src={karn} />
      </div>
      <div>
        <img alt="Sorin, Vengeful Bloodlord" src={sorin} />
      </div>
      <div>
        <img alt="Elspeth, Sun's Champion" src={elspeth} />
      </div>
      <div>
        <img alt="Chandra, Dressed to Kill" src={chandra} />
      </div>
      <div>
        <img alt="Nicol Bolas, God-Pharaoh" src={nicolBolas} />
      </div>
      <div>
        <img alt="Liliana of the Veil" src={liliana} />
      </div>
      <div>
        <img alt="Jace, the Mind Sculptor" src={jace} />
      </div>
    </div>
  );
};

export default memo(AnimatedCards);
