import { ERROR_MESSAGES, RESPONSE_CODES } from 'appConstants';
import { setStorageItem, STORAGE_KEY } from 'helpers';
import { call, put } from 'redux-saga/effects';
import { getMeta } from 'services';
import { metaActions } from 'store';
import { Meta, Response } from 'types';

/**
 * Saga generator function called to get meta data
 * @returns {Response} Response object.
 */
export function* getMetaData() {
  try {
    yield put(metaActions.request());

    const response: Response<Meta> = yield call(getMeta);
    const { code, data } = response;
    if (code === RESPONSE_CODES.SUCCESS && !!data) {
      if (
        typeof data === 'object' &&
        !Array.isArray(data) &&
        Object.prototype.hasOwnProperty.call(data, 'id')
      ) {
        delete (data as { [key: string]: any }).id;
      }
      yield put(metaActions.setMeta(data as Meta));
      return response;
    }

    const { message } = response;
    yield put(metaActions.failure(message));
    return response;
  } catch (error) {
    yield put(metaActions.failure(ERROR_MESSAGES.UNKNOWN_ERROR));
    setStorageItem(STORAGE_KEY, null);
    console.log('[DEBUG:SAGAS] getMetaData', error);
    return null;
  }
}
