import { ERROR_MESSAGES } from 'appConstants';
import { config } from 'config';
import { getFailure } from 'helpers';
import { Meta, Response } from 'types';

import { callApi } from './base';

/**
 * Sends a GET request to the get meta API endpoint.
 * @returns {Promise<Response>}
 */
export const getMeta = async (): Promise<Response<Meta>> => {
  try {
    const response = await callApi({
      url: `${config.apiUrl}api/meta`,
    });
    const { data } = (response ?? {}) as { [key: string]: any };
    return data as Response<Meta>;
  } catch (error) {
    console.log('[DEBUG] getMeta with error', error);
    return getFailure(ERROR_MESSAGES.UNKNOWN_ERROR);
  }
};
