import { takeLatest } from 'redux-saga/effects';
import {
  amazonAffiliateProductsActions,
  articlesActions,
  cardsActions,
  eventsActions,
  metaActions,
  setsActions,
  usersActions,
} from 'store';

import { getAmazonAffiliateProducts } from './affiliates/amazon';
import { getArticles } from './articles';
import {
  getCard,
  getCards,
  getCardSimilarities,
  getCardSynergies,
} from './cards';
import { getMetaData } from './meta';
import { getAllSets } from './sets';
import {
  getUser,
  loginUser,
  logoutUser,
  refreshToken,
  registerUser,
  updateUser,
} from './users';
import {
  getMostSearchedCards,
  getMostViewedArticles,
  getMostViewedCards,
} from './events';

/**
 * The generator function for the root saga that contains all registered sagas.
 */
export default function* rootSaga() {
  yield takeLatest(
    amazonAffiliateProductsActions.getAmazonAffiliateProducts.type,
    getAmazonAffiliateProducts
  );
  yield takeLatest(articlesActions.getArticles.type, getArticles);
  yield takeLatest(cardsActions.getCard.type, getCard);
  yield takeLatest(cardsActions.getCards.type, getCards);
  yield takeLatest(cardsActions.getCardSimilarities.type, getCardSimilarities);
  yield takeLatest(cardsActions.getCardSynergies.type, getCardSynergies);
  yield takeLatest(
    eventsActions.getMostSearchedCards.type,
    getMostSearchedCards
  );
  yield takeLatest(
    eventsActions.getMostViewedArticles.type,
    getMostViewedArticles
  );
  yield takeLatest(eventsActions.getMostViewedCards.type, getMostViewedCards);
  yield takeLatest(metaActions.getMetaData.type, getMetaData);
  yield takeLatest(setsActions.getAllSets.type, getAllSets);
  yield takeLatest(usersActions.getUser.type, getUser);
  yield takeLatest(usersActions.login.type, loginUser);
  yield takeLatest(usersActions.logout.type, logoutUser);
  yield takeLatest(usersActions.refreshToken.type, refreshToken);
  yield takeLatest(usersActions.register.type, registerUser);
  yield takeLatest(usersActions.update.type, updateUser);
}
