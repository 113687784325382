import React, { memo, useCallback } from 'react';
import { isEqual } from 'lodash';
import { connect, ConnectedProps } from 'react-redux';
import { Modal } from 'components/core';
import { modalActions, PromptModalState, RootState } from 'store';

import './styles.scss';

type Props = ConnectedProps<typeof connector> & PromptModalState;

const PromptModal: React.FC<Props> = ({
  hideModal,
  isPromptModalShown,
  message,
  textNo = 'No',
  textYes = 'Yes',
  title,
  cbOk,
}) => {
  const onClose = useCallback(() => hideModal(), [hideModal]);

  const onCloseWithCb = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (cbOk) {
        cbOk(event);
      }
      hideModal();
    },
    [cbOk, hideModal]
  );

  return isPromptModalShown ? (
    <Modal title={title} cbClose={onClose}>
      <div className="promptModal">
        {/* Message */}
        <p>{message}</p>

        {/* buttons */}
        <div className="footer">
          {/* No button */}
          <button onClick={onClose} type="button" aria-label="No">
            {textNo}
          </button>

          {/* Yes Button */}
          <button onClick={onCloseWithCb} type="button" aria-label="Yes">
            {textYes}
          </button>
        </div>
      </div>
    </Modal>
  ) : null;
};

const mapStateToProps = (state: RootState) => ({
  isPromptModalShown: state.modal.promptModal.shown,
});

const mapDispatchToProps = {
  hideModal: modalActions.hidePromptModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(
  memo(PromptModal, (prevProps, nextProps) => isEqual(prevProps, nextProps))
);
