import React, { useCallback, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  AMAZON_AFFILIATE_MESSAGES,
  EVENT_ACTION,
  EVENT_TYPE,
  FORMATS,
  FORMAT_LABELS,
  RARITIES,
  RARITY_LABELS,
} from 'appConstants';
import {
  Button,
  CardImage,
  CardSynergies,
  Loader,
  Products,
} from 'components/core';
import { convertSymbolsToIcons, parseQueryString } from 'helpers';
import { usePrevious, useRouteParams } from 'hooks';
import { getSingle, trackSingleEvent } from 'services';
import { Cards, cardsActions, RootState } from 'store';
import { Card } from 'types';

import './styles.scss';

type Props = ConnectedProps<typeof connector>;

const CardItem: React.FC<Props> = ({
  cards = [],
  redirectUrl,
  searching,
  selectedCard,
  sets,
  getCards,
  selectCard,
  selectCardForSynergy,
  setCardSimilarities,
  setCardSynergies,
  setRedirectUrl,
}) => {
  const navigate = useNavigate();

  const { cardDetails } = useRouteParams();
  const { side = '', uuid = '' } = cardDetails ?? {};

  const previousRedirectUrl = usePrevious(redirectUrl);

  const {
    artist = '',
    faceName = '',
    flavorText = '',
    finishes = [],
    layout,
    legalities = {},
    manaCost = '',
    name = '',
    number = 0,
    printings = [],
    rarity = 'common',
    rulings = [],
    setCode = '',
    text = '',
    type = '',
  } = selectedCard ?? {};

  const cardTransformation = (selectedCard as Card)?.transformation;
  const prints = printings.filter((value) => value !== setCode);

  const rarityLabelKey = Object.entries(RARITIES).find((value) => {
    return value[1] === rarity;
  })?.[0];

  const formats = Object.keys(FORMATS).map((format) => ({
    format: FORMAT_LABELS[format as keyof typeof FORMATS],
    legality:
      Object.entries(legalities).find((legality) => {
        const [key] = legality;
        return key === FORMATS[format as keyof typeof FORMATS];
      })?.[1] || 'Not Legal',
  }));

  const queryString = parseQueryString();

  const getCard = async (cardId: string) =>
    await getSingle({ payload: cardId });

  const getSelectedCard = useCallback(
    async (cardId: string, cardsList: Cards, transform: boolean = false) => {
      const match =
        (!transform
          ? cardsList.find((value) => value.uuid === cardId)
          : cardsList.find((value) => value.transformation?.uuid === cardId)
              ?.transformation) ?? null;
      if (match) {
        selectCard(match);
        trackSingleEvent({
          payload: {
            action: EVENT_ACTION.VIEW,
            data: cardId,
            type: EVENT_TYPE.CARD,
          },
        });
        return;
      }
      selectCard((await getCard(cardId)).data as Card);
    },
    [selectCard]
  );

  useEffect(() => {
    if (selectedCard?.uuid === uuid) {
      return;
    }
    if (side === 'back') {
      getSelectedCard(uuid, cards, layout !== 'meld');
      return;
    }
    getSelectedCard(uuid, cards);
  }, [cards, layout, selectedCard, side, uuid, selectCard, getSelectedCard]);

  useEffect(
    () => {
      return () => {
        setCardSimilarities(null);
        setCardSynergies(null);
        setRedirectUrl(undefined);
        selectCard(null);
        selectCardForSynergy(null);
      };
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (previousRedirectUrl === undefined && redirectUrl) {
      navigate(
        !queryString.redirect
          ? redirectUrl
          : `${redirectUrl}${window.location.search}`,
        { replace: true }
      );
    }
  }, [previousRedirectUrl, queryString.redirect, redirectUrl, navigate]);

  const onPressPrint = useCallback(
    (set: string) =>
      getCards({
        cardPrint: {
          name,
          setCode: set,
        },
        page: 0,
        query: [
          { name: 'name', value: faceName || name || '' },
          { name: 'setCode', value: set },
        ],
      }),
    [faceName, name, getCards]
  );

  const getSetCode = (cardSetCode: string, cardPrintings: string[]) => {
    const isPromoSetCode =
      cardSetCode[0]?.toLowerCase() === 'p' &&
      cardPrintings.includes(cardSetCode.substring(1));
    return (
      isPromoSetCode ? cardSetCode.substring(1) : cardSetCode
    ).toLowerCase();
  };

  const renderCardDetails = (source: {
    cardManaCost?: string | null;
    cardName: string | null;
    cardPrintings?: string[];
    cardRarity: string | null;
    cardRarityLabel?: string | null;
    cardSetCode: string | null;
    cardText?: string | null;
    cardType: string | null;
  }) => {
    const {
      cardManaCost = '',
      cardName,
      cardPrintings = [],
      cardRarity,
      cardRarityLabel,
      cardSetCode = '',
      cardText = '',
      cardType,
    } = source;
    return (
      <>
        <h3
          dangerouslySetInnerHTML={{
            __html: `<span>${cardName}</span>${convertSymbolsToIcons(cardManaCost)}`,
          }}
        />
        <div>
          <span className="typeAndRarity">
            <span>{cardType}</span>
            <span className="rarity">
              <i
                className={`ss ss-${getSetCode(cardSetCode || '', cardPrintings)} ss-${cardRarity} ss-grad`}
              />
              {!!cardRarityLabel && (
                <p>
                  {RARITY_LABELS[cardRarityLabel as keyof typeof RARITY_LABELS]}
                </p>
              )}
            </span>
          </span>
          <p
            dangerouslySetInnerHTML={{
              __html: convertSymbolsToIcons(cardText),
            }}
          />
        </div>
      </>
    );
  };

  if (selectedCard === null || searching) {
    return (
      <div className="details full">
        <Loader type="screen" />
      </div>
    );
  }

  return (
    <>
      <div className="cardItem">
        <div className="upperHalf">
          <div className="content">
            <div className="details noBorder formatsContainer">
              <div className="formats">
                {formats.map(({ format, legality }) => (
                  <span className="format" key={format}>
                    <p>{format}</p>
                    <p className={legality === 'Legal' ? undefined : 'illegal'}>
                      {legality}
                    </p>
                  </span>
                ))}
              </div>
            </div>
            <div className="additionalInfo">
              <span>
                <i
                  className={`ss ss-${getSetCode(setCode || '', printings)} ss-${rarity} ss-grad`}
                />
                <span className="setInfo">
                  <p>{sets.find((set) => set.code === setCode)?.name || ''}</p>
                  <p>
                    {`#${number}`} | {finishes.join('/')}
                  </p>
                </span>
              </span>
            </div>
            <Products />
          </div>
          <div className="imageContainer">
            <CardImage card={selectedCard} renderAs="details" />
            <div className="details">
              {renderCardDetails({
                cardManaCost: manaCost,
                cardName: faceName || name,
                cardPrintings: printings,
                cardRarity: rarity,
                cardRarityLabel: rarityLabelKey,
                cardSetCode: setCode,
                cardText: text,
                cardType: type,
              })}
              <>
                {(layout === 'split' || layout === 'adventure') &&
                  renderCardDetails({
                    cardManaCost: cardTransformation?.manaCost,
                    cardName:
                      cardTransformation?.faceName || cardTransformation?.name,
                    cardPrintings: cardTransformation?.printings,
                    cardRarity: cardTransformation?.rarity,
                    cardRarityLabel: Object.entries(RARITIES).find((value) => {
                      return value[1] === cardTransformation?.rarity;
                    })?.[0],
                    cardSetCode: cardTransformation?.setCode,
                    cardText: cardTransformation?.text,
                    cardType: cardTransformation?.type,
                  })}
              </>
              <div>
                {!!flavorText && <span className="separator" />}
                {!!flavorText && (
                  <p
                    className="flavorText"
                    dangerouslySetInnerHTML={{
                      __html: convertSymbolsToIcons(flavorText),
                    }}
                  />
                )}
                <span className="separator full" />
                {!!artist && <p>{`Illustrated by ${artist}`}</p>}
              </div>
            </div>
          </div>
        </div>
        {prints.length > 0 && (
          <div
            className={`details noBorder rulings prints${prints.length === 1 ? ' single' : ''}`}
          >
            <h3>Prints</h3>
            {prints.map((value) => (
              <Button key={value} onClick={() => onPressPrint(value)}>
                <i
                  className={`ss ss-${getSetCode(value || '', printings)} ss-${rarity} ss-grad`}
                />
                <span className="setInfo">
                  <p>{sets.find((set) => set.code === value)?.name || ''}</p>
                </span>
              </Button>
            ))}
          </div>
        )}
        {rulings.length > 0 && (
          <div className="details noBorder rulings">
            {rulings.map((ruling, i) => (
              <span className="ruling" key={`ruling-${ruling.date}-${i}`}>
                <p>{ruling.date}</p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: convertSymbolsToIcons(ruling.text),
                  }}
                />
                {i !== rulings.length - 1 && (
                  <span className="separator full" />
                )}
              </span>
            ))}
          </div>
        )}
        <div className="lowerHalf">
          {selectedCard !== null && !/Basic Land/.test(type || '') && (
            <CardSynergies
              card={selectedCard}
              note={AMAZON_AFFILIATE_MESSAGES.AFFILIATE_MARKETING_NOTE}
            />
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  cards: state.cards.searchResult?.data,
  redirectUrl: state.cards.redirectUrl,
  searching: state.cards.searching,
  selectedCard: state.cards.selectedCard,
  sets: state.sets.sets,
});

const mapDispatchToProps = {
  getCards: cardsActions.getCards,
  selectCard: cardsActions.selectCard,
  selectCardForSynergy: cardsActions.selectCardForSynergy,
  setCardSimilarities: cardsActions.setCardSimilarities,
  setCardSynergies: cardsActions.setCardSynergies,
  setRedirectUrl: cardsActions.setRedirectUrl,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(CardItem);
