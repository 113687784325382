import { Option } from 'types';

import {
  CARD_TYPES,
  FORMAT_LABELS,
  FORMATS,
  RARITIES,
  RARITY_LABELS,
} from './enums/cards';

export const CARD_TYPE_OPTIONS = Object.keys(CARD_TYPES).map((value) => ({
  label: CARD_TYPES[value as keyof typeof CARD_TYPES],
  value: CARD_TYPES[value as keyof typeof CARD_TYPES],
}));

export const FORMAT_OPTIONS: Option[] = Object.keys(FORMATS).map((value) => ({
  label: FORMAT_LABELS[value as keyof typeof FORMATS],
  value: FORMATS[value as keyof typeof FORMATS],
}));

export const RARITY_OPTIONS: Option[] = Object.keys(RARITIES).map((value) => ({
  label: RARITY_LABELS[value as keyof typeof RARITIES],
  value: RARITIES[value as keyof typeof RARITIES],
}));

export const TWO_FACED_CARDS = [
  'modal_dfc',
  'transform',
  'double_faced_token',
  'reversible_card',
  'meld',
];
