import React, { useEffect, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Loader, ProductItem } from 'components/core';
import { usePrevious } from 'hooks';
import { RootState } from 'store';

import './styles.scss';

type Props = ConnectedProps<typeof connector>;

const Products: React.FC<Props> = ({ affiliateProducts, fetching }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const intervalIdRef = useRef<NodeJS.Timer | null>(null);

  const [currentIndex, setCurrentIndex] = useState(0);

  const previousIndex = usePrevious(currentIndex);

  useEffect(() => {
    if (containerRef.current !== null && !intervalIdRef.current) {
      intervalIdRef.current = setInterval(() => {
        setCurrentIndex((prev) =>
          prev + 1 === affiliateProducts.length ? 0 : (prev += 1)
        );
      }, 5000);
    }
  }, [affiliateProducts.length]);

  useEffect(() => {
    if (currentIndex === 0 && previousIndex === affiliateProducts.length - 1) {
      if (containerRef.current) {
        containerRef.current.scrollLeft = 0;
      }
      return;
    }
    if (currentIndex > 0 && currentIndex < affiliateProducts.length - 1) {
      if (containerRef.current) {
        containerRef.current.scrollLeft += 280;
      }
    }
  }, [containerRef, currentIndex, previousIndex, affiliateProducts.length]);

  return (
    <div className={`products${fetching ? ' loading' : ''}`}>
      {fetching ? (
        <Loader type="component" />
      ) : (
        <div className="container" ref={containerRef}>
          {affiliateProducts.map((product, i) => (
            <ProductItem
              data-index={i}
              key={product.link}
              imageWidth={260}
              product={product}
              visible={currentIndex === i}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  affiliateProducts: state.amazonAffiliateProducts.products,
  fetching: state.amazonAffiliateProducts.fetching,
});

const connector = connect(mapStateToProps);

export default connector(Products);
