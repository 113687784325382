import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Errors, Meta } from 'types';

export type MetaState = {
  meta: Meta | null;
  errors?: Errors;
  fetching: boolean;
};

const initialState: MetaState = {
  meta: null,
  errors: undefined,
  fetching: false,
};

const metaSlices = createSlice({
  name: 'meta',
  initialState,
  reducers: {
    failure: (state, action: PayloadAction<Errors>) => {
      state.errors = action.payload;
      state.fetching = false;
    },
    reset: (state) => {
      state.errors = undefined;
      state.fetching = false;
    },
    request: (state) => {
      state.errors = undefined;
      state.fetching = true;
    },
    setMeta: (state, action: PayloadAction<Meta>) => {
      state.errors = undefined;
      state.fetching = false;
      state.meta = action.payload;
    },
  },
});

export const metaActions = {
  ...metaSlices.actions,
  getMetaData: createAction('meta/getMetaData'),
};

export default metaSlices.reducer;
