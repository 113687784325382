import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { withPayloadType } from 'helpers';
import {
  Card,
  CardBase,
  CardSynergy,
  Errors,
  SearchRequest,
  SearchResult,
  SynergyRequest,
} from 'types';

export type Cards = Card[];
export type CardSynergies = CardSynergy[];

export type CardsState = {
  cardSimilarities: SearchResult<CardSynergies> | null;
  cardSynergies: SearchResult<CardSynergies> | null;
  previousSearchRequest: SearchRequest | null;
  redirectUrl?: string;
  searchFiltersShown: boolean;
  searching: boolean;
  searchRequest: SearchRequest | null;
  searchResult: SearchResult<Cards> | null;
  selectedCard: CardBase | Card | null;
  selectedCardForSynergy: CardBase | Card | null;
  errors?: Errors;
  fetching: boolean;
};

const initialState: CardsState = {
  cardSimilarities: null,
  cardSynergies: null,
  previousSearchRequest: null,
  redirectUrl: undefined,
  searchFiltersShown: false,
  searching: false,
  searchRequest: null,
  searchResult: null,
  selectedCard: null,
  selectedCardForSynergy: null,
  errors: undefined,
  fetching: false,
};

const cardsSlices = createSlice({
  name: 'cards',
  initialState,
  reducers: {
    failure: (state, action: PayloadAction<Errors>) => {
      state.errors = action.payload;
      state.fetching = false;
      state.searching = false;
    },
    request: (state) => {
      state.errors = undefined;
      state.fetching = true;
    },
    reset: (state) => {
      state.errors = undefined;
      state.fetching = false;
      state.searching = false;
    },
    setCards: (state, action: PayloadAction<SearchResult<Cards> | null>) => {
      state.errors = undefined;
      state.searching = false;
      state.searchResult = action.payload;
    },
    setCardSimilarities: (
      state,
      action: PayloadAction<SearchResult<CardSynergies> | null>
    ) => {
      state.errors = undefined;
      state.fetching = false;
      state.cardSimilarities = action.payload;
    },
    setCardSynergies: (
      state,
      action: PayloadAction<SearchResult<CardSynergies> | null>
    ) => {
      state.errors = undefined;
      state.fetching = false;
      state.cardSynergies = action.payload;
    },
    setRedirectUrl: (state, action: PayloadAction<string | undefined>) => {
      state.redirectUrl = action.payload;
    },
    setPreviousSearchRequest: (
      state,
      action: PayloadAction<SearchRequest | null>
    ) => {
      state.previousSearchRequest = action.payload;
    },
    setSearching: (state) => {
      state.errors = undefined;
      state.searching = true;
    },
    setSearchRequest: (state, action: PayloadAction<SearchRequest | null>) => {
      if (!action.payload?.cardPrint) {
        state.searchResult = null;
        state.searchRequest = action.payload;
        return;
      }
      if (!state.previousSearchRequest) {
        state.previousSearchRequest = state.searchRequest;
      }
      state.searchRequest = action.payload;
    },
    selectCard: (state, action: PayloadAction<CardBase | Card | null>) => {
      state.selectedCard = action.payload;
    },
    selectCardForSynergy: (
      state,
      action: PayloadAction<CardBase | Card | null>
    ) => {
      state.selectedCardForSynergy = action.payload;
    },
    toggleSearchFilters: (state) => {
      state.searchFiltersShown = !state.searchFiltersShown;
    },
  },
});

export const cardsActions = {
  ...cardsSlices.actions,
  getCards: createAction('cards/getCards', withPayloadType<SearchRequest>()),
  getCardSimilarities: createAction(
    'cards/getCardSimilarities',
    withPayloadType<SynergyRequest>()
  ),
  getCardSynergies: createAction(
    'cards/getCardSynergies',
    withPayloadType<SynergyRequest>()
  ),
  getCard: createAction('cards/getCard', withPayloadType<string>()),
};

export default cardsSlices.reducer;
