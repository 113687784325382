import React, { useCallback, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { TWO_FACED_CARDS } from 'appConstants';
import {
  Button,
  CardSynergyItem,
  // Dropdown
} from 'components/core';
import { usePrevious } from 'hooks';
import { cardsActions, loaderActions, RootState } from 'store';
import { Card, CardBase } from 'types';

import './styles.scss';

type Props = ConnectedProps<typeof connector> & {
  card: CardBase | Card;
  note?: string;
};

const Synergies: React.FC<Props> = ({
  card,
  cardSimilarities,
  cardSynergies,
  fetching,
  note,
  selectedCard,
  selectedCardForSynergy,
  getCardSimilarities,
  getCardSynergies,
  selectCardForSynergy,
  setLoader,
}) => {
  const { data: similarities = [] } = cardSimilarities ?? {};
  const { data: synergies = [] } = cardSynergies ?? {};

  const previousFetching = usePrevious(fetching);

  // const [formatValue, setFormatValue] = useState('standard');

  // const formats = !selectedCard?.legalities
  //   ? FORMAT_OPTIONS
  //   : FORMAT_OPTIONS.filter((value) => {
  //       const key = value.value.toString();
  //       return (
  //         (selectedCard.legalities as { [key: string]: any })[key] === 'Legal'
  //       );
  //     });

  // const format = formats.find((value) => value.value === formatValue)?.label;

  useEffect(() => {
    if (!fetching && previousFetching) {
      setLoader({ message: null, shown: false });
    }
  }, [fetching, previousFetching, setLoader]);

  const onPressSimilarities = useCallback(() => {
    setLoader({
      message:
        'Retrieving similarity suggestions...<br />This may take a while.',
      shown: true,
    });
    const finalCard = selectedCardForSynergy ?? card;
    getCardSimilarities({
      // format,
      name: finalCard?.faceName || finalCard?.name || '',
      text: finalCard?.text || '',
    });
    selectCardForSynergy(finalCard);
  }, [
    card,
    // format,
    selectedCardForSynergy,
    getCardSimilarities,
    selectCardForSynergy,
    setLoader,
  ]);

  const onPressSynergies = useCallback(() => {
    setLoader({
      message: 'Retrieving synergy suggestions...<br />This may take a while.',
      shown: true,
    });
    const finalCard = selectedCardForSynergy ?? card;
    getCardSynergies({
      // format,
      name: finalCard?.faceName || finalCard?.name || '',
      text: finalCard?.text || '',
    });
    selectCardForSynergy(finalCard);
  }, [
    card,
    // format,
    selectedCardForSynergy,
    getCardSynergies,
    selectCardForSynergy,
    setLoader,
  ]);

  return (
    <div className="synergies">
      {!!note?.trim() && <p className="affiliateNote">{note}</p>}
      {selectedCard &&
        selectedCardForSynergy &&
        selectedCard.uuid !== selectedCardForSynergy.uuid &&
        (TWO_FACED_CARDS.includes(selectedCardForSynergy.layout || '') &&
        selectedCard.otherFaceIds?.includes(
          selectedCardForSynergy.uuid || ''
        ) ? null : (
          <div className="synergy">
            <CardSynergyItem card={selectedCardForSynergy} hideBackground />
          </div>
        ))}
      {synergies.length === 0 && (
        <Button onClick={onPressSynergies} styles={['synergyButton']}>
          <span>Synergy Suggestions</span>
        </Button>
      )}
      {similarities.length === 0 && (
        <>
          {/* <Dropdown
            defaultOptionLabel="All Formats"
            id="format"
            label="Format"
            options={formats}
            setValue={setFormatValue}
            styles={['synergyButton']}
            value={formatValue}
          /> */}
          <Button onClick={onPressSimilarities} styles={['synergyButton']}>
            <span>Similarity Suggestions</span>
          </Button>
        </>
      )}
      <p>
        Our card synergy and similarity suggestions are designed to assist you
        in building your deck. While they are generated using advanced
        algorithms and available data, they may sometimes overlook certain
        interactions or contain inaccuracies. We recommend reviewing all
        suggestions carefully and playtesting your deck before using it in a
        game.
      </p>
      {synergies.length > 0 && (
        <div className="container">
          <h3>
            {`Synergizes with ${selectedCardForSynergy?.faceName || selectedCardForSynergy?.name || ''}`}
          </h3>
          <div className="cards">
            {synergies.map((card) => (
              <CardSynergyItem key={card.uuid} card={card} />
            ))}
          </div>
        </div>
      )}
      {similarities.length > 0 && (
        <div className="container">
          <h3>
            {`Similar to ${selectedCardForSynergy?.faceName || selectedCardForSynergy?.name || ''}`}
          </h3>
          <div className="cards">
            {similarities.map((card) => (
              <CardSynergyItem key={card.uuid} card={card} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  cardSimilarities: state.cards.cardSimilarities,
  cardSynergies: state.cards.cardSynergies,
  fetching: state.cards.fetching,
  selectedCard: state.cards.selectedCard,
  selectedCardForSynergy: state.cards.selectedCardForSynergy,
});

const mapDispatchToProps = {
  getCardSimilarities: cardsActions.getCardSimilarities,
  getCardSynergies: cardsActions.getCardSynergies,
  selectCardForSynergy: cardsActions.selectCardForSynergy,
  setLoader: loaderActions.setLoader,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Synergies);
