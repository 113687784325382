import {
  Action,
  combineReducers,
  configureStore,
  Dispatch,
  Reducer,
  Store,
} from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { Persistor, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware, { SagaMiddleware } from 'redux-saga';

import rootSaga from './sagas/root';
import amazonAffiliateProductsReducer from './slices/affiliates/amazon';
import appStateReducer from './slices/app';
import articlesReducer from './slices/articles';
import eventsReducer from './slices/events';
import cardsReducer from './slices/cards';
import loaderReducer from './slices/loader';
import metaReducer from './slices/meta';
import modalReducer from './slices/modal';
import setsReducer from './slices/sets';
import sidebarReducer from './slices/sidebar';
import usersReducer from './slices/users';

const appReducer = combineReducers({
  amazonAffiliateProducts: amazonAffiliateProductsReducer,
  app: appStateReducer,
  articles: articlesReducer,
  cards: cardsReducer,
  events: eventsReducer,
  loader: loaderReducer,
  meta: metaReducer,
  modal: modalReducer,
  sets: setsReducer,
  sidebar: sidebarReducer,
  users: usersReducer,
});

export type RootState = ReturnType<typeof appReducer>;

const rootReducer = (state: RootState, action: Action) => {
  if (action.type === 'auth/logout') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export type ConfigureStore = {
  store?: Store | undefined;
  persistor?: Persistor;
  sagaMiddleware?: SagaMiddleware;
  useReduxDispatch: () => Dispatch<Action>;
  useReduxSelector: TypedUseSelectorHook<RootState>;
};

export const initializeStore = (): ConfigureStore => {
  const persistConfig = {
    timeout: 10000,
    key: 'tapthat.land',
    storage,
    blacklist: ['loader', 'modal'],
  };

  const persistedReducer = persistReducer(
    persistConfig,
    rootReducer as Reducer
  );
  const sagaMiddleware = createSagaMiddleware();
  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ thunk: false }).concat(sagaMiddleware),
  });
  const persistor = persistStore(store);
  sagaMiddleware.run(rootSaga);

  // Use throughout your app instead of plain `useDispatch` and `useSelector`
  const useReduxDispatch = () => useDispatch<typeof store.dispatch>();
  const useReduxSelector: TypedUseSelectorHook<RootState> = useSelector;

  return {
    store,
    persistor,
    sagaMiddleware,
    useReduxDispatch,
    useReduxSelector,
  };
};
