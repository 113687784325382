import React, { FormEvent, useCallback, useState } from 'react';
import { compact } from 'lodash';
import { connect, ConnectedProps } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  CARD_TYPE_OPTIONS,
  CARD_TYPES,
  FORMAT_OPTIONS,
  LEGALITY_STATUS,
  MANA_COLORS,
  PRINTINGS,
  RARITY_OPTIONS,
} from 'appConstants';
import {
  Button,
  Checkbox,
  Dropdown,
  ImageBackground,
  InputText,
  RadioButtons,
  SetsDropdown,
} from 'components/core';
import { cardsActions, modalActions, RootState } from 'store';
import { Legalities } from 'types';
import { getCdnAssetUrl, getQueryString, parseQueryString } from 'helpers';

import blackMana from '../../../../assets/images/card-symbols/B.svg';
import blueMana from '../../../../assets/images/card-symbols/U.svg';
import colorlessMana from '../../../../assets/images/card-symbols/C.svg';
import greenMana from '../../../../assets/images/card-symbols/G.svg';
import redMana from '../../../../assets/images/card-symbols/R.svg';
import whiteMana from '../../../../assets/images/card-symbols/W.svg';

import './styles.scss';

const mountain = getCdnAssetUrl('mountain.webp');

const LEGALITIES = [
  LEGALITY_STATUS.LEGAL,
  LEGALITY_STATUS.RESTRICTED,
  LEGALITY_STATUS.BANNED,
];

type Props = ConnectedProps<typeof connector> & {
  toggleVisibility: () => void;
};

const CardsSearchFilters: React.ForwardRefRenderFunction<
  HTMLDivElement,
  Props
> = ({ searchRequest, getCards, showMessage, toggleVisibility }, ref) => {
  const navigate = useNavigate();

  const searchRequestObject = parseQueryString(
    getQueryString(searchRequest?.query ?? [])
  );
  const isSimpleSearch =
    searchRequestObject?.name === searchRequestObject?.flavorText &&
    searchRequestObject?.flavorText === searchRequestObject?.text &&
    searchRequestObject?.text === searchRequestObject?.artist &&
    !!searchRequestObject?.name;

  const [availability, setAvailability] = useState<PRINTINGS[]>(
    (searchRequest?.body?.availability ?? []) as PRINTINGS[]
  );
  const [artist, setArtist] = useState(
    !isSimpleSearch ? searchRequestObject?.artist || '' : ''
  );
  const [colors, setColors] = useState<MANA_COLORS[]>(
    (searchRequest?.body?.colors ?? []) as MANA_COLORS[]
  );
  const [format, setFormat] = useState(
    Object.keys(searchRequest?.body?.legalities ?? {})[0] || ''
  );
  const [flavorText, setFlavorText] = useState(
    !isSimpleSearch ? searchRequestObject?.flavorText || '' : ''
  );
  const [legalities, setLegalities] = useState<Legalities | undefined>(
    !searchRequest?.body?.legalities
      ? undefined
      : searchRequest?.body?.legalities
  );
  const [legality, setLegality] = useState(
    !searchRequest?.body?.legalities
      ? ''
      : (searchRequest.body.legalities as { [key: string]: string | null })[
          Object.keys(searchRequest.body.legalities)[0] || ''
        ] || ''
  );
  const [manaCost, setManaCost] = useState(searchRequestObject?.manaCost || '');
  const [name, setName] = useState(
    !isSimpleSearch ? searchRequestObject?.name || '' : ''
  );
  const [power, setPower] = useState(
    !isSimpleSearch ? searchRequestObject?.power || '' : ''
  );
  const [rarity, setRarity] = useState(searchRequestObject?.rarity || '');
  const [code, setCode] = useState(searchRequestObject?.setCode || '');
  const [text, setText] = useState(
    !isSimpleSearch ? searchRequestObject?.text || '' : ''
  );
  const [toughness, setToughness] = useState(
    !isSimpleSearch ? searchRequestObject?.toughness || '' : ''
  );
  const [types, setTypes] = useState<CARD_TYPES[]>(
    ((searchRequest?.body ?? {}).types ?? []) as CARD_TYPES[]
  );

  const addOrRemoveAvailability = (
    checked: boolean,
    print: PRINTINGS,
    state: PRINTINGS[]
  ) => {
    const index = state.findIndex((value) => print === value);
    if (checked) {
      if (index === -1) {
        const copy = [...state, print];
        return copy;
      }
      return state;
    }
    if (index === -1) {
      return state;
    }

    const copy = [...state];
    copy.splice(index, 1);
    return copy;
  };

  const addOrRemoveColor = (
    checked: boolean,
    color: MANA_COLORS,
    state: MANA_COLORS[]
  ) => {
    const index = state.findIndex((value) => value === color);
    if (checked) {
      if (index === -1) {
        const copy = [...state, color];
        return copy;
      }
      return state;
    }

    if (index === -1) {
      return state;
    }
    const copy = [...state];
    copy.splice(index, 1);
    return copy;
  };

  const onCheckArena = useCallback((checked: boolean) => {
    setAvailability((prev) =>
      addOrRemoveAvailability(checked, PRINTINGS.ARENA, prev)
    );
  }, []);

  const onCheckMTGO = useCallback((checked: boolean) => {
    setAvailability((prev) =>
      addOrRemoveAvailability(checked, PRINTINGS.MAGIC_ONLINE, prev)
    );
  }, []);

  const onCheckPaper = useCallback((checked: boolean) => {
    setAvailability((prev) =>
      addOrRemoveAvailability(checked, PRINTINGS.PAPER, prev)
    );
  }, []);

  const onColorBlackSet = useCallback((checked: boolean) => {
    setColors((prev) => addOrRemoveColor(checked, MANA_COLORS.BLACK, prev));
  }, []);

  const onColorBlueSet = useCallback((checked: boolean) => {
    setColors((prev) => addOrRemoveColor(checked, MANA_COLORS.BLUE, prev));
  }, []);

  const onColorGreenSet = useCallback((checked: boolean) => {
    setColors((prev) => addOrRemoveColor(checked, MANA_COLORS.GREEN, prev));
  }, []);

  const onColorlessSet = useCallback((checked: boolean) => {
    setColors((prev) => addOrRemoveColor(checked, MANA_COLORS.COLORLESS, prev));
  }, []);

  const onColorRedSet = useCallback((checked: boolean) => {
    setColors((prev) => addOrRemoveColor(checked, MANA_COLORS.RED, prev));
  }, []);

  const onColorWhiteSet = useCallback((checked: boolean) => {
    setColors((prev) => addOrRemoveColor(checked, MANA_COLORS.WHITE, prev));
  }, []);

  const onSearch = useCallback(() => {
    toggleVisibility();
    navigate('/search');
    getCards({
      body: {
        availability: compact(availability),
        colors: compact(colors),
        legalities,
        types: compact(types),
      },
      page: 0,
      query: [
        { name: 'artist', value: artist },
        { name: 'flavorText', value: flavorText },
        { name: 'manaCost', value: manaCost },
        { name: 'name', value: name },
        { name: 'power', value: power },
        { name: 'rarity', value: rarity },
        { name: 'setCode', value: code },
        { name: 'text', value: text },
        { name: 'toughness', value: toughness },
      ],
    });
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    availability.length,
    artist,
    code,
    colors.length,
    flavorText,
    JSON.stringify(legalities),
    manaCost,
    name,
    power,
    rarity,
    text,
    toughness,
    JSON.stringify(types),
    getCards,
    navigate,
    toggleVisibility,
  ]);

  const onSetFormat = useCallback(
    (value: string) => {
      if (!legality) {
        showMessage({
          message: 'You must select a legality status first.',
          shown: true,
          title: 'Select Legality Status',
        });
        return;
      }
      if (!value.trim()) {
        setLegalities(undefined);
        setFormat('');
        return;
      }
      setFormat(value);
      setLegalities(
        value === 'historicbrawl'
          ? { brawl: legality, [value]: legality }
          : ({ [value]: legality } as Legalities)
      );
    },
    [legality, showMessage]
  );

  const onSetTypes = useCallback((value: string) => {
    setTypes([value as CARD_TYPES]);
  }, []);

  const onSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      onSearch();
    },
    [onSearch]
  );

  return (
    <div className="cardsSearchFilters" ref={ref}>
      <div className="content">
        <ImageBackground
          bgImage={mountain}
          overlayStyles={['bgOverlay']}
          styles={['background']}
        />
        <form id="searchForm" onSubmit={onSubmit}>
          <Button
            onClick={toggleVisibility}
            styles={['closeButton']}
            type="button"
          >
            <span className="icon-cross" />
          </Button>
          <div className="column row even">
            <InputText
              placeholder="Any card name, e.g Lightning Bolt, Jace"
              label="Name"
              maxLength={50}
              name="name"
              required={false}
              setValue={setName}
              type="input"
              value={name}
            />
            <InputText
              placeholder="Artist's name, e.g. John Avon"
              label="Artist"
              maxLength={50}
              name="artist"
              required={false}
              setValue={setArtist}
              type="input"
              value={artist}
            />
          </div>
          <div className="row">
            <InputText
              placeholder="Any text phrase, e.g Comes into play tapped."
              label="Text"
              maxLength={100}
              name="text"
              required={false}
              type="input"
              setValue={setText}
              value={text}
            />
          </div>
          <div className="column row set">
            <SetsDropdown
              defaultCode={searchRequestObject?.setCode}
              setCode={setCode}
            />
            <Dropdown
              defaultOptionLabel="Select Card Type"
              id="type"
              label="Card Type"
              options={CARD_TYPE_OPTIONS}
              setValue={onSetTypes}
              value={types[0]}
            />
          </div>
          <div className="column row">
            <InputText
              placeholder="e.g {U}{G}{R}, {U/P}, {1}{W}"
              label="Mana Cost"
              maxLength={20}
              name="manaCost"
              required={false}
              setValue={setManaCost}
              type="input"
              value={manaCost}
            />
            <Dropdown
              defaultOptionLabel="Select Card Rarity"
              id="rarity"
              label="Card Rarity"
              options={RARITY_OPTIONS}
              setValue={setRarity}
              value={rarity}
            />
          </div>
          <div className="column row even">
            <InputText
              placeholder="Power equal to input value"
              label="Power"
              maxLength={3}
              name="power"
              required={false}
              setValue={setPower}
              type="input"
              value={power}
            />
            <InputText
              placeholder="Toughness equal to input value"
              label="Toughness"
              maxLength={3}
              name="toughness"
              required={false}
              setValue={setToughness}
              type="input"
              value={toughness}
            />
          </div>
          <div className="column row legality">
            <RadioButtons
              label="Legality Status"
              options={LEGALITIES}
              setValue={setLegality}
              value={legality}
            />
            <Dropdown
              defaultOptionLabel="Select Format"
              id="format"
              label="Format"
              options={FORMAT_OPTIONS}
              setValue={onSetFormat}
              value={format}
            />
          </div>
          <div className="row">
            <InputText
              placeholder="Any flavor text, e.g. Release the beast."
              label="Flavor Text"
              maxLength={50}
              name="flavorText"
              required={false}
              setValue={setFlavorText}
              type="input"
              value={flavorText}
            />
          </div>
          <div className="checkboxes">
            <label htmlFor="checkboxes">Colors</label>
            <div className="column checkbox" id="checkboxes">
              <Checkbox
                defaultChecked={
                  !!colors.find((value) => value === MANA_COLORS.COLORLESS)
                }
                icon={colorlessMana}
                iconStyles={{ container: 'colorIcon' }}
                onCheck={onColorlessSet}
                value={MANA_COLORS.COLORLESS}
              />
              <Checkbox
                defaultChecked={
                  !!colors.find((value) => value === MANA_COLORS.BLACK)
                }
                icon={blackMana}
                iconStyles={{ container: 'colorIcon' }}
                onCheck={onColorBlackSet}
                value={MANA_COLORS.BLACK}
              />
              <Checkbox
                defaultChecked={
                  !!colors.find((value) => value === MANA_COLORS.BLUE)
                }
                icon={blueMana}
                iconStyles={{ container: 'colorIcon' }}
                onCheck={onColorBlueSet}
                value={MANA_COLORS.BLUE}
              />
              <Checkbox
                defaultChecked={
                  !!colors.find((value) => value === MANA_COLORS.GREEN)
                }
                icon={greenMana}
                iconStyles={{ container: 'colorIcon' }}
                onCheck={onColorGreenSet}
                value={MANA_COLORS.GREEN}
              />
              <Checkbox
                defaultChecked={
                  !!colors.find((value) => value === MANA_COLORS.RED)
                }
                icon={redMana}
                iconStyles={{ container: 'colorIcon' }}
                onCheck={onColorRedSet}
                value={MANA_COLORS.RED}
              />
              <Checkbox
                defaultChecked={
                  !!colors.find((value) => value === MANA_COLORS.WHITE)
                }
                icon={whiteMana}
                iconStyles={{ container: 'colorIcon' }}
                onCheck={onColorWhiteSet}
                value={MANA_COLORS.WHITE}
              />
            </div>
          </div>
          <div className="checkboxes">
            <label htmlFor="checkboxes">Availability</label>
            <div className="column checkbox" id="checkboxes">
              <Checkbox
                defaultChecked={
                  !!availability.find((value) => value === PRINTINGS.PAPER)
                }
                label="Paper"
                onCheck={onCheckPaper}
                value={PRINTINGS.PAPER}
              />
              <Checkbox
                defaultChecked={
                  !!availability.find(
                    (value) => value === PRINTINGS.MAGIC_ONLINE
                  )
                }
                label="Magic Online"
                onCheck={onCheckMTGO}
                value={PRINTINGS.MAGIC_ONLINE}
              />
              <Checkbox
                defaultChecked={
                  !!availability.find((value) => value === PRINTINGS.ARENA)
                }
                label="Arena"
                onCheck={onCheckArena}
                value={PRINTINGS.ARENA}
              />
            </div>
          </div>
          <div className="buttonContainer">
            <Button onClick={onSearch} label="Search" type="submit" />
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  searchRequest: state.cards.searchRequest,
});

const mapDispatchToProps = {
  getCards: cardsActions.getCards,
  showMessage: modalActions.renderMessageModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps, undefined, {
  forwardRef: true,
});

export default connector(React.forwardRef(CardsSearchFilters));
