/* eslint-disable no-unused-vars */
export enum EVENT_ACTION {
  SEARCH,
  SIMILARITY_SUGGESTIONS,
  SYNERGY_SUGGESTIONS,
  VIEW,
}

export enum EVENT_TYPE {
  ARTICLE,
  CARD,
}
