import React, { memo, useEffect, useState } from 'react';
import { CardImage } from 'components/core';
import { getSingle } from 'services';
import { Cards } from 'store';
import { Card } from 'types';

import './styles.scss';

type Props = {
  articleId: string;
  cardIds: string[];
};

const CardLinks: React.FC<Props> = ({ articleId, cardIds }) => {
  const [cards, setCards] = useState<Cards>([]);

  useEffect(() => {
    (async () => {
      const result = await Promise.allSettled(
        cardIds.map((cardId) => getSingle({ payload: cardId }, false))
      );
      const fetched = result
        .filter((result) => result.status === 'fulfilled')
        .map((value) => value.value.data as Card);
      setCards(fetched);
    })();
  }, [cardIds]);

  return (
    <div className="cardLinks">
      {cards.map((card) => (
        <CardImage
          key={card.uuid}
          articleId={articleId}
          card={card}
          styles={['card']}
        />
      ))}
    </div>
  );
};

export default memo(CardLinks);
