import { isEqual } from 'lodash';
import React, { memo, useCallback, useEffect, useRef } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import { getCdnAssetUrl } from 'helpers';
import { RootState, sidebarActions, usersActions } from 'store';

import './styles.scss';

const kofiSymbol = getCdnAssetUrl('kofi_symbol.png');

type Props = ConnectedProps<typeof connector> & {
  type: 'left' | 'right';
};

const Sidebar: React.FC<Props> = ({
  isLeftSidebarOpen,
  isRightSidebarOpen,
  type,
  // user,
  // logout,
  toggleLeftSidebar,
  toggleRightSidebar,
}) => {
  // const onLogOut = useCallback(() => {
  //   if (user?.id) {
  //     toggleRightSidebar();
  //     logout(user?.id);
  //   }
  // }, [user?.id, logout, toggleRightSidebar]);
  const donationPanel = useRef<HTMLElement | null>(null);
  const troubleshootPopup = useRef<HTMLElement | null>(null);

  useEffect(() => {
    donationPanel.current = document.getElementById('kofiframe');
    troubleshootPopup.current = document.getElementById('troubleshoot');
  }, []);

  const toggleDonationPanel = useCallback(() => {
    if (donationPanel.current) {
      if (donationPanel.current.classList.contains('hidden')) {
        donationPanel.current.classList.remove('hidden');
        donationPanel.current.classList.add('slide-up');
      } else {
        donationPanel.current.classList.add('hidden');
        donationPanel.current.classList.remove('slide-up');
      }
    }
  }, [donationPanel]);

  const toggleTroubleshootPopup = useCallback(() => {
    if (troubleshootPopup.current) {
      if (troubleshootPopup.current.classList.contains('hidden')) {
        troubleshootPopup.current.classList.remove('hidden');
        troubleshootPopup.current.classList.add('slide-up');
      } else {
        troubleshootPopup.current.classList.add('hidden');
        troubleshootPopup.current.classList.remove('slide-up');
      }
    }
  }, [troubleshootPopup]);

  const toggleLeft = useCallback(
    () => toggleLeftSidebar(),
    [toggleLeftSidebar]
  );

  const toggleRight = useCallback(() => {
    if (
      donationPanel.current &&
      donationPanel.current.classList.contains('slide-up')
    ) {
      toggleDonationPanel();
    }
    if (
      troubleshootPopup.current &&
      troubleshootPopup.current.classList.contains('slide-up')
    ) {
      toggleTroubleshootPopup();
    }
    toggleRightSidebar();
  }, [toggleDonationPanel, toggleTroubleshootPopup, toggleRightSidebar]);

  return (
    <div
      className={`sidebarContainer${
        isLeftSidebarOpen || isRightSidebarOpen ? ' toggled' : ''
      }`}
    >
      {type === 'left' ? (
        isLeftSidebarOpen ? (
          <>
            <div
              aria-hidden={true}
              onClick={toggleLeft}
              className={isLeftSidebarOpen ? 'leftSidebarOutside' : ''}
            />
            <div
              className={`leftSidebar${isLeftSidebarOpen ? ' toggled' : ''}`}
            >
              <div className="linksContainer">
                <Link className="links" to="/">
                  <span className="icon-rewards-home clickable" />
                  Home
                </Link>
              </div>
            </div>
          </>
        ) : null
      ) : isRightSidebarOpen ? (
        <>
          <div
            aria-hidden={true}
            onClick={toggleRight}
            className={isRightSidebarOpen ? 'rightSidebarOutside' : ''}
          />
          <div
            className={`rightSidebar${isRightSidebarOpen ? ' toggled' : ''}`}
          >
            <div className="linksContainer">
              <Link className="links" to="/" onClick={toggleRight}>
                <span className="icon-home clickable" />
                Home
              </Link>
              <Link
                className="links"
                to="/articles?scroll=0"
                onClick={toggleRight}
              >
                <span className="icon-articles clickable" />
                Articles
              </Link>
              <button
                className="links buttonLink"
                onClick={toggleTroubleshootPopup}
              >
                <span className="icon-wrench" />
                Troubleshoot
              </button>
              <a
                className="links"
                href="https://www.facebook.com/people/Tap-That-Land/61573977905618/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span className="icon-facebook" />
                Follow Us
              </a>
              {/* {user ? (
                <>
                  <Link className="links" to="/profile" onClick={toggleRight}>
                    <span className="icon-profile clickable" />
                    Profile
                  </Link>
                  <button className="links" onClick={onLogOut}>
                    <span className="icon-exit clickable" />
                    Log Out
                  </button>
                </>
              ) : (
                <Link className="links" to="/login" onClick={toggleRight}>
                  <span className="icon-enter clickable" />
                  Log In
                </Link>
              )} */}
            </div>
            <div className="linksContainer kofiContainer">
              <button
                className="links buttonLink"
                onClick={toggleDonationPanel}
              >
                <span>
                  <img
                    alt="Support Us on Ko-fi"
                    className="kofiIcon"
                    src={kofiSymbol}
                  />
                </span>
                Support Us
              </button>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLeftSidebarOpen: state.sidebar.leftSidebar,
  isRightSidebarOpen: state.sidebar.rightSidebar,
  user: state.users.user,
});

const mapDispatchToProps = {
  logout: usersActions.logout,
  toggleLeftSidebar: sidebarActions.toggleLeftSidebar,
  toggleRightSidebar: sidebarActions.toggleRightSidebar,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(
  memo(Sidebar, (prevProps, nextProps) => isEqual(prevProps, nextProps))
);
