import React, { memo, MouseEventHandler } from 'react';
import { ImageLoader } from 'components/core';
import { getCdnAssetUrl } from 'helpers';

import './styles.scss';

const tapThatLandLogo = getCdnAssetUrl('tapthatland.webp');

type Props = {
  toggleLeftSidebar?: MouseEventHandler<HTMLButtonElement>;
  toggleRightSidebar?: MouseEventHandler<HTMLButtonElement>;
};

const Topbar: React.FC<Props> = ({ toggleLeftSidebar, toggleRightSidebar }) => {
  return (
    <header id="topbar" className="topbar">
      <div id="topbar_container" className="container">
        {window.history.length > 0 && (
          <button
            type="button"
            className="menuBtn"
            onClick={toggleLeftSidebar}
            aria-label="Back Button"
          >
            <span className="icon-back clickable" />
          </button>
        )}

        <div className="logo">
          <ImageLoader
            src={tapThatLandLogo}
            styles={{ container: 'imgContainer' }}
          />
        </div>

        <button
          type="button"
          id="topbar_link_right"
          className="menu"
          onClick={toggleRightSidebar}
          aria-label="Toggle Sidebar"
        >
          <span className="icon-menu clickable" />
        </button>
      </div>
    </header>
  );
};

export default memo(Topbar);
