import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Errors, Set } from 'types';

export type Sets = Set[];

export type SetsState = {
  sets: Sets;
  selectedSet: Set | null;
  errors?: Errors;
  fetching: boolean;
};

const initialState: SetsState = {
  sets: [],
  selectedSet: null,
  errors: undefined,
  fetching: false,
};

const setsSlices = createSlice({
  name: 'sets',
  initialState,
  reducers: {
    failure: (state, action: PayloadAction<Errors>) => {
      state.errors = action.payload;
      state.fetching = false;
    },
    request: (state) => {
      state.errors = undefined;
      state.fetching = true;
    },
    reset: (state) => {
      state.errors = undefined;
      state.fetching = false;
    },
    setSets: (state, action: PayloadAction<Sets>) => {
      state.errors = undefined;
      state.fetching = false;
      state.sets = action.payload;
    },
    setSelectedSet: (state, action: PayloadAction<Set>) => {
      state.errors = undefined;
      state.fetching = false;
      state.selectedSet = action.payload;
    },
  },
});

export const setsActions = {
  ...setsSlices.actions,
  getAllSets: createAction('sets/getAllSets'),
};

export default setsSlices.reducer;
