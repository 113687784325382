import React, { memo } from 'react';

import './styles.scss';

type Props = {
  bgImage: string;
  overlayStyles?: string[];
  styles?: string[];
};

const ImageBackground: React.FC<Props> = ({
  bgImage,
  overlayStyles = [],
  styles = [],
}) => {
  return (
    <div
      className={`imageBackground${styles.length > 0 ? ` ${styles.join(' ')}` : ''}`}
    >
      <div
        className={`bgContainer`}
        style={{
          backgroundImage: `url(${bgImage})`,
        }}
      />
      <div
        className={`overlay${overlayStyles.length > 0 ? ` ${overlayStyles.join(' ')}` : ''}`}
      />
    </div>
  );
};

export default memo(ImageBackground);
