import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AppState = {
  scrollTop: number;
};

const initialState: AppState = {
  scrollTop: 0,
};

const appSlices = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setScrollTop: (state, action: PayloadAction<number>) => {
      state.scrollTop = action.payload;
    },
  },
});

export const appActions = {
  ...appSlices.actions,
};

export default appSlices.reducer;
