import { ERROR_MESSAGES } from 'appConstants';
import { config } from 'config';
import {
  isValidBusinessAddress,
  isValidEmail,
  isValidPassword,
  isValidUsername,
  getFailure,
} from 'helpers';
import {
  ACCOUNT_TYPES,
  AuthenticatedUser,
  GetUserPayload,
  RequestPayload,
  Response,
  UpdateUserPayload,
  User,
} from 'types';

import { callApi, callApiWithAuth } from './base';

/**
 * Sends a GET request to the get user API endpoint.
 * @param {RequestPayload<GetUserPayload>} data - a RequestPayload object containing a user's access token and ID.
 * @returns {Promise<Response>}
 */
export const getUserById = async (
  data: RequestPayload<GetUserPayload>
): Promise<Response<User>> => {
  const {
    payload: { token = '', userId = '' },
  } = data;
  try {
    const response = await callApiWithAuth({
      url: `${config.apiUrl}api/users/${userId}`,
      token: token ?? '',
    });
    const { data } = (response ?? {}) as { [key: string]: any };
    return data as Response<User>;
  } catch (error) {
    console.log('[DEBUG] getUserById with error', error);
    return getFailure(ERROR_MESSAGES.UNKNOWN_ERROR);
  }
};

/**
 * Sends a POST request to the login API endpoint.
 * @param {RequestPayload<User>} data - a RequestPayload object containing a user's credentials including password and email/username.
 * @returns {Promise<Response>}
 */
export const login = async (
  data: RequestPayload<User>
): Promise<Response<AuthenticatedUser>> => {
  const { payload } = data;
  const { email, password, username } = payload;

  if (email && !isValidEmail(email)) {
    return getFailure(ERROR_MESSAGES.INVALID_EMAIL_ADDRESS);
  }
  if (username && !isValidUsername(username)) {
    return getFailure(ERROR_MESSAGES.INVALID_USERNAME);
  }
  if (!password?.trim() || !isValidPassword(password)) {
    return getFailure(ERROR_MESSAGES.INVALID_PASSWORD);
  }

  try {
    const response = await callApi({
      url: `${config.apiUrl}api/users/login`,
      method: 'POST',
      data: payload,
    });
    const { data } = (response ?? {}) as { [key: string]: any };
    return data as Response<AuthenticatedUser>;
  } catch (error) {
    console.log('[DEBUG] login with error', error);
    return getFailure(ERROR_MESSAGES.UNKNOWN_ERROR);
  }
};

/**
 * Sends a POST request to the logout API endpoint.
 * @param {RequestPayload<string>} data - a RequestPayload object containing an access token.
 * @returns {Promise<Response>}
 */
export const logout = async (
  data: RequestPayload<string>
): Promise<Response<boolean>> => {
  const { payload: token } = data;
  try {
    const response = await callApiWithAuth({
      url: `${config.apiUrl}api/users/logout`,
      method: 'POST',
      token,
    });
    const { data } = (response ?? {}) as { [key: string]: any };
    return data as Response<boolean>;
  } catch (error) {
    console.log('[DEBUG] logout with error', error);
    return getFailure(ERROR_MESSAGES.UNKNOWN_ERROR);
  }
};

/**
 * Sends a POST request to the refresh token API endpoint.
 * @param {RequestPayload<string>} data - a RequestPayload object containing an existing refresh token.
 * @returns {Promise<Response>}
 */
export const refresh = async (
  data: RequestPayload<string>
): Promise<Response<AuthenticatedUser>> => {
  const { payload: refreshToken = '' } = data;
  try {
    const response = await callApi({
      url: `${config.apiUrl}api/users/refresh`,
      method: 'POST',
      data: { refreshToken },
    });
    const { data } = (response ?? {}) as { [key: string]: any };
    return data as Response<AuthenticatedUser>;
  } catch (error) {
    console.log('[DEBUG] refresh with error', error);
    return getFailure(ERROR_MESSAGES.UNKNOWN_ERROR);
  }
};

/**
 * Sends a POST request to the create user API endpoint.
 * @param {RequestPayload<User>} data - a RequestPayload object containing a user's registration information.
 * @returns {Promise<Response>}
 */
export const register = async (
  data: RequestPayload<User>
): Promise<Response<User>> => {
  const { payload } = data;
  const { accountType, businessAddress, email, password, username } = payload;

  if (
    !isValidEmail(email) ||
    !isValidUsername(username) ||
    !isValidPassword(password)
  ) {
    return getFailure(ERROR_MESSAGES.INVALID_USER_REGISTRATION_CREDENTIALS);
  }

  if (
    accountType === ACCOUNT_TYPES.BUSINESS &&
    !isValidBusinessAddress(businessAddress)
  ) {
    return getFailure(ERROR_MESSAGES.INVALID_BUSINESS_ADDRESS);
  }

  try {
    const response = await callApi({
      url: `${config.apiUrl}api/users`,
      method: 'POST',
      data: payload,
    });
    const { data } = (response ?? {}) as { [key: string]: any };
    return data as Response<User>;
  } catch (error) {
    console.log('[DEBUG] register with error', error);
    return getFailure(ERROR_MESSAGES.UNKNOWN_ERROR);
  }
};

/**
 * Sends a POST request to the update user API endpoint.
 * @param {RequestPayload<UpdateUserPayload>} data - a RequestPayload object containing a user's previous and updated information.
 * @returns {Promise<Response>}
 */
export const update = async (
  data: RequestPayload<UpdateUserPayload>
): Promise<Response<User>> => {
  const { payload } = data;
  const {
    data: { previous, updated },
    token,
  } = payload;
  const { id, accountType, email, username } = previous;
  const { businessAddress, password } = updated;

  if (
    !isValidEmail(email) ||
    !isValidUsername(username) ||
    !isValidPassword(password)
  ) {
    return getFailure(ERROR_MESSAGES.INVALID_USER_REGISTRATION_CREDENTIALS);
  }

  if (
    accountType === ACCOUNT_TYPES.BUSINESS &&
    !isValidBusinessAddress(businessAddress)
  ) {
    return getFailure(ERROR_MESSAGES.INVALID_BUSINESS_ADDRESS);
  }

  try {
    const response = await callApiWithAuth({
      url: `${config.apiUrl}api/users/${id}`,
      method: 'POST',
      data: {
        accountType,
        businessAddress,
        email,
        password,
        username,
      },
      token,
    });
    const { data } = (response ?? {}) as { [key: string]: any };
    return data as Response<User>;
  } catch (error) {
    console.log('[DEBUG] update with error', error);
    return getFailure(ERROR_MESSAGES.UNKNOWN_ERROR);
  }
};
