import { useEffect } from 'react';
import {
  AmazonAffiliateProductsState,
  AppState,
  articlesActions,
  ArticlesState,
  cardsActions,
  CardsState,
  eventsActions,
  EventsState,
  LoaderState,
  metaActions,
  MetaState,
  modalActions,
  ModalState,
  RootState,
  setsActions,
  SetsState,
  SidebarState,
  usersActions,
  UsersState,
} from 'store';

import { useReduxHooks } from './useReduxHooks';

export const useErrorMessage = (
  stateName: 'articles' | 'cards' | 'events' | 'meta' | 'sets' | 'users',
  onClose?: () => void
) => {
  const { useReduxDispatch, useReduxSelector: useSelector } =
    useReduxHooks() ?? {};

  const dispatch = useReduxDispatch?.();
  const rootState = useSelector?.(
    (rootState: RootState) => rootState[stateName as keyof RootState]
  );

  const getActions = (
    name: 'articles' | 'cards' | 'events' | 'meta' | 'sets' | 'users'
  ) => {
    switch (name) {
      case 'articles':
        return articlesActions;
      case 'cards':
        return cardsActions;
      case 'events':
        return eventsActions;
      case 'meta':
        return metaActions;
      case 'sets':
        return setsActions;
      case 'users':
        return usersActions;
    }
  };

  const getState = (
    name: 'articles' | 'cards' | 'events' | 'meta' | 'sets' | 'users',
    state?:
      | AmazonAffiliateProductsState
      | AppState
      | ArticlesState
      | CardsState
      | EventsState
      | LoaderState
      | MetaState
      | ModalState
      | SetsState
      | SidebarState
      | UsersState
  ) => {
    if (!state) {
      return null;
    }
    switch (name) {
      case 'articles':
        return state as ArticlesState;
      case 'cards':
        return state as CardsState;
      case 'events':
        return state as EventsState;
      case 'meta':
        return state as MetaState;
      case 'sets':
        return state as SetsState;
      case 'users':
        return state as UsersState;
    }
  };

  const actions = getActions(stateName);
  const finalState = getState(stateName, rootState);

  useEffect(() => {
    if (actions.reset.type && finalState?.errors && dispatch) {
      dispatch({
        payload: {
          isMessageModalShown: true,
          message: finalState.errors,
          cbClose: () => {
            onClose?.();
            dispatch({
              type: actions.reset.type,
            });
          },
        },
        type: modalActions.renderMessageModal.type,
      });
    }
  }, [actions.reset.type, finalState?.errors, dispatch, onClose]);
};
