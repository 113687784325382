import React, { memo, useEffect, useState } from 'react';
import moment from 'moment';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import {
  AMAZON_AFFILIATE_MESSAGES,
  ASPECT_RATIOS,
  SCREEN_WIDTH,
} from 'appConstants';
import { CardLinks, Deck, Products, YouTubeIframe } from 'components/core';
import { Article } from 'types';

import './styles.scss';

type Props = {
  article: Article;
  preview?: boolean;
};

const ArticleItem: React.FC<Props> = ({ article, preview = false }) => {
  const [
    amazonAffiliateProductsContainer,
    setAmazonAffiliateProductsContainer,
  ] = useState<HTMLElement | null>(null);
  const [deckContainer, setDeckContainer] = useState<HTMLElement | null>(null);
  const [linksContainer, setLinksContainer] = useState<HTMLElement | null>(
    null
  );
  const [youTubeContainer, setYouTubeContainer] = useState<HTMLElement | null>(
    null
  );

  const videoWidth = SCREEN_WIDTH - 20;
  const videoHeight = videoWidth / ASPECT_RATIOS.FULL_HD_1080;

  useEffect(() => {
    if (preview) {
      return;
    }
    const root = document.getElementById('deck-container');
    if (root) {
      setDeckContainer(root);
    }
  }, [preview]);

  useEffect(() => {
    if (preview) {
      return;
    }
    const root = document.getElementById('links-container');
    if (root) {
      setLinksContainer(root);
    }
  }, [preview]);

  useEffect(() => {
    if (preview) {
      return;
    }
    const root = document.getElementById('youtube-container');
    if (root) {
      setYouTubeContainer(root);
    }
  }, [preview]);

  useEffect(() => {
    if (preview) {
      return;
    }
    const root = document.getElementById('amazon-affiliate-products');
    if (root) {
      setAmazonAffiliateProductsContainer(root);
    }
  }, [preview]);

  if (preview) {
    return (
      <Link className="articleItem preview" to={`/article/${article.id}`}>
        <h3>{article.title}</h3>
        {preview && (
          <span className="preview">{`Published ${moment(article.releaseDate).format('Do MMMM, YYYY HH:mm')}`}</span>
        )}
        <span
          className={`content${preview ? ' preview' : ''}`}
          dangerouslySetInnerHTML={{
            __html: article.previewBody,
          }}
        />
      </Link>
    );
  }

  return (
    <div className="articleItem">
      <h3>{article.title}</h3>
      <span>{`Published ${moment(article.releaseDate).format('Do MMMM, YYYY HH:mm')}`}</span>
      <div
        className="content"
        dangerouslySetInnerHTML={{
          __html: article.body
            .replace('[DECK]', '<div id="deck-container"></div>')
            .replace(
              '[CARD_LINKS]',
              `<div id="links-container"></div>
               <div id="amazon-affiliate-products"></div>`
            )
            .replace('[YOUTUBE]', '<div id="youtube-container"></div>'),
        }}
      />
      {!!amazonAffiliateProductsContainer &&
        !!article.deck &&
        ReactDOM.createPortal(
          <div className="affiliateProducts">
            <Products />
            <p className="affiliateNote">
              {AMAZON_AFFILIATE_MESSAGES.AFFILIATE_MARKETING_NOTE}
            </p>
          </div>,
          amazonAffiliateProductsContainer
        )}
      {!!deckContainer &&
        !!article.deck &&
        ReactDOM.createPortal(
          <Deck articleId={article.id!} deck={article.deck} />,
          deckContainer
        )}
      {!!linksContainer &&
        !!article.cardLinks &&
        ReactDOM.createPortal(
          <CardLinks
            articleId={article.id!}
            cardIds={article.cardLinks ?? []}
          />,
          linksContainer
        )}
      {!!youTubeContainer &&
        !!article.youtube &&
        ReactDOM.createPortal(
          <YouTubeIframe
            height={videoHeight}
            {...article.youtube}
            width={videoWidth}
          />,
          youTubeContainer
        )}
    </div>
  );
};

export default memo(ArticleItem, (prevProps, nextProps) =>
  prevProps.preview === nextProps.preview && !nextProps.preview
    ? prevProps.article.body === nextProps.article.body
    : prevProps.article.previewBody === nextProps.article.previewBody
);
