import tSymbol from '../assets/images/card-symbols/T.svg';
import qSymbol from '../assets/images/card-symbols/Q.svg';
import eSymbol from '../assets/images/card-symbols/E.svg';
import pSymbol from '../assets/images/card-symbols/P.svg';
import pwSymbol from '../assets/images/card-symbols/PW.svg';
import chaosSymbol from '../assets/images/card-symbols/CHAOS.svg';
import aSymbol from '../assets/images/card-symbols/A.svg';
import tkSymbol from '../assets/images/card-symbols/TK.svg';
import xSymbol from '../assets/images/card-symbols/X.svg';
import ySymbol from '../assets/images/card-symbols/Y.svg';
import zSymbol from '../assets/images/card-symbols/Z.svg';
import zeroGenericSymbol from '../assets/images/card-symbols/0.svg';
import halfSymbol from '../assets/images/card-symbols/HALF.svg';
import oneGenericSymbol from '../assets/images/card-symbols/1.svg';
import twoGenericSymbol from '../assets/images/card-symbols/2.svg';
import threeGenericSymbol from '../assets/images/card-symbols/3.svg';
import fourGenericSymbol from '../assets/images/card-symbols/4.svg';
import fiveGenericSymbol from '../assets/images/card-symbols/5.svg';
import sixGenericSymbol from '../assets/images/card-symbols/6.svg';
import sevenGenericSymbol from '../assets/images/card-symbols/7.svg';
import eightGenericSymbol from '../assets/images/card-symbols/8.svg';
import nineGenericSymbol from '../assets/images/card-symbols/9.svg';
import tenGenericSymbol from '../assets/images/card-symbols/10.svg';
import elevenGenericSymbol from '../assets/images/card-symbols/11.svg';
import twelveGenericSymbol from '../assets/images/card-symbols/12.svg';
import thirteenGenericSymbol from '../assets/images/card-symbols/13.svg';
import fourteenGenericSymbol from '../assets/images/card-symbols/14.svg';
import fifteenGenericSymbol from '../assets/images/card-symbols/15.svg';
import sixteenGenericSymbol from '../assets/images/card-symbols/16.svg';
import seventeenGenericSymbol from '../assets/images/card-symbols/17.svg';
import eighteenGenericSymbol from '../assets/images/card-symbols/18.svg';
import nineteenGenericSymbol from '../assets/images/card-symbols/19.svg';
import twentyGenericSymbol from '../assets/images/card-symbols/20.svg';
import hundredGenericSymbol from '../assets/images/card-symbols/100.svg';
import millionGenericSymbol from '../assets/images/card-symbols/1000000.svg';
import infinitySymbol from '../assets/images/card-symbols/INFINITY.svg';
import wuSymbol from '../assets/images/card-symbols/WU.svg';
import wbSymbol from '../assets/images/card-symbols/WB.svg';
import brSymbol from '../assets/images/card-symbols/BR.svg';
import bgSymbol from '../assets/images/card-symbols/BG.svg';
import ubSymbol from '../assets/images/card-symbols/UB.svg';
import urSymbol from '../assets/images/card-symbols/UR.svg';
import rgSymbol from '../assets/images/card-symbols/RG.svg';
import rwSymbol from '../assets/images/card-symbols/RW.svg';
import gwSymbol from '../assets/images/card-symbols/GW.svg';
import guSymbol from '../assets/images/card-symbols/GU.svg';
import bgpSymbol from '../assets/images/card-symbols/BGP.svg';
import brpSymbol from '../assets/images/card-symbols/BRP.svg';
import gupSymbol from '../assets/images/card-symbols/GUP.svg';
import gwpSymbol from '../assets/images/card-symbols/GWP.svg';
import rgpSymbol from '../assets/images/card-symbols/RGP.svg';
import rwpSymbol from '../assets/images/card-symbols/RWP.svg';
import ubpSymbol from '../assets/images/card-symbols/UBP.svg';
import urpSymbol from '../assets/images/card-symbols/URP.svg';
import wbpSymbol from '../assets/images/card-symbols/WBP.svg';
import wupSymbol from '../assets/images/card-symbols/WUP.svg';
import cwSymbol from '../assets/images/card-symbols/CW.svg';
import cuSymbol from '../assets/images/card-symbols/CU.svg';
import cbSymbol from '../assets/images/card-symbols/CB.svg';
import crSymbol from '../assets/images/card-symbols/CR.svg';
import cgSymbol from '../assets/images/card-symbols/CG.svg';
import twoWSymbol from '../assets/images/card-symbols/2W.svg';
import twoUSymbol from '../assets/images/card-symbols/2U.svg';
import twoBSymbol from '../assets/images/card-symbols/2B.svg';
import twoRSymbol from '../assets/images/card-symbols/2R.svg';
import twoGSymbol from '../assets/images/card-symbols/2G.svg';
import hSymbol from '../assets/images/card-symbols/H.svg';
import wpSymbol from '../assets/images/card-symbols/WP.svg';
import upSymbol from '../assets/images/card-symbols/UP.svg';
import bpSymbol from '../assets/images/card-symbols/BP.svg';
import rpSymbol from '../assets/images/card-symbols/RP.svg';
import gpSymbol from '../assets/images/card-symbols/GP.svg';
import cpSymbol from '../assets/images/card-symbols/CP.svg';
import hwSymbol from '../assets/images/card-symbols/HW.svg';
import hrSymbol from '../assets/images/card-symbols/HR.svg';
import wSymbol from '../assets/images/card-symbols/W.svg';
import uSymbol from '../assets/images/card-symbols/U.svg';
import bSymbol from '../assets/images/card-symbols/B.svg';
import rSymbol from '../assets/images/card-symbols/R.svg';
import gSymbol from '../assets/images/card-symbols/G.svg';
import cSymbol from '../assets/images/card-symbols/C.svg';
import sSymbol from '../assets/images/card-symbols/S.svg';
import lSymbol from '../assets/images/card-symbols/L.svg';
import dSymbol from '../assets/images/card-symbols/D.svg';

export const CARD_SYMBOLS = [
  {
    symbol: '{T}',
    svg: tSymbol,
  },
  {
    symbol: '{Q}',
    svg: qSymbol,
  },
  {
    symbol: '{E}',
    svg: eSymbol,
  },
  {
    symbol: '{P}',
    svg: pSymbol,
  },
  {
    symbol: '{PW}',
    svg: pwSymbol,
  },
  {
    symbol: '{CHAOS}',
    svg: chaosSymbol,
  },
  {
    symbol: '{A}',
    svg: aSymbol,
  },
  {
    symbol: '{TK}',
    svg: tkSymbol,
  },
  {
    symbol: '{X}',
    svg: xSymbol,
  },
  {
    symbol: '{Y}',
    svg: ySymbol,
  },
  {
    symbol: '{Z}',
    svg: zSymbol,
  },
  {
    symbol: '{0}',
    svg: zeroGenericSymbol,
  },
  {
    symbol: '{½}',
    svg: halfSymbol,
  },
  {
    symbol: '{1}',
    svg: oneGenericSymbol,
  },
  {
    symbol: '{2}',
    svg: twoGenericSymbol,
  },
  {
    symbol: '{3}',
    svg: threeGenericSymbol,
  },
  {
    symbol: '{4}',
    svg: fourGenericSymbol,
  },
  {
    symbol: '{5}',
    svg: fiveGenericSymbol,
  },
  {
    symbol: '{6}',
    svg: sixGenericSymbol,
  },
  {
    symbol: '{7}',
    svg: sevenGenericSymbol,
  },
  {
    symbol: '{8}',
    svg: eightGenericSymbol,
  },
  {
    symbol: '{9}',
    svg: nineGenericSymbol,
  },
  {
    symbol: '{10}',
    svg: tenGenericSymbol,
  },
  {
    symbol: '{11}',
    svg: elevenGenericSymbol,
  },
  {
    symbol: '{12}',
    svg: twelveGenericSymbol,
  },
  {
    symbol: '{13}',
    svg: thirteenGenericSymbol,
  },
  {
    symbol: '{14}',
    svg: fourteenGenericSymbol,
  },
  {
    symbol: '{15}',
    svg: fifteenGenericSymbol,
  },
  {
    symbol: '{16}',
    svg: sixteenGenericSymbol,
  },
  {
    symbol: '{17}',
    svg: seventeenGenericSymbol,
  },
  {
    symbol: '{18}',
    svg: eighteenGenericSymbol,
  },
  {
    symbol: '{19}',
    svg: nineteenGenericSymbol,
  },
  {
    symbol: '{20}',
    svg: twentyGenericSymbol,
  },
  {
    symbol: '{100}',
    svg: hundredGenericSymbol,
  },
  {
    symbol: '{1000000}',
    svg: millionGenericSymbol,
  },
  {
    symbol: '{∞}',
    svg: infinitySymbol,
  },
  {
    symbol: '{W/U}',
    svg: wuSymbol,
  },
  {
    symbol: '{W/B}',
    svg: wbSymbol,
  },
  {
    symbol: '{B/R}',
    svg: brSymbol,
  },
  {
    symbol: '{B/G}',
    svg: bgSymbol,
  },
  {
    symbol: '{U/B}',
    svg: ubSymbol,
  },
  {
    symbol: '{U/R}',
    svg: urSymbol,
  },
  {
    symbol: '{R/G}',
    svg: rgSymbol,
  },
  {
    symbol: '{R/W}',
    svg: rwSymbol,
  },
  {
    symbol: '{G/W}',
    svg: gwSymbol,
  },
  {
    symbol: '{G/U}',
    svg: guSymbol,
  },
  {
    symbol: '{B/G/P}',
    svg: bgpSymbol,
  },
  {
    symbol: '{B/R/P}',
    svg: brpSymbol,
  },
  {
    symbol: '{G/U/P}',
    svg: gupSymbol,
  },
  {
    symbol: '{G/W/P}',
    svg: gwpSymbol,
  },
  {
    symbol: '{R/G/P}',
    svg: rgpSymbol,
  },
  {
    symbol: '{R/W/P}',
    svg: rwpSymbol,
  },
  {
    symbol: '{U/B/P}',
    svg: ubpSymbol,
  },
  {
    symbol: '{U/R/P}',
    svg: urpSymbol,
  },
  {
    symbol: '{W/B/P}',
    svg: wbpSymbol,
  },
  {
    symbol: '{W/U/P}',
    svg: wupSymbol,
  },
  {
    symbol: '{C/W}',
    svg: cwSymbol,
  },
  {
    symbol: '{C/U}',
    svg: cuSymbol,
  },
  {
    symbol: '{C/B}',
    svg: cbSymbol,
  },
  {
    symbol: '{C/R}',
    svg: crSymbol,
  },
  {
    symbol: '{C/G}',
    svg: cgSymbol,
  },
  {
    symbol: '{2/W}',
    svg: twoWSymbol,
  },
  {
    symbol: '{2/U}',
    svg: twoUSymbol,
  },
  {
    symbol: '{2/B}',
    svg: twoBSymbol,
  },
  {
    symbol: '{2/R}',
    svg: twoRSymbol,
  },
  {
    symbol: '{2/G}',
    svg: twoGSymbol,
  },
  {
    symbol: '{H}',
    svg: hSymbol,
  },
  {
    symbol: '{W/P}',
    svg: wpSymbol,
  },
  {
    symbol: '{U/P}',
    svg: upSymbol,
  },
  {
    symbol: '{B/P}',
    svg: bpSymbol,
  },
  {
    symbol: '{R/P}',
    svg: rpSymbol,
  },
  {
    symbol: '{G/P}',
    svg: gpSymbol,
  },
  {
    symbol: '{C/P}',
    svg: cpSymbol,
  },
  {
    symbol: '{HW}',
    svg: hwSymbol,
  },
  {
    symbol: '{HR}',
    svg: hrSymbol,
  },
  {
    symbol: '{W}',
    svg: wSymbol,
  },
  {
    symbol: '{U}',
    svg: uSymbol,
  },
  {
    symbol: '{B}',
    svg: bSymbol,
  },
  {
    symbol: '{R}',
    svg: rSymbol,
  },
  {
    symbol: '{G}',
    svg: gSymbol,
  },
  {
    symbol: '{C}',
    svg: cSymbol,
  },
  {
    symbol: '{S}',
    svg: sSymbol,
  },
  {
    symbol: '{L}',
    svg: lSymbol,
  },
  {
    symbol: '{D}',
    svg: dSymbol,
  },
];
