import React, { memo, useCallback } from 'react';
import { isEqual } from 'lodash';
import { connect, ConnectedProps } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'components/core';
import * as GridComponents from 'components/grid';
import { modalActions, ComponentModalState, RootState } from 'store';

import './styles.scss';

type Props = ConnectedProps<typeof connector> & ComponentModalState;

const ComponentModal: React.FC<Props> = ({
  contentWrapStyles,
  gridConfig,
  isComponentModalShown = false,
  isHeaderHidden,
  isPopupOnly = false,
  modalStyles,
  hideModal,
  redirectUrl = '/',
  shown = false,
  title,
}) => {
  const navigate = useNavigate();

  const onCloseModal = useCallback(() => {
    hideModal();
    if (!isPopupOnly) {
      navigate(redirectUrl);
    } else {
      navigate(-1);
    }
  }, [hideModal, isPopupOnly, redirectUrl, navigate]);

  const Component: React.FC =
    GridComponents[gridConfig?.componentName as keyof typeof GridComponents];

  return shown || isComponentModalShown ? (
    <Modal
      title={title}
      isHeaderHidden={isHeaderHidden}
      cbClose={onCloseModal}
      modalStyles={modalStyles}
      contentWrapStyles={
        contentWrapStyles
          ? ['componentModal', ...contentWrapStyles]
          : ['componentModal']
      }
    >
      {Component === null ? null : <Component {...gridConfig.props} />}
    </Modal>
  ) : null;
};

const mapStateToProps = (state: RootState) => ({
  isComponentModalShown: state.modal.componentModal.shown,
});

const mapDispatchToProps = {
  hideModal: modalActions.hideComponentModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(
  memo(ComponentModal, (prevProps, nextProps) => isEqual(prevProps, nextProps))
);
