import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { SEARCH_CONSTANTS } from 'appConstants';
import {
  ArticleItem,
  ArticlesPagination,
  ArticlesSearchBar,
  Footer,
  ImageBackground,
  Loader,
} from 'components/core';
import { getCdnAssetUrl } from 'helpers';
import { useErrorMessage, useResetScrollTop, useScrollTop } from 'hooks';
import { articlesActions, RootState } from 'store';

import './styles.scss';

const bgImage = getCdnAssetUrl('forest.webp');

type Props = ConnectedProps<typeof connector>;

const Articles: React.FC<Props> = ({
  articles = [],
  searching,
  totalArticles,
  getArticles,
}) => {
  useErrorMessage('articles');
  useResetScrollTop(true);
  useScrollTop();

  useEffect(
    () => {
      getArticles({ page: 0 });
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <div className="articles">
        <ImageBackground bgImage={bgImage} />
        <ArticlesSearchBar />
        <div className="container">
          {articles.map((value) => (
            <ArticleItem key={value.id} article={value} preview />
          ))}
        </div>
        {totalArticles > SEARCH_CONSTANTS.FETCH_COUNT && (
          <ArticlesPagination styles={['bottomPagination']} />
        )}
        <Footer />
      </div>
      {searching && <Loader type="screen" />}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  articles: state.articles.searchResult?.data,
  searching: state.articles.searching,
  totalArticles: state.articles.searchResult?.searchTotal ?? 0,
});

const mapDispatchToProps = {
  getArticles: articlesActions.getArticles,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Articles);
