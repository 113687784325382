/**
 * Given an array of strings, returns a new array containing unique string values.
 * @param value - An array of strings
 * @returns An array containing only values that have no duplicates.
 */
export const getArrayOfUniqueStrings = (value: string[]) =>
  value.reduce((previousValue, value) => {
    if (previousValue.find((x) => value === x)?.trim()) {
      return previousValue;
    }
    return [...previousValue, value];
  }, [] as string[]);
