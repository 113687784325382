/* eslint-disable no-unused-vars  */
export enum ACCOUNT_TYPES {
  PERSONAL,
  BUSINESS,
}

export type AuthenticatedUser = {
  refreshToken: string | null;
  token: string | null;
};

export type BusinessAddress = {
  cityOrLocality?: string;
  country?: string;
  line1?: string;
  line2?: string;
  stateOrProvinceOrRegion?: string;
  zipCode?: number;
};

export type EmailVerification = {
  isEmailVerified?: boolean;
  resendVerificationUrl?: string;
  tokenExpiry?: Date;
  verificationToken?: string;
  verificationUrl?: string;
};

export type User = {
  id: string | null;
  accountType: ACCOUNT_TYPES | null;
  businessAddress: BusinessAddress | null;
  email: string | null;
  emailVerification?: EmailVerification | null;
  lastName: string | null;
  name: string | null;
  password: string | null;
  username: string | null;
};

/* REDUX types */
export type GetUserPayload = { token: string; userId: string };

export type UpdateUserPayload = {
  data: { previous: User; updated: User };
  token: string;
};
