export type GridType = {
  component: string;
  id: number;
  pathname: string;
  redirectUrl?: string;
  requires?: ('auth' | 'elements')[];
  styles?: string[];
  title?: string;
};

export const gridConfig: GridType[] = [
  {
    component: 'CardDetails',
    id: 1,
    pathname: '/card/:uuid/:side?',
    redirectUrl: '/search',
    title: 'Card Details',
  },
  {
    component: 'Article',
    id: 2,
    pathname: '/article/:uuid',
    redirectUrl: '/articles',
  },
];
