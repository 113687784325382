import React, { ChangeEvent, memo, useCallback } from 'react';
import { isEqual } from 'lodash';

import ImageLoader from '../ImageLoader';
import './styles.scss';

type Props = {
  defaultChecked?: boolean;
  icon?: string;
  iconStyles?: {
    container?: string;
    image?: string;
  };
  label?: string;
  styles?: string[];
  value: string;
  // eslint-disable-next-line no-unused-vars
  onCheck: (value: boolean) => void;
};

const Checkbox: React.FC<Props> = ({
  defaultChecked = false,
  icon,
  iconStyles,
  label,
  styles = [],
  value,
  onCheck,
}) => {
  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const isChecked = (event.target as HTMLInputElement)?.checked ?? false;
      onCheck(isChecked);
    },
    [onCheck]
  );

  return (
    <div
      className={`checkbox${styles.length > 0 ? ` ${styles.join(' ')}` : ''}`}
    >
      <input
        type="checkbox"
        defaultChecked={defaultChecked}
        onChange={onChange}
        value={value}
      />
      {!!label && <label>{label}</label>}
      {!!icon && (
        <ImageLoader
          inline
          src={icon}
          styles={{
            container: `icon${iconStyles?.container ? ` ${iconStyles.container}` : ''}`,
            image: iconStyles?.image || '',
          }}
        />
      )}
    </div>
  );
};

export default memo(
  Checkbox,
  (prevProps, nextProps) =>
    prevProps.value === nextProps.value &&
    isEqual(prevProps.onCheck, nextProps.onCheck)
);
