/* eslint-disable no-unused-vars */
export enum ERROR_MESSAGES {
  ARTICLE_RETRIEVAL_FAILED = 'Failed to retrieve article.',
  CARD_RETRIEVAL_FAILED = 'Failed to retrieve card.',
  EMPTY_SEARCH_RESULTS = 'No results found for your search.',
  EMPTY_SIMILARITY_SUGGESTIONS = 'Failed to generate results for your card similarity suggestions request.',
  EMPTY_SYNERGY_SUGGESTIONS = 'Failed to generate results for your card synergy suggestions request.',
  INVALID_BUSINESS_ADDRESS = 'Some details of your business address are missing. Please review and provide all required information before trying again.',
  INVALID_EMAIL_ADDRESS = 'The email address you provided is invalid.',
  INVALID_LOGIN_CREDENTIALS = 'The credentials you provided are not valid. Please ensure your username or email, and password are correct.',
  INVALID_PASSWORD = 'The password you provided is invalid.',
  INVALID_REQUEST = 'Invalid request.',
  INVALID_USER_REGISTRATION_CREDENTIALS = 'One or more of the credentials you entered is not in the correct format. Please ensure your username, password, and email follow the required format and try again.',
  INVALID_USERNAME = 'The username you provided is invalid.',
  UNKNOWN_ERROR = 'Encountered an unknown error',
}

export enum RESPONSE_CODES {
  FAILED = 0,
  SUCCESS = 200,
}
