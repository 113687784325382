import { createSlice } from '@reduxjs/toolkit';

export type SidebarState = {
  leftSidebar: boolean;
  rightSidebar: boolean;
};

const initialState: SidebarState = {
  leftSidebar: false,
  rightSidebar: false,
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    reset: (state) => {
      state.leftSidebar = false;
      state.rightSidebar = false;
    },
    toggleLeftSidebar: (state) => {
      state.leftSidebar = !state.leftSidebar;
      state.rightSidebar = false;
    },
    toggleRightSidebar: (state) => {
      state.leftSidebar = false;
      state.rightSidebar = !state.rightSidebar;
    },
  },
});

export const sidebarActions = {
  ...sidebarSlice.actions,
};

export default sidebarSlice.reducer;
