import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { CardsGallery } from 'components/core';
import { useGalleryClass } from 'hooks';
import { eventsActions, RootState } from 'store';

type Props = ConnectedProps<typeof connector>;

const MostSearchedCards: React.FC<Props> = ({
  fetching,
  lastMostSearchedCardsFetch,
  mostSearchedCards,
  searchFiltersShown,
  getMostSearchedCards,
}) => {
  useGalleryClass(mostSearchedCards.length > 0 && !searchFiltersShown);

  useEffect(() => {
    getMostSearchedCards(lastMostSearchedCardsFetch);
  }, [lastMostSearchedCardsFetch, getMostSearchedCards]);

  return (
    <CardsGallery
      cards={mostSearchedCards}
      fetching={fetching}
      label="Top Searched Cards"
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  fetching: state.events.fetchingMostSearchedCards,
  lastMostSearchedCardsFetch: state.events.lastMostSearchedCardsFetch,
  mostSearchedCards: state.events.mostSearchedCards?.data ?? [],
  searchFiltersShown: state.cards.searchFiltersShown,
});

const mapDispatchToProps = {
  getMostSearchedCards: eventsActions.getMostSearchedCards,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(MostSearchedCards);
