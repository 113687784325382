import React, { memo, MutableRefObject, useEffect, useRef } from 'react';
import { Loader } from 'components/core';
import { usePrevious } from 'hooks';

import './styles.scss';

const memoizedImageNames: { [key: string]: string } = {};
const loadedSrcs: string[] = [];
let isObserverSupported = false;
let shouldLazyLoad = true;
if (typeof window !== 'undefined') {
  shouldLazyLoad = window.location.href.indexOf('lazyloader=false') < 0;
  isObserverSupported = Boolean('IntersectionObserver' in window);
}

type Props = {
  alt?: string;
  fallbackSrc?: string;
  fetching?: boolean;
  inline?: boolean;
  lazy?: boolean;
  src: string | undefined;
  styleOverride?: React.CSSProperties;
  styles?: {
    container?: string;
    image?: string;
  };
  // eslint-disable-next-line
  onLoad?: (loaded: boolean) => void;
};

const ImageLoader: React.ForwardRefRenderFunction<HTMLImageElement, Props> = (
  {
    alt,
    fallbackSrc,
    fetching = false,
    inline = false,
    lazy = true,
    src,
    styleOverride,
    styles,
    onLoad,
  },
  ref
) => {
  const imageHolder = useRef<HTMLElement | null>(null);
  const observer = useRef<IntersectionObserver | null>(null);
  const previousSrc = usePrevious(src);

  useEffect(
    () => {
      setTimeout(() => {
        onLoad?.(
          (ref as MutableRefObject<HTMLImageElement | null>)?.current?.src !==
            undefined
        );
      }, 200);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getFileName = (path?: string) => {
    if (!path) {
      return '';
    }

    try {
      if (memoizedImageNames.path === undefined) {
        const paths = path.split('/');
        memoizedImageNames[path] = paths[paths.length - 1];
      }
    } catch {
      /* empty */
    }

    return memoizedImageNames[path];
  };

  const imageLoadedSuccessfully = (event: Event) => {
    clearListeners(event);

    const image = event?.target as HTMLImageElement;
    if (!image) {
      return;
    }
    if (loadedSrcs.indexOf(image.src) < 0) {
      loadedSrcs.push(image.src);
    }

    image.parentElement?.classList?.remove('notLoaded');

    if (image.dataset.lazy) {
      image.classList.add('popIn');
    }
  };

  const failedToLoadImage = (event: Event) => {
    const image = event?.target as HTMLImageElement;
    if (!image) {
      return;
    }

    image.parentElement?.classList.add('notLoaded');
    console.log(`SPA >> ImageLoader: failed to ${image.src}`);
    if (fallbackSrc) {
      image.parentElement?.classList?.remove('notLoaded');
      image.src = fallbackSrc;
    }

    clearListeners(event);
  };

  const clearListeners = (event: Event) => {
    event?.target?.removeEventListener('load', imageLoadedSuccessfully);
    event?.target?.removeEventListener('error', failedToLoadImage);
  };

  const loadImage = (image: HTMLImageElement | null) => {
    if (!image) {
      return;
    }

    // Remove any existing image event listeners
    image.removeEventListener('load', imageLoadedSuccessfully);
    image.removeEventListener('error', failedToLoadImage);
    // Set image event listeners
    image.addEventListener('load', imageLoadedSuccessfully);
    image.addEventListener('error', failedToLoadImage);

    setTimeout(() => {
      image.src = image.dataset.src ?? '';
    }, 50);
  };

  useEffect(
    () => {
      if (isObserverSupported && !observer.current) {
        observer.current = new IntersectionObserver((entries) => {
          const len = entries.length;

          for (let i = 0; i < len; i++) {
            if (
              entries[i].intersectionRatio >= 0.1 ||
              entries[i].isIntersecting
            ) {
              observer.current?.unobserve(entries[i].target);
              loadImage(entries[i].target.childNodes[0] as HTMLImageElement);
            }
          }
        });

        if (
          isObserverSupported &&
          loadedSrcs.indexOf(
            (ref as MutableRefObject<HTMLImageElement | null>)?.current?.dataset
              ?.src ?? ''
          ) < 0 &&
          lazy &&
          shouldLazyLoad &&
          imageHolder.current
        ) {
          observer.current.observe(imageHolder.current);
        }
      }
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    []
  );

  useEffect(
    () => {
      if (isObserverSupported && src !== previousSrc) {
        loadImage((ref as MutableRefObject<HTMLImageElement | null>)?.current);
      }
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [src, previousSrc]
  );

  const renderImage = (children: React.ReactNode) =>
    !inline ? (
      <div
        className={`imageLoader imageLoaded${
          loadImmediately ? '' : ' notLoaded'
        }${styles?.container ? ` ${styles.container}` : ''}`}
        ref={imageHolder as MutableRefObject<HTMLDivElement>}
        style={styleOverride}
      >
        {children}
      </div>
    ) : (
      <span
        className={`imageLoader imageLoaded${
          loadImmediately ? '' : ' notLoaded'
        }${styles?.container ? ` ${styles.container}` : ''}`}
        ref={imageHolder}
        style={styleOverride}
      >
        {children}
      </span>
    );

  const loadImmediately =
    !isObserverSupported ||
    !lazy ||
    !shouldLazyLoad ||
    loadedSrcs.indexOf(src ?? '') > -1;

  if (fetching) {
    return <Loader type="component" />;
  }

  return renderImage(
    <img
      alt={alt || getFileName(src)}
      className={`clickable${styles?.image ? ` ${styles.image}` : ''}`}
      src={loadImmediately ? src : undefined}
      data-src={src}
      data-lazy={lazy && shouldLazyLoad && !loadImmediately}
      ref={ref}
    />
  );
};

export default memo(React.forwardRef(ImageLoader));
