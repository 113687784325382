import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { withPayloadType } from 'helpers';
import { Errors, Article, SearchRequest, SearchResult } from 'types';

export type Articles = Article[];

export type ArticlesState = {
  searching: boolean;
  searchRequest: SearchRequest | null;
  searchResult: SearchResult<Articles> | null;
  selectedArticle: Article | null;
  errors?: Errors;
};

const initialState: ArticlesState = {
  searching: false,
  searchRequest: null,
  searchResult: null,
  selectedArticle: null,
  errors: undefined,
};

const articlesSlices = createSlice({
  name: 'articles',
  initialState,
  reducers: {
    failure: (state, action: PayloadAction<Errors>) => {
      state.errors = action.payload;
      state.searching = false;
    },
    request: (state) => {
      state.errors = undefined;
      state.searching = true;
    },
    reset: (state) => {
      state.errors = undefined;
      state.searching = false;
    },
    selectArticle: (state, action: PayloadAction<Article | null>) => {
      state.selectedArticle = action.payload;
    },
    setArticles: (state, action: PayloadAction<SearchResult<Articles>>) => {
      state.errors = undefined;
      state.searching = false;
      state.searchResult = action.payload;
    },
    setSearchRequest: (state, action: PayloadAction<SearchRequest | null>) => {
      state.searchRequest = action.payload;
    },
  },
});

export const articlesActions = {
  ...articlesSlices.actions,
  getArticles: createAction(
    'articles/getArticles',
    withPayloadType<SearchRequest>()
  ),
};

export default articlesSlices.reducer;
