import React, {
  FocusEventHandler,
  FormEvent,
  ForwardedRef,
  KeyboardEvent,
  KeyboardEventHandler,
  memo,
  MutableRefObject,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { isEqual } from 'lodash';

import ErrorMessages from '../ErrorMessages';
import './styles.scss';

type Props = {
  disabled?: boolean;
  disableKeypress?: boolean;
  errors?: string | string[];
  hideErrorMessages?: boolean;
  id?: string;
  label: string;
  maxLength?: number;
  name: string;
  placeholder?: string;
  readOnly?: boolean;
  required: boolean;
  resizable?: boolean;
  type: 'input' | 'number' | 'password' | 'search' | 'textarea';
  value: string;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onPressEnter?: KeyboardEventHandler<HTMLInputElement>;
  // eslint-disable-next-line no-unused-vars
  setValue: (value: string) => void;
};

const InputText: React.ForwardRefRenderFunction<
  HTMLInputElement | HTMLTextAreaElement,
  React.PropsWithChildren<Props>
> = (
  {
    children,
    disabled,
    disableKeypress,
    errors,
    hideErrorMessages,
    id,
    label,
    maxLength,
    name,
    placeholder,
    readOnly,
    required,
    resizable = true,
    type,
    value,
    onBlur,
    onFocus,
    onKeyDown,
    onPressEnter,
    setValue,
  },
  ref
) => {
  const [isFocused, setIsFocused] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  useEffect(() => {
    if (errors?.length && ref && !isFocused) {
      (
        ref as MutableRefObject<HTMLInputElement | HTMLTextAreaElement>
      )?.current.focus();
      setIsFocused(true);
    }
  }, [errors?.length, isFocused, ref]);

  const onKeyPress = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (disableKeypress) {
        event.preventDefault();
      }
      if (event.key === 'Enter') {
        onPressEnter?.(event);
      } else {
        onKeyDown?.(event);
      }
    },
    [disableKeypress, onKeyDown, onPressEnter]
  );

  const onResetIsFocused = useCallback(() => {
    if (isFocused) {
      setIsFocused(false);
    }
  }, [isFocused]);

  const onSetIsFocused = useCallback(() => {
    if (errors?.length && !isFocused) {
      setIsFocused(true);
    }
  }, [errors?.length, isFocused]);

  const onTogglePasswordVisibility = useCallback(() => {
    setPasswordVisible(!passwordVisible);
  }, [passwordVisible]);

  const textAreaAdjust = useCallback(
    (event: FormEvent<HTMLTextAreaElement>) => {
      const element = event.target as HTMLTextAreaElement;
      if (!element) {
        return;
      }
      element.style.height = '1px';
      element.style.height = 25 + element.scrollHeight + 'px';
    },
    []
  );

  const onInputChange = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      const input = (event.target as HTMLInputElement)?.value || '';
      if (!maxLength || (!!maxLength && input.length <= maxLength)) {
        setValue(input);
        onResetIsFocused();
      }
    },
    [maxLength, onResetIsFocused, setValue]
  );

  const onTextAreaChange = useCallback(
    (event: FormEvent<HTMLTextAreaElement>) => {
      const input = (event.target as HTMLTextAreaElement)?.value || '';
      if (!maxLength || (!!maxLength && input.length <= maxLength)) {
        setValue(input);
        textAreaAdjust(event);
        onResetIsFocused();
      }
    },
    [maxLength, onResetIsFocused, textAreaAdjust, setValue]
  );

  return (
    <div
      className={`inputText${
        !!errors || (Array.isArray(errors) && errors.length)
          ? ' withErrors'
          : ''
      }`}
    >
      {label ? (
        <label htmlFor={id ?? name}>
          {label} {required ? <span className="required">*</span> : ''}{' '}
        </label>
      ) : null}

      {type === 'textarea' ? (
        <textarea
          autoCapitalize="off"
          className={`${resizable ? 'resizable ' : ''}${
            isFocused ? 'withFocus' : ''
          }`}
          disabled={disabled}
          id={id ?? name}
          maxLength={maxLength}
          onBlur={onBlur}
          onFocus={onFocus || onSetIsFocused}
          onInput={onTextAreaChange}
          readOnly={readOnly}
          ref={ref as ForwardedRef<HTMLTextAreaElement>}
          value={value}
        />
      ) : (
        <>
          {type === 'search' && <span className="searchIcon icon-search" />}
          <input
            autoCapitalize="off"
            className={`${type === 'search' ? 'search ' : ''}${isFocused ? 'withFocus' : ''}`}
            disabled={disabled}
            id={id ?? name}
            maxLength={maxLength}
            onInput={onInputChange}
            onKeyDown={onKeyPress}
            onBlur={onBlur}
            onFocus={onFocus || onSetIsFocused}
            placeholder={placeholder}
            readOnly={readOnly}
            ref={ref as ForwardedRef<HTMLInputElement>}
            type={
              type === 'password'
                ? passwordVisible
                  ? 'input'
                  : 'password'
                : type
            }
            value={value}
          />
          {type === 'search' && children}
          {type === 'password' && (
            <button className="toggle" onClick={onTogglePasswordVisibility}>
              <span
                className={`${passwordVisible ? 'icon-eye' : 'icon-eye-blocked'}`}
              />
            </button>
          )}
        </>
      )}
      {!hideErrorMessages ? <ErrorMessages errors={errors} /> : null}
    </div>
  );
};

export default memo(
  React.forwardRef(InputText),
  (prevProps, nextProps) =>
    prevProps.value === nextProps.value &&
    prevProps.errors?.length === nextProps.errors?.length &&
    isEqual(prevProps.onBlur, nextProps.onBlur) &&
    isEqual(prevProps.onFocus, nextProps.onFocus) &&
    isEqual(prevProps.onKeyDown, nextProps.onKeyDown) &&
    isEqual(prevProps.onPressEnter, nextProps.onPressEnter) &&
    isEqual(prevProps.setValue, nextProps.setValue) &&
    isEqual(prevProps.children, nextProps.children)
);
