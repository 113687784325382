import { PayloadAction } from '@reduxjs/toolkit';
import { ERROR_MESSAGES, RESPONSE_CODES } from 'appConstants';
import { call, put } from 'redux-saga/effects';
import {
  getCardsByFilters,
  getSimilaritySuggestions,
  getSingle,
  getSynergySuggestions,
} from 'services';
import { cardsActions, Cards, CardSynergies } from 'store';
import {
  Card,
  Response,
  SearchRequest as CardSearchRequest,
  SearchResult,
  SynergyRequest,
} from 'types';

/**
 * Saga generator function called to get a single card.
 * @param {PayloadAction<string>} data - a PayloadAction object containing the card ID.
 * @returns {Response} Response object.
 */
export function* getCard(data: PayloadAction<string>) {
  const { payload } = data;

  try {
    yield put(cardsActions.request());

    const response: Response<Card | null> = yield call(getSingle, {
      payload,
    });
    const { code, data } = response;
    if (code === RESPONSE_CODES.SUCCESS && !!data && typeof data === 'object') {
      yield put(cardsActions.selectCard(data as Card));
      return response;
    }

    const { message } = response;
    if (!data) {
      if (message !== 'Success') {
        yield put(cardsActions.failure(message));
      } else {
        yield put(cardsActions.failure(ERROR_MESSAGES.CARD_RETRIEVAL_FAILED));
      }
    }
    return response;
  } catch (error) {
    yield put(cardsActions.failure(ERROR_MESSAGES.UNKNOWN_ERROR));
    console.log('[DEBUG:SAGAS] getCard', error);
    return null;
  }
}

/**
 * Saga generator function called to get cards based on filters.
 * @param {PayloadAction<CardSearchRequest>} data - a PayloadAction object containing the
 * query page number and other advanced filters.
 * @returns {Response} Response object.
 */
export function* getCards(data: PayloadAction<CardSearchRequest>) {
  const { payload } = data;

  try {
    const { usePreviousRequest = false } = payload;
    yield put(cardsActions.setSearching());
    if (usePreviousRequest) {
      payload.usePreviousRequest = false;
    }
    yield put(cardsActions.setSearchRequest(payload));
    yield put(cardsActions.setRedirectUrl(undefined));

    const response: Response<Cards> = yield call(getCardsByFilters, {
      payload,
    });
    const { code, data } = response;
    if (code === RESPONSE_CODES.SUCCESS && !!data && typeof data === 'object') {
      const cards = data as SearchResult<Cards>;
      yield put(cardsActions.setCards(cards));
      if (usePreviousRequest) {
        yield put(cardsActions.setPreviousSearchRequest(null));
      }
      if (payload.cardPrint) {
        const print = cards.data.find(
          (value) =>
            (value.faceName === payload.cardPrint?.name ||
              value.name === payload.cardPrint?.name) &&
            value.setCode === payload.cardPrint?.setCode
        );
        if (print) {
          yield put(cardsActions.setRedirectUrl(`/card/${print.uuid}`));
        }
      }
      return response;
    }

    const { message } = response;
    if (!data) {
      if (message !== 'Success') {
        yield put(cardsActions.failure(message));
      } else {
        yield put(cardsActions.failure(ERROR_MESSAGES.EMPTY_SEARCH_RESULTS));
      }
    }
    return response;
  } catch (error) {
    yield put(cardsActions.failure(ERROR_MESSAGES.UNKNOWN_ERROR));
    console.log('[DEBUG:SAGAS] getCards', error);
    return null;
  }
}

/**
 * Saga generator function called to get a card's similarity suggestions.
 * @param {PayloadAction<SynergyRequest>} data - a PayloadAction object containing the SynergyRequest object.
 * @returns {Response} Response object.
 */
export function* getCardSimilarities(data: PayloadAction<SynergyRequest>) {
  const { payload } = data;

  try {
    yield put(cardsActions.request());

    const response: Response<SearchResult<CardSynergies>> = yield call(
      getSimilaritySuggestions,
      {
        payload,
      }
    );
    const { code, data } = response;
    if (code === RESPONSE_CODES.SUCCESS && !!data && typeof data === 'object') {
      const similarities = data as SearchResult<CardSynergies>;
      yield put(
        cardsActions.setCardSimilarities({
          ...similarities,
        })
      );
      return response;
    }

    const { message } = response;
    if (!data) {
      if (message !== 'Success') {
        yield put(cardsActions.failure(message));
      } else {
        yield put(
          cardsActions.failure(ERROR_MESSAGES.EMPTY_SIMILARITY_SUGGESTIONS)
        );
      }
    }
    return response;
  } catch (error) {
    yield put(cardsActions.failure(ERROR_MESSAGES.UNKNOWN_ERROR));
    console.log('[DEBUG:SAGAS] getCardSimilarities', error);
    return null;
  }
}

/**
 * Saga generator function called to get a card's synergy suggestions.
 * @param {PayloadAction<SynergyRequest>} data - a PayloadAction object containing the SynergyRequest object.
 * @returns {Response} Response object.
 */
export function* getCardSynergies(data: PayloadAction<SynergyRequest>) {
  const { payload } = data;

  try {
    yield put(cardsActions.request());

    const response: Response<SearchResult<CardSynergies>> = yield call(
      getSynergySuggestions,
      {
        payload,
      }
    );
    const { code, data } = response;
    if (code === RESPONSE_CODES.SUCCESS && !!data && typeof data === 'object') {
      const synergies = data as SearchResult<CardSynergies>;
      yield put(
        cardsActions.setCardSynergies({
          ...synergies,
        })
      );
      return response;
    }

    const { message } = response;
    if (!data) {
      if (message !== 'Success') {
        yield put(cardsActions.failure(message));
      } else {
        yield put(
          cardsActions.failure(ERROR_MESSAGES.EMPTY_SYNERGY_SUGGESTIONS)
        );
      }
    }
    return response;
  } catch (error) {
    yield put(cardsActions.failure(ERROR_MESSAGES.UNKNOWN_ERROR));
    console.log('[DEBUG:SAGAS] getCardSynergies', error);
    return null;
  }
}
