/**
 * Normalizes a string by removing all special characters and capitalizing all words.
 * @param input - a string to normalize.
 * @returns - the normalized string.
 */
export const normalizeText = (input: string) => {
  return input
    .toLowerCase() // Convert all to lowercase
    .replace(/[_\W]+/g, ' ') // Replace underscores or special characters with a space
    .trim() // Remove leading and trailing spaces
    .split(' ') // Split into words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
    .join(' '); // Join back into a single string
};
