import { SEARCH_CONSTANTS } from 'appConstants';
import React, { useCallback } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Pagination } from 'components/core';
import { cardsActions, RootState } from 'store';

type Props = ConnectedProps<typeof connector> & { styles?: string[] };

const CardsPagination: React.FC<Props> = ({
  searchRequest,
  searchResult,
  getCards,
  styles = [],
}) => {
  const { searchTotal = 0 } = searchResult ?? {};
  const { body, page = 0, query } = searchRequest ?? {};

  const totalPages = Math.ceil(searchTotal / SEARCH_CONSTANTS.FETCH_COUNT);

  const getNextPage = useCallback(() => {
    const nextPage = page + 1;
    return nextPage > totalPages ? totalPages : nextPage;
  }, [page, totalPages]);

  const getPreviousPage = useCallback(() => {
    const previousPage = page - 1;
    return previousPage < 0 ? 0 : previousPage;
  }, [page]);

  const onFirstOrLast = useCallback(
    (isLast: boolean = true) => {
      getCards({
        body,
        page: isLast ? totalPages - 1 : 0,
        query,
      });
    } /* eslint-disable react-hooks/exhaustive-deps */,
    [
      JSON.stringify(body),
      JSON.stringify(query),
      totalPages,
      getCards,
      getNextPage,
      getPreviousPage,
    ]
  );

  const onNextOrPrevious = useCallback(
    (isNext: boolean = true) => {
      getCards({
        body,
        page: isNext ? getNextPage() : getPreviousPage(),
        query,
      });
    } /* eslint-disable react-hooks/exhaustive-deps */,
    [
      JSON.stringify(body),
      JSON.stringify(query),
      getCards,
      getNextPage,
      getPreviousPage,
    ]
  );

  const onFirst = useCallback(() => {
    onFirstOrLast(false);
  }, [onFirstOrLast]);

  const onLast = useCallback(() => {
    onFirstOrLast();
  }, [onFirstOrLast]);

  const onNext = useCallback(() => {
    onNextOrPrevious();
  }, [onNextOrPrevious]);

  const onPrevious = useCallback(() => {
    onNextOrPrevious(false);
  }, [onNextOrPrevious]);

  return (
    <Pagination
      current={page + 1}
      nextGroupDisabled={page === totalPages - 1}
      onFirst={onFirst}
      onLast={onLast}
      onNext={onNext}
      onPrevious={onPrevious}
      previousGroupDisabled={page === 0}
      styles={styles}
      total={totalPages}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  fetching: state.cards.fetching,
  searchRequest: state.cards.searchRequest,
  searchResult: state.cards.searchResult,
});

const mapDispatchToProps = {
  getCards: cardsActions.getCards,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(CardsPagination);
