/* eslint-disable no-unused-vars */
export enum CARD_TYPES {
  ARTIFACT = 'Artifact',
  BATTLE = 'Battle',
  CREATURE = 'Creature',
  CONSPIRACY = 'Conspiracy',
  DUNGEON = 'Dungeon',
  EMBLEM = 'Emblem',
  ENCHANTMENT = 'Enchantment',
  HERO = 'Hero',
  INSTANT = 'Instant',
  KINDRED = 'Kindred',
  LAND = 'Land',
  PHENOMENON = 'Phenomenon',
  PLANE = 'Plane',
  PLANESWALKER = 'Planeswalker',
  SCHEME = 'Scheme',
  SORCERY = 'Sorcery',
  VANGUARD = 'Vanguard',
}

export enum FORMAT_LABELS {
  ALCHEMY = 'Alchemy',
  COMMANDER = 'Commander',
  DUEL = 'Duel Commander',
  EXPLORER = 'Explorer',
  FUTURE = 'Future Standard',
  GLADIATOR = 'Gladiator',
  HISTORIC = 'Historic',
  HISTORIC_BRAWL = 'Brawl',
  LEGACY = 'Legacy',
  MODERN = 'Modern',
  OATHBREAKER = 'Oathbreaker',
  OLD_SCHOOL = 'Old School',
  PAUPER = 'Pauper',
  PAUPER_COMMANDER = 'Pauper Commander',
  PENNY = 'Penny',
  PIONEER = 'Pioneer',
  PRE_DH = 'PreDH',
  PRE_MODERN = 'Premodern',
  STANDARD = 'Standard',
  STANDARD_BRAWL = 'Standard Brawl',
  TIMELESS = 'Timeless',
  VINTAGE = 'Vintage',
}

export enum FORMATS {
  ALCHEMY = 'alchemy',
  COMMANDER = 'commander',
  DUEL = 'duel',
  EXPLORER = 'explorer',
  FUTURE = 'future',
  GLADIATOR = 'gladiator',
  HISTORIC = 'historic',
  HISTORIC_BRAWL = 'historicbrawl',
  LEGACY = 'legacy',
  MODERN = 'modern',
  OATHBREAKER = 'oathbreaker',
  OLD_SCHOOL = 'oldschool',
  PAUPER = 'pauper',
  PAUPER_COMMANDER = 'paupercommander',
  PENNY = 'penny',
  PIONEER = 'pioneer',
  PRE_DH = 'predh',
  PRE_MODERN = 'premodern',
  STANDARD = 'standard',
  STANDARD_BRAWL = 'standardbrawl',
  TIMELESS = 'timeless',
  VINTAGE = 'vintage',
}

export enum LEGALITY_STATUS {
  BANNED = 'Banned',
  LEGAL = 'Legal',
  RESTRICTED = 'Restricted',
}

export enum MANA_COLORS {
  BLACK = 'B',
  BLUE = 'U',
  COLORLESS = 'C',
  GREEN = 'G',
  RED = 'R',
  WHITE = 'W',
}

export enum PRINTINGS {
  PAPER = 'paper',
  MAGIC_ONLINE = 'mtgo',
  ARENA = 'arena',
}

export enum RARITIES {
  COMMON = 'common',
  UNCOMMON = 'uncommon',
  RARE = 'rare',
  MYTHIC_RARE = 'mythic',
}

export enum RARITY_LABELS {
  COMMON = 'Common',
  UNCOMMON = 'Uncommon',
  RARE = 'Rare',
  MYTHIC_RARE = 'Mythic Rare',
}

export enum SEARCH_CONSTANTS {
  FETCH_COUNT = 100,
}
