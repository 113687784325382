import React, { memo } from 'react';
import { useMatch } from 'react-router-dom';
import { ComponentModal } from 'components/core';
import * as GridComponents from 'components/grid';
import { gridConfig } from 'config';
import { getQueryStringValue } from 'helpers';

type Props = {
  pathname: string;
  redirectUrl?: string;
};

const GridComponent: React.FC<Props> = ({ pathname: path, redirectUrl }) => {
  const match = useMatch({ path, end: true });
  if (!match) {
    return null;
  }
  const gridItem = gridConfig.find(
    (value) => value.pathname === match.pattern.path
  );
  if (!gridItem) {
    return null;
  }
  const Component: React.FC =
    GridComponents[gridItem.component as keyof typeof GridComponents];
  if (!Component) {
    return null;
  }

  const { component: componentName, styles, title = '' } = gridItem;
  const parsedRedirectUrl = getQueryStringValue('redirect');

  return (
    <ComponentModal
      contentWrapStyles={styles}
      gridConfig={{
        componentName,
        props: match.params,
      }}
      redirectUrl={
        parsedRedirectUrl?.trim() ? `${parsedRedirectUrl}` : redirectUrl
      }
      shown
      title={title}
    />
  );
};

export default memo(GridComponent);
