import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { Deck as PlayDeck, DeckItems } from 'types';

import './styles.scss';

type Props = {
  articleId: string;
  deck: PlayDeck;
};

const Deck: React.FC<Props> = ({ articleId, deck }) => (
  <div className="deck">
    <>
      {Object.keys(deck.main).map((value) => (
        <div key={value} className="cardGroups">
          {!!(deck.main as { [key: string]: DeckItems })[value] && (
            <>
              <h3>{value}</h3>
              <div>
                {(deck.main as { [key: string]: DeckItems })[value]?.map(
                  (deckItem) => (
                    <Link
                      key={deckItem.cardId}
                      to={`/card/${deckItem.cardId}?redirect=${`/article/${articleId}`}`}
                    >
                      <p className="cards">
                        {`${deckItem.quantity}x ${deckItem.cardName}`}
                      </p>
                    </Link>
                  )
                )}
              </div>
            </>
          )}
        </div>
      ))}
      <div className="cardGroups">
        {!!deck.sideboard && (
          <>
            <h3>Sideboard</h3>
            <div>
              {deck.sideboard.map((deckItem) => (
                <Link
                  key={deckItem.cardId}
                  to={`/card/${deckItem.cardId}?redirect=${`/article/${articleId}`}`}
                >
                  <p className="cards">
                    {`${deckItem.quantity}x ${deckItem.cardName}`}
                  </p>
                </Link>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  </div>
);

export default memo(Deck);
