import { isEqual } from 'lodash';
import { connect, ConnectedProps } from 'react-redux';
import React, { useCallback, useEffect } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useMatch,
  useNavigate,
} from 'react-router-dom';
import {
  Articles,
  BackToTop,
  GridComponent,
  Loader,
  MessageModal,
  PromptModal,
  RequireAuth,
  Sidebar,
  Topbar,
  Troubleshoot,
} from 'components/core';
import { CardsSearchResults, Dashboard } from 'components/grid';
import { gridConfig, GridType } from 'config';
import { usePrevious } from 'hooks';
import { RootState, sidebarActions, usersActions } from 'store';
import { usePrefetch } from 'hooks/usePrefetch';

type Props = ConnectedProps<typeof connector>;

const AppBase: React.FC<Props> = ({
  loader,
  messageModalState,
  promptModalState,
  user,
  toggleRightSidebar,
}) => {
  // Get all data necessary to load app
  usePrefetch();

  const location = useLocation();
  const navigate = useNavigate();

  const loginMatch = useMatch({ path: '/login', end: true });

  const previousRoute = usePrevious(location.pathname);
  const previousUser = usePrevious(user);

  useEffect(() => {
    // Successfully logged in; redirect to dashboard
    if (!isEqual(user, previousUser) && !!user && !previousUser) {
      const isHome = window.location.hash === '#/';
      const route = isHome
        ? '/'
        : (window.location.hash.split('#/').pop() ?? '/');

      setTimeout(() => {
        navigate(route, { replace: true });
      }, 1500);

      return;
    }

    // authUser has been set to null or undefined -> logout
    if (!isEqual(user, previousUser) && !!previousUser && !user) {
      navigate('/', { replace: true });
    }
  }, [previousUser, user, navigate]);

  const goBack = useCallback(() => {
    if (window.history.length > 0) {
      if (/\/search/.test(previousRoute)) {
        navigate('/');
        return;
      }
      if (/\/articles/.test(previousRoute)) {
        navigate('/');
        return;
      }
      navigate(-1);
    }
  }, [previousRoute, navigate]);

  const toggleSidebar = useCallback(
    () => toggleRightSidebar(),
    [toggleRightSidebar]
  );

  const renderGrid = (grid: GridType) => {
    const { id, requires, ...others } = grid;
    if (!requires) {
      return (
        <Route
          key={id}
          path={others.pathname}
          element={<GridComponent {...others} />}
        />
      );
    }
    if (requires.length === 1) {
      if (requires.includes('auth')) {
        return (
          <Route
            key={id}
            path={others.pathname}
            element={
              <RequireAuth>
                <GridComponent {...others} />
              </RequireAuth>
            }
          />
        );
      }
    }
    return null;
  };

  return (
    <>
      {!loginMatch && (
        <>
          <Topbar
            toggleLeftSidebar={goBack}
            toggleRightSidebar={toggleSidebar}
          />
          <Sidebar type="left" />
          <Sidebar type="right" />
        </>
      )}

      <Routes>
        <Route path="/" element={<Dashboard />} />
        {/* <Route path="/login" element={<Login />} /> */}
        <Route path="/search" element={<CardsSearchResults />} />
        <Route path="/articles" element={<Articles />} />

        {gridConfig.map((grid) => renderGrid(grid))}

        {/* Catch-all route */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>

      <MessageModal {...messageModalState} />
      <PromptModal {...promptModalState} />

      <BackToTop selector="#app" />

      <Troubleshoot />

      {loader.shown && !!loader.message?.trim() && (
        <Loader message={loader.message} />
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  loader: state.loader,
  messageModalState: state.modal.messageModal,
  promptModalState: state.modal.promptModal,
  token: state.users.token,
  user: state.users.user,
});

const mapDispatchToProps = {
  getUser: usersActions.getUser,
  logout: usersActions.logout,
  setUser: usersActions.setUser,
  toggleRightSidebar: sidebarActions.toggleRightSidebar,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(AppBase);
