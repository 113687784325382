import { ERROR_MESSAGES } from 'appConstants';
import { config } from 'config';
import { getDeviceFingerprint, getFailure } from 'helpers';
import { Articles, Cards } from 'store';
import { EventRequest, RequestPayload, Response, SearchResult } from 'types';

import { callApi } from './base';

/**
 * Sends a GET request to the get most viewed articles API endpoint.
 * @returns {Promise<Response>}
 */
export const getViewedArticles = async (): Promise<
  Response<SearchResult<Articles>>
> => {
  try {
    const response = await callApi({
      url: `${config.apiUrl}api/events/articles/view`,
    });
    const { data } = (response ?? {}) as { [key: string]: any };
    return data as Response<SearchResult<Articles>>;
  } catch (error) {
    console.log('[DEBUG] getViewedArticles with error', error);
    return getFailure(ERROR_MESSAGES.UNKNOWN_ERROR);
  }
};

/**
 * Sends a GET request to the get most searched cards API endpoint.
 * @returns {Promise<Response>}
 */
export const getSearchedCards = async (): Promise<
  Response<SearchResult<Cards>>
> => {
  try {
    const response = await callApi({
      url: `${config.apiUrl}api/events/cards/search`,
    });
    const { data } = (response ?? {}) as { [key: string]: any };
    return data as Response<SearchResult<Cards>>;
  } catch (error) {
    console.log('[DEBUG] getSearchedCards with error', error);
    return getFailure(ERROR_MESSAGES.UNKNOWN_ERROR);
  }
};

/**
 * Sends a GET request to the get most viewed cards API endpoint.
 * @returns {Promise<Response>}
 */
export const getViewedCards = async (): Promise<
  Response<SearchResult<Cards>>
> => {
  try {
    const response = await callApi({
      url: `${config.apiUrl}api/events/cards/view`,
    });
    const { data } = (response ?? {}) as { [key: string]: any };
    return data as Response<SearchResult<Cards>>;
  } catch (error) {
    console.log('[DEBUG] getViewedCards with error', error);
    return getFailure(ERROR_MESSAGES.UNKNOWN_ERROR);
  }
};

/**
 * Sends a GET request to the track event API endpoint.
 * @param {RequestPayload<EventRequest>} data - a RequestPayload object containing the event request object.
 * @returns {Promise<Response>}
 */
export const trackSingleEvent = async (
  data: RequestPayload<EventRequest>
): Promise<Response<boolean>> => {
  const { payload } = data;
  try {
    if (!payload) {
      return getFailure(ERROR_MESSAGES.INVALID_REQUEST);
    }
    if (!payload.deviceId) {
      payload.deviceId = await getDeviceFingerprint();
    }
    const response = await callApi({
      url: `${config.apiUrl}api/events`,
      method: 'POST',
      data: payload,
    });
    const { data } = (response ?? {}) as { [key: string]: any };
    return data as Response<boolean>;
  } catch (error) {
    console.log('[DEBUG] trackSingleEvent with error', error);
    return getFailure(ERROR_MESSAGES.UNKNOWN_ERROR);
  }
};
