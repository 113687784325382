import React, { FormEvent, useCallback, useRef, useState } from 'react';
import { Button, Loader, SeachInput } from 'components/core';

import './styles.scss';

type SearchFilterProps = { toggleVisibility: () => void };

type Props = {
  placeholder?: string;
  SearchFilterPopup?: React.FC<SearchFilterProps>;
  searchFiltersShown?: boolean;
  searching: boolean;
  showActivity?: boolean;
  callback?: () => void;
  // eslint-disable-next-line no-unused-vars
  search: (keywords: string) => void;
  toggleSearchFilters?: () => void;
};

const CardsSearchBar: React.ForwardRefRenderFunction<HTMLDivElement, Props> = (
  {
    placeholder = 'Enter keywords',
    SearchFilterPopup,
    searchFiltersShown = false,
    showActivity = true,
    searching,
    callback,
    search,
    toggleSearchFilters,
  },
  searchBarRef
) => {
  const ref = useRef<HTMLInputElement | null>(null);

  const [searchInput, setSearchInput] = useState('');

  const executeSearch = useCallback(() => {
    if (searchFiltersShown) {
      toggleSearchFilters?.();
    }
    search(searchInput);
    callback?.();
  }, [searchFiltersShown, searchInput, callback, search, toggleSearchFilters]);

  const onEnter = useCallback(() => {
    executeSearch();
  }, [executeSearch]);

  const onSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      executeSearch();
    },
    [executeSearch]
  );

  return (
    <>
      <div className="searchBar" ref={searchBarRef}>
        {!searchFiltersShown && (
          <form onSubmit={onSubmit}>
            <SeachInput
              onPressEnter={onEnter}
              placeholder={placeholder}
              ref={ref}
              setValue={setSearchInput}
              value={searchInput}
            >
              {!!toggleSearchFilters && (
                <Button
                  label="Advanced"
                  onClick={toggleSearchFilters}
                  styles={['advancedButton']}
                />
              )}
            </SeachInput>
          </form>
        )}
        {!!SearchFilterPopup &&
          !!toggleSearchFilters &&
          searchFiltersShown &&
          !searching && (
            <SearchFilterPopup toggleVisibility={toggleSearchFilters} />
          )}
      </div>
      {showActivity && searching && <Loader type="screen" />}
    </>
  );
};

export default React.forwardRef(CardsSearchBar);
