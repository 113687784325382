import React, { memo, useCallback, useEffect, useRef, useState } from 'react';

import './styles.scss';

type Props = {
  scrollEnd?: boolean;
  selector: string;
  threshold?: number;
};

const BackToTop: React.FC<Props> = ({
  scrollEnd = false,
  selector,
  threshold = 200,
}) => {
  const scrollListener = useRef<(() => void) | null>(null);

  const [showButton, setShowButton] = useState(false);

  const setButtonDisplay = useCallback(
    (element: Element | (Window & typeof globalThis)) => {
      const scrollPosition =
        element instanceof Element ? element.scrollTop : element.scrollY;
      const hasReachedThreshold = scrollPosition > threshold;
      if (hasReachedThreshold !== showButton) {
        setShowButton(hasReachedThreshold);
      } else if (!hasReachedThreshold) {
        setShowButton(false);
      }
    },
    [showButton, threshold]
  );

  useEffect(() => {
    if (scrollListener.current) {
      return;
    }

    try {
      // BEGIN LISTENING ON SCROLL EVENT
      // set selector as scrollable div or use window

      scrollListener.current = () => {
        const element = selector
          ? (document.querySelector(`${selector}`) ?? window)
          : window;
        let scrollTimeout: NodeJS.Timeout | undefined;
        if (scrollEnd) {
          window.clearTimeout(scrollTimeout);
          scrollTimeout = setTimeout(() => {
            window.clearTimeout(scrollTimeout);
            setButtonDisplay(element);
          }, 50);
        } else {
          setButtonDisplay(element);
        }
      };

      const docElement = selector
        ? (document.querySelector(`${selector}`) ?? window)
        : window;
      docElement.addEventListener('scroll', scrollListener.current);
    } catch (err) {
      console.log('BackToTop Component Error', err);
    }
  }, [scrollEnd, selector, setButtonDisplay]);

  const scrollToTop = useCallback(() => {
    try {
      // set selector as scrollable div or use window
      const element = document.querySelector(`${selector}`) ?? window;
      element?.scrollTo({ top: 0, behavior: 'smooth' });
    } catch (err) {
      console.log('BackToTop Component >> scrollToTop() > Error', err);
    }
  }, [selector]);

  return (
    <div className={`backToTop${showButton ? ' active' : ''}`}>
      <button onClick={scrollToTop}>
        <span className="icon-arrow-up" />
      </button>
    </div>
  );
};

export default memo(BackToTop);
