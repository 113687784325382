import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AmazonAffiliateProduct, Errors } from 'types';

export type AmazonAffiliateProducts = AmazonAffiliateProduct[];

export type AmazonAffiliateProductsState = {
  fetching: boolean;
  products: AmazonAffiliateProducts;
  errors?: Errors;
};

const initialState: AmazonAffiliateProductsState = {
  fetching: false,
  products: [],
  errors: undefined,
};

const amazonAffiliateProductsSlices = createSlice({
  name: 'amazonAffiliateProducts',
  initialState,
  reducers: {
    failure: (state, action: PayloadAction<Errors>) => {
      state.errors = action.payload;
      state.fetching = false;
    },
    request: (state) => {
      state.errors = undefined;
      state.fetching = true;
    },
    reset: (state) => {
      state.errors = undefined;
      state.fetching = false;
    },
    setProducts: (state, action: PayloadAction<AmazonAffiliateProducts>) => {
      state.errors = undefined;
      state.fetching = false;
      state.products = action.payload;
    },
  },
});

export const amazonAffiliateProductsActions = {
  ...amazonAffiliateProductsSlices.actions,
  getAmazonAffiliateProducts: createAction('amazonAffiliateProducts'),
};

export default amazonAffiliateProductsSlices.reducer;
