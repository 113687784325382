import React, { useCallback, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Params, useMatch } from 'react-router-dom';
import { EVENT_ACTION, EVENT_TYPE } from 'appConstants';
import { ArticleItem, Loader } from 'components/core';
import { getSingleArticle, trackSingleEvent } from 'services';
import { articlesActions, RootState } from 'store';
import { Article as SelectedArticle } from 'types';

type Props = ConnectedProps<typeof connector>;

const Article: React.FC<Props> = ({
  article,
  articles = [],
  selectArticle,
}) => {
  const routeMatch = useMatch({ path: 'article/:articleId', end: true });
  const { articleId } = routeMatch?.params ?? ({} as Params<'articleId'>);

  const [fetching, setFetching] = useState(false);

  const getArticle = useCallback(
    async (id: string) =>
      selectArticle(
        (await getSingleArticle({ payload: id })).data as SelectedArticle
      ),
    [selectArticle]
  );

  useEffect(() => {
    if (!articleId) {
      return;
    }
    if (article?.id === articleId) {
      return;
    }
    const match = articles.find((value) => value.id === article?.id);
    if (match) {
      selectArticle(match);
      trackSingleEvent({
        payload: {
          action: EVENT_ACTION.VIEW,
          data: articleId,
          type: EVENT_TYPE.CARD,
        },
      });
      return;
    }
    setFetching(true);
    (async () => await getArticle(articleId))();
    setFetching(false);
  }, [article, articleId, articles, getArticle, selectArticle]);

  useEffect(
    () => {
      return () => {
        selectArticle(null);
      };
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (!article || fetching) {
    return <Loader type="screen" />;
  }

  return <ArticleItem article={article} />;
};

const mapStateToProps = (state: RootState) => ({
  article: state.articles.selectedArticle,
  articles: state.articles.searchResult?.data,
});

const mapDispatchToProps = {
  selectArticle: articlesActions.selectArticle,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Article);
