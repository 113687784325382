import { BusinessAddress } from 'types';

/**
 * Validate's a business user's business address.
 * @param businessAddress
 * @returns a boolean indicating a valid
 */
export const isValidBusinessAddress = (
  businessAddress: BusinessAddress | null | undefined
) => {
  if (businessAddress == null) {
    return false;
  }

  if (!businessAddress.line1?.trim() || !businessAddress?.country?.trim()) {
    return false;
  }

  return true;
};

/**
 * Validates a user's email address.
 * @param email
 * @returns a boolean indicating a valid or invalid email address pattern.
 */
export const isValidEmail = (email: string | null | undefined) => {
  // Null or empty check
  if (!email?.trim()) {
    return false;
  }

  if (!/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(email)) {
    return false;
  }

  // Sanitization: Check for malicious content like scripts
  if (/<script>/.test(email) || /javascript:/i.test(email)) {
    return false;
  }

  return true;
};

/**
 * Validates a user's password according to the following criteria:
 * 1. Must be 8-12 characters in length
 * 2. Must contain at least 1 capital letter
 * 3. Must contain at least 1 number
 * 4. Must have at least 1 special character from the following special characters: ["@", "%", "+", "\", "/", "'", "!", "#", "$", "^", ":", ".", "(", ")", "{", "}", "[", "]", "~", "-", "_"]
 * @param password
 * @returnsa boolean indicating whether or not the password matches the criteria.
 */
export const isValidPassword = (password: string | null | undefined) => {
  // Null or empty check
  if (!password?.trim()) {
    return false;
  }

  // eslint-disable-next-line no-useless-escape
  return /^(?=.*[A-Z])(?=.*\d)(?=.*[@%+\\/'!#$^:.\(\){}\[\]~\-_])[A-Za-z\d@%+\\/'!#$^:.\(\){}\[\]~\-_]{8,12}$/.test(
    password
  );
};

/**
 * Validates a user's username according to the following criteria:
 * 1. `^` and `$`**: Anchors to ensure the pattern matches the entire username from start to end.
 * 2. `(?!.*\.\.)`**: Ensures no consecutive periods (`..`).
 * 3. `(?!.*\.$)`**: Ensures the username does not end with a period.
 * 4. `[a-zA-Z0-9.]`**: Allows only letters (uppercase and lowercase), numbers, and periods.
 * 5. `{5,20}`**: Enforces a minimum of 5 characters and a maximum of 20 characters.
 * @param username
 * @returns a boolean indicating whether or not the username matches the criteria.
 */
export const isValidUsername = (
  username: string | null | undefined
): boolean => {
  // Null or empty check
  if (!username?.trim()) {
    return false;
  }
  return /^(?!.*\.\.)(?!.*\.$)[a-zA-Z0-9.]{5,20}$/.test(username);
};
