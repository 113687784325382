import { ERROR_MESSAGES } from 'appConstants';
import { config } from 'config';
import { getDeviceFingerprint, getFailure, getQueryString } from 'helpers';
import { Articles } from 'store';
import {
  Article,
  RequestPayload,
  Response,
  SearchRequest,
  SearchResult,
} from 'types';

import { callApi } from './base';

/**
 * Sends a GET request to the get articles API endpoint.
 * @param {RequestPayload<string>} data - a RequestPayload object containing the page number.
 * @returns {Promise<Response>}
 */
export const searchArticles = async (
  data: RequestPayload<SearchRequest>
): Promise<Response<SearchResult<Articles>>> => {
  const { payload: searchRequest } = data;
  const { page, query = [] } = searchRequest;

  try {
    const response = await callApi({
      url: `${config.apiUrl}api/articles/search/${page}${getQueryString(query)}`,
    });
    const { data } = (response ?? {}) as { [key: string]: any };
    return data as Response<SearchResult<Articles>>;
  } catch (error) {
    console.log('[DEBUG] getArticles with error', error);
    return getFailure(ERROR_MESSAGES.UNKNOWN_ERROR);
  }
};

/**
 * Sends a GET request to the get article API endpoint.
 * @param {RequestPayload<string>} data - a RequestPayload object containing the article ID.
 * @returns {Promise<Response>}
 */
export const getSingleArticle = async (
  data: RequestPayload<string>
): Promise<Response<Article | null>> => {
  const { payload: articleId } = data;
  try {
    if (!articleId) {
      return getFailure(ERROR_MESSAGES.INVALID_REQUEST);
    }
    const deviceId = await getDeviceFingerprint();
    const response = await callApi({
      url: `${config.apiUrl}api/articles/select/${articleId}/${deviceId}`,
    });
    const { data } = (response ?? {}) as { [key: string]: any };
    return data as Response<Article | null>;
  } catch (error) {
    console.log('[DEBUG] getSingleArticle with error', error);
    return getFailure(ERROR_MESSAGES.UNKNOWN_ERROR);
  }
};
