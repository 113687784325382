import React from 'react';
import { CardsSearchBar } from 'components/core';

import './styles.scss';

const CardsSearch: React.ForwardRefRenderFunction<HTMLDivElement> = (
  _props,
  ref
) => {
  return (
    <div className="cardsSearch" ref={ref}>
      <CardsSearchBar />
    </div>
  );
};

export default React.forwardRef(CardsSearch);
