import { PayloadAction } from '@reduxjs/toolkit';
import { ERROR_MESSAGES, RESPONSE_CODES } from 'appConstants';
import { call, put } from 'redux-saga/effects';
import { getSingleArticle, searchArticles } from 'services';
import { Articles, articlesActions } from 'store';
import { Article, Response, SearchRequest, SearchResult } from 'types';

/**
 * Saga generator function called to get articles.
 * @param {PayloadAction<number>} data - a PayloadAction object containing the query page number.
 * @returns {Response} Response object.
 */
export function* getArticles(data: PayloadAction<SearchRequest>) {
  const { payload } = data;

  try {
    yield put(articlesActions.request());
    yield put(articlesActions.setSearchRequest(payload));

    const response: Response<Articles> = yield call(searchArticles, {
      payload,
    });
    const { code, data } = response;
    if (code === RESPONSE_CODES.SUCCESS && !!data && typeof data === 'object') {
      const articles = data as SearchResult<Articles>;
      yield put(articlesActions.setArticles(articles));
      return response;
    }

    const { message } = response;
    if (!data) {
      if (message !== 'Success') {
        yield put(articlesActions.failure(message));
      } else {
        yield put(articlesActions.failure(ERROR_MESSAGES.EMPTY_SEARCH_RESULTS));
      }
    }
    return response;
  } catch (error) {
    yield put(articlesActions.failure(ERROR_MESSAGES.UNKNOWN_ERROR));
    console.log('[DEBUG:SAGAS] getArticles', error);
    return null;
  }
}

/**
 * Saga generator function called to get a single article.
 * @param {PayloadAction<string>} data - a PayloadAction object containing the article ID.
 * @returns {Response} Response object.
 */
export function* getArticle(data: PayloadAction<string>) {
  const { payload } = data;

  try {
    yield put(articlesActions.request());

    const response: Response<Article | null> = yield call(getSingleArticle, {
      payload,
    });
    const { code, data } = response;
    if (code === RESPONSE_CODES.SUCCESS && !!data && typeof data === 'object') {
      yield put(articlesActions.selectArticle(data as Article));
      return response;
    }

    const { message } = response;
    if (!data) {
      if (message !== 'Success') {
        yield put(articlesActions.failure(message));
      } else {
        yield put(
          articlesActions.failure(ERROR_MESSAGES.ARTICLE_RETRIEVAL_FAILED)
        );
      }
    }
    return response;
  } catch (error) {
    yield put(articlesActions.failure(ERROR_MESSAGES.UNKNOWN_ERROR));
    console.log('[DEBUG:SAGAS] getArticle', error);
    return null;
  }
}
