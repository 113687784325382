import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { withPayloadType } from 'helpers';
import {
  AuthenticatedUser,
  Errors,
  GetUserPayload,
  UpdateUserPayload,
  User,
} from 'types';

export type UsersState = {
  refreshToken: string | null;
  token: string | null;
  user: User | null;
  errors?: Errors;
  fetching: boolean;
};

const initialState: UsersState = {
  refreshToken: null,
  token: null,
  user: null,
  errors: undefined,
  fetching: false,
};

const usersSlices = createSlice({
  name: 'users',
  initialState,
  reducers: {
    failure: (state, action: PayloadAction<Errors>) => {
      state.errors = action.payload;
      state.fetching = false;
    },
    request: (state) => {
      state.errors = undefined;
      state.fetching = true;
    },
    reset: (state) => {
      state.errors = undefined;
      state.fetching = false;
    },
    setTokens: (state, action: PayloadAction<AuthenticatedUser>) => {
      state.errors = undefined;
      state.fetching = false;
      state.refreshToken = action.payload.refreshToken;
      state.token = action.payload.token;
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.errors = undefined;
      state.fetching = false;
      state.user = action.payload;
    },
  },
});

export const usersActions = {
  ...usersSlices.actions,
  getUser: createAction('users/getUser', withPayloadType<GetUserPayload>()),
  login: createAction('users/login', withPayloadType<User>()),
  logout: createAction('users/logout', withPayloadType<string>()),
  refreshToken: createAction('users/refreshToken', withPayloadType<string>()),
  register: createAction('users/register', withPayloadType<User>()),
  update: createAction('users/update', withPayloadType<UpdateUserPayload>()),
};

export default usersSlices.reducer;
