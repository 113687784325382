import React, { memo, useCallback, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { TWO_FACED_CARDS } from 'appConstants';
import { Button, ImageLoader } from 'components/core';
import { getCdnAssetUrl } from 'helpers';
import { useRouteParams } from 'hooks';
import { Card, CardBase, CardSynergy } from 'types';

import './styles.scss';

const backImg = getCdnAssetUrl('card-back.webp');

type Props = {
  articleId?: string;
  card: Card | CardSynergy | CardBase;
  redirectUrl?: string;
  renderAs?: 'image' | 'details';
  styles?: string[];
  onPress?: () => void;
};

const CardImage: React.FC<Props> = ({
  articleId,
  card,
  redirectUrl,
  renderAs = 'image',
  styles = [],
  onPress,
}) => {
  const navigate = useNavigate();

  const { cardDetails } = useRouteParams();
  const { side: cardSide = '' } = cardDetails ?? {};

  const { imageUrls, layout = '', otherFaceIds = [], side, uuid } = card;
  const cardTransformation = (card as Card)?.transformation;

  const ref = useRef<HTMLImageElement | null>(null);

  const [cardId, setCardId] = useState(uuid);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageUrl, setImageUrl] = useState(card.imageUrls.scryfall);
  const [queryString, setQueryString] = useState('');

  const flip = useCallback(
    async () => {
      if (renderAs === 'image') {
        if (cardTransformation) {
          setCardId((prev) => {
            if (prev === uuid) {
              return cardTransformation.uuid;
            }
            return uuid;
          });
          setImageUrl((prev) => {
            if (prev === imageUrls.scryfall) {
              return cardTransformation.imageUrls.scryfall;
            }
            return imageUrls.scryfall;
          });
          setQueryString((prev) => (prev === 'back' ? '' : 'back'));
          return;
        }

        setCardId(uuid);
        setImageUrl(imageUrls.scryfall);
        setQueryString((prev) => (prev === 'back' ? '' : 'back'));
        return;
      }

      if (cardTransformation) {
        navigate(`/card/${cardTransformation.uuid}/back`, {
          replace: true,
        });
        return;
      }

      if (layout === 'meld' && cardSide !== 'back') {
        navigate(`/card/${uuid}/back`, { replace: true });
        return;
      }

      navigate(
        `/card/${cardSide === 'back' && layout === 'meld' ? uuid : otherFaceIds[0]}`,
        {
          replace: true,
        }
      );
    },
    /* eslint-disable react-hooks/exhaustive-deps */ [
      cardSide,
      imageUrls.scryfall,
      JSON.stringify(cardTransformation),
      layout,
      JSON.stringify(otherFaceIds),
      renderAs,
      uuid,
      navigate,
    ]
  );

  return (
    <div
      className={`cardImage${styles.length > 0 ? ` ${styles.join(' ')}` : ''}`}
    >
      {renderAs === 'image' ? (
        <Link
          to={`/card/${cardId}/${queryString}${articleId ? `?redirect=/article/${articleId}` : redirectUrl ? `?redirect=${redirectUrl}` : ''}`}
        >
          <ImageLoader
            fallbackSrc={backImg}
            inline
            onLoad={setImageLoaded}
            ref={ref}
            src={(renderAs === 'image' ? imageUrl : imageUrls.scryfall) || ''}
            styles={{ container: 'cardImageContainer' }}
          />
        </Link>
      ) : (
        <>
          {!onPress ? (
            <span className="default">
              <ImageLoader
                fallbackSrc={backImg}
                inline
                onLoad={setImageLoaded}
                ref={ref}
                src={
                  cardSide === 'back' && layout === 'meld'
                    ? imageUrls?.scryfallCardBack || imageUrls?.scryfall || ''
                    : imageUrls?.scryfall || ''
                }
                styles={{ container: 'cardImageContainer' }}
              />
            </span>
          ) : (
            <span className="default clickable">
              <Button onClick={onPress}>
                <ImageLoader
                  fallbackSrc={backImg}
                  inline
                  onLoad={setImageLoaded}
                  ref={ref}
                  src={
                    cardSide === 'back' && layout === 'meld'
                      ? imageUrls?.scryfallCardBack || imageUrls?.scryfall || ''
                      : imageUrls?.scryfall || ''
                  }
                  styles={{ container: 'cardImageContainer' }}
                />
              </Button>
            </span>
          )}
        </>
      )}
      {!!side &&
        (!!cardTransformation ||
          (otherFaceIds.length > 0 && otherFaceIds.length < 2)) &&
        TWO_FACED_CARDS.includes(layout || '') &&
        !(layout === 'meld' && renderAs === 'image') &&
        imageLoaded &&
        !(onPress && renderAs === 'details') && (
          <Button styles={['transform']} onClick={flip}>
            <span className="icon-flip" />
          </Button>
        )}
    </div>
  );
};

export default memo(
  CardImage,
  (prevProps, nextProps) => prevProps.card.uuid === nextProps.card.uuid
);
