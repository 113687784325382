import { useEffect } from 'react';
import { useDivRef } from 'hooks';

export const useGalleryClass = (shouldAddClass: boolean) => {
  const cardsSearchRef = useDivRef();

  useEffect(() => {
    if (cardsSearchRef) {
      const cssClasses = cardsSearchRef.current?.classList;
      if (cssClasses !== undefined) {
        if (shouldAddClass) {
          if (!cssClasses.contains('gallery')) {
            cardsSearchRef.current?.classList.add('gallery');
          }
        } else {
          if (cssClasses.contains('gallery')) {
            cardsSearchRef.current?.classList.remove('gallery');
          }
        }
      }
    }
  }, [cardsSearchRef, shouldAddClass]);
};
