import React, { memo } from 'react';
import { ImageLoader } from 'components/core';
import { AmazonAffiliateProduct } from 'types';

import './styles.scss';

type Props = {
  imageWidth: number;
  product: AmazonAffiliateProduct;
  visible: boolean;
};

const ProductItem: React.FC<Props> = ({ imageWidth, product, visible }) => (
  <div className={`productItem${visible ? ' visible' : ''}`}>
    <a href={product.link} rel="noopener noreferrer" target="_blank">
      <ImageLoader
        inline
        src={product.imageUrl}
        styleOverride={{
          paddingBottom: `${parseInt(
            `${
              product.imageAspectRatio < 1
                ? Math.floor(imageWidth * product.imageAspectRatio)
                : Math.floor(imageWidth / product.imageAspectRatio)
            }`
          )}px`,
        }}
      />
    </a>
  </div>
);

export default memo(ProductItem);
