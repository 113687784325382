import React, {
  ChangeEvent,
  FocusEventHandler,
  memo,
  useCallback,
  useState,
} from 'react';
import { isEqual } from 'lodash';
import { ErrorMessages } from 'components/core';
import { Option } from 'types';

import chevron from '../../../assets/images/chevron-small.webp';
import './styles.scss';

type Props = {
  defaultOptionLabel: string;
  disabled?: boolean;
  id: string;
  errors?: string[];
  hideErrorMessages?: boolean;
  label: string;
  options: Option[];
  styles?: string[];
  value: string | number;
  onBlur?: FocusEventHandler<HTMLSelectElement>;
  onFocus?: FocusEventHandler<HTMLSelectElement>;
  // eslint-disable-next-line no-unused-vars
  setValue: (value: string) => void;
};

const Dropdown: React.FC<Props> = ({
  defaultOptionLabel,
  disabled,
  errors = [],
  id,
  label,
  options,
  styles,
  value,
  hideErrorMessages = false,
  onBlur,
  onFocus,
  setValue,
}) => {
  const selected = options.find((option) => option.value === value);
  const isDefault = selected === undefined;

  const [isFocused, setIsFocused] = useState(false);

  const getLabel = useCallback((option: Option) => option.label, []);

  const getValue = useCallback((option: Option) => option.value, []);

  const onSetIsFocused = () => {
    if (errors?.length && !isFocused) {
      setIsFocused(true);
    }
  };

  const onSelectChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const selectedValue = (event.target as HTMLSelectElement)?.value || '';
      setValue(selectedValue);
      setIsFocused(false);
    },
    [setValue]
  );

  return (
    <div className="dropdown">
      <label htmlFor={id}>{label}</label>
      <select
        id={id}
        className={`dropdown selectContainer${isDefault ? ' default' : ''}${styles ? ` ${styles.join(' ')}` : ''}${
          errors.length > 0 ? ' withErrors' : ''
        }${isFocused ? ' withFocus' : ''}`}
        onChange={onSelectChange}
        onFocus={onFocus || onSetIsFocused}
        onBlur={onBlur}
        disabled={disabled}
        style={{ backgroundImage: `url(${chevron})` }}
        value={value}
      >
        {defaultOptionLabel ? (
          <option className="defaultOption" value="">
            {defaultOptionLabel}
          </option>
        ) : null}
        {options
          ? options.map((choice, i) =>
              typeof choice === 'object' ? (
                <option key={i} value={getValue(choice)}>
                  {`${getLabel(choice)}`}
                </option>
              ) : (
                <option key={i} value={choice}>
                  {choice}
                </option>
              )
            )
          : null}
      </select>
      {!hideErrorMessages ? <ErrorMessages errors={errors} /> : null}
    </div>
  );
};

export default memo(
  Dropdown,
  (prevProps, nextProps) =>
    prevProps.value === nextProps.value &&
    isEqual(prevProps.setValue, nextProps.setValue)
);
