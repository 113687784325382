import React from 'react';
import { Cards } from 'store';
import { CardImage, Loader } from 'components/core';

import './styles.scss';

type Props = {
  cards: Cards;
  fetching: boolean;
  label: string;
};

const CardsGallery: React.FC<Props> = ({ cards, fetching, label }) =>
  cards.length > 0 && (
    <div className="cardsGallery">
      <div className="overlay" />
      {fetching ? (
        <Loader type="component" />
      ) : (
        <>
          <h3>{label}</h3>
          <div className="container">
            {cards.map((card) => (
              <div className="column" key={card.uuid}>
                <CardImage card={card} redirectUrl="/" />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );

export default CardsGallery;
