import { ERROR_MESSAGES } from 'appConstants';
import { config } from 'config';
import { getFailure } from 'helpers';
import { AmazonAffiliateProducts } from 'store';
import { Response, SearchResult } from 'types';

import { callApi } from '../base';

/**
 * Fetches a list of Amazon affiliate products from the API.
 * @returns {Promise<Response<SearchResult<AmazonAffiliateProducts>>>}
 * A promise resolving to a response containing the search results for Amazon affiliate products.
 * If the request fails, it returns a failure response with an unknown error message.
 */
export const getProducts = async (): Promise<
  Response<SearchResult<AmazonAffiliateProducts>>
> => {
  try {
    const response = await callApi({
      url: `${config.apiUrl}api/affiliates/amazon`,
    });
    const { data } = (response ?? {}) as { [key: string]: any };
    return data as Response<SearchResult<AmazonAffiliateProducts>>;
  } catch (error) {
    console.log('[DEBUG] getProducts with error', error);
    return getFailure(ERROR_MESSAGES.UNKNOWN_ERROR);
  }
};
