import { ERROR_MESSAGES } from 'appConstants';
import { config } from 'config';
import { getFailure } from 'helpers';
import { Sets } from 'store';
import { Response } from 'types';

import { callApi } from './base';

/**
 * Sends a GET request to the get sets API endpoint.
 * @returns {Promise<Response>}
 */
export const getSets = async (): Promise<Response<Sets>> => {
  try {
    const response = await callApi({
      url: `${config.apiUrl}api/sets`,
    });
    const { data } = (response ?? {}) as { [key: string]: any };
    return data as Response<Sets>;
  } catch (error) {
    console.log('[DEBUG] getSets with error', error);
    return getFailure(ERROR_MESSAGES.UNKNOWN_ERROR);
  }
};
