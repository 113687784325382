/**
 * Gets the string value of a query parameter from the query URL string.
 * @param key - key of the query parameter
 * @returns the string value of a query parameter
 */
export const getQueryStringValue = (key: string) => {
  try {
    const splitUrl = window.location.href.split('?');
    if (splitUrl.length > 1) {
      const urlParams = new URLSearchParams(`?${splitUrl[1]}`);

      for (const [name, value] of urlParams) {
        if (name.toLowerCase() === key.toLowerCase()) {
          return value.replace(/ /g, '%2B');
        }
      }
    }
    return null;
  } catch {
    return null;
  }
};
