import { ERROR_MESSAGES, EVENT_ACTION } from 'appConstants';
import { config } from 'config';
import { getDeviceFingerprint, getFailure, getQueryString } from 'helpers';
import { Cards, CardSynergies } from 'store';
import {
  Card,
  RequestPayload,
  Response,
  SearchRequest,
  SearchResult,
  SynergyRequest,
} from 'types';

import { callApi } from './base';

/**
 * Sends a GET request to the get cards API endpoint.
 * @param {RequestPayload<SearchRequest>} data - a RequestPayload object containing the query page number, as well as the query and body params.
 * @returns {Promise<Response>}
 */
export const getCardsByFilters = async (
  data: RequestPayload<SearchRequest>
): Promise<Response<SearchResult<Cards>>> => {
  const { payload: searchRequest } = data;
  const { body = {}, page, query = [] } = searchRequest;

  try {
    if (page < 0) {
      return getFailure(ERROR_MESSAGES.INVALID_REQUEST);
    }
    const deviceId = await getDeviceFingerprint();
    const response = await callApi({
      url: `${config.apiUrl}api/cards/${page}/${deviceId}${getQueryString(query)}`,
      method: 'POST',
      data: body,
    });
    const { data } = (response ?? {}) as { [key: string]: any };
    return data as Response<SearchResult<Cards>>;
  } catch (error) {
    console.log('[DEBUG] getCards with error', error);
    return getFailure(ERROR_MESSAGES.UNKNOWN_ERROR);
  }
};

/**
 * Sends a GET request to the get singles API endpoint.
 * @param {RequestPayload<string>} data - a RequestPayload object containing the card ID.
 * @param isCardView - a boolean that determines whether or not the card fetch is tracked as a view event.
 * @returns {Promise<Response>}
 */
export const getSingle = async (
  data: RequestPayload<string>,
  isCardView: boolean = true
): Promise<Response<Card | null>> => {
  const { payload: cardId } = data;
  try {
    if (!cardId) {
      return getFailure(ERROR_MESSAGES.INVALID_REQUEST);
    }
    const deviceId = await getDeviceFingerprint();
    const response = await callApi({
      url: `${config.apiUrl}api/cards/singles/${cardId}/${deviceId}${isCardView ? getQueryString([{ name: 'action', value: `${EVENT_ACTION.VIEW}` }]) : ''}`,
    });
    const { data } = (response ?? {}) as { [key: string]: any };
    return data as Response<Card | null>;
  } catch (error) {
    console.log('[DEBUG] getSingle with error', error);
    return getFailure(ERROR_MESSAGES.UNKNOWN_ERROR);
  }
};

/**
 * Sends a GET request to the get card similarity suggestions API endpoint.
 * @param {RequestPayload<SynergyRequest>} data - a RequestPayload object containing the SynergyRequest object.
 * @returns {Promise<Response>}
 */
export const getSimilaritySuggestions = async (
  data: RequestPayload<SynergyRequest>
): Promise<Response<SearchResult<CardSynergies>>> => {
  const { payload } = data;
  const { format, name: cardName, text: cardText } = payload;
  try {
    if (!cardName) {
      return getFailure(ERROR_MESSAGES.INVALID_REQUEST);
    }
    const response = await callApi({
      url: `${config.apiUrl}api/cards/similar`,
      method: 'POST',
      data: {
        cardName,
        cardText,
        format,
      },
    });
    const { data } = (response ?? {}) as { [key: string]: any };
    return data as Response<SearchResult<CardSynergies>>;
  } catch (error) {
    console.log('[DEBUG] getSimilaritySuggestions with error', error);
    return getFailure(ERROR_MESSAGES.UNKNOWN_ERROR);
  }
};

/**
 * Sends a GET request to the get card synergy suggestions API endpoint.
 * @param {RequestPayload<SynergyRequest>} data - a RequestPayload object containing the SynergyRequest object.
 * @returns {Promise<Response>}
 */
export const getSynergySuggestions = async (
  data: RequestPayload<SynergyRequest>
): Promise<Response<SearchResult<CardSynergies>>> => {
  const { payload } = data;
  const { format, name: cardName, text: cardText } = payload;
  try {
    if (!cardName) {
      return getFailure(ERROR_MESSAGES.INVALID_REQUEST);
    }
    const response = await callApi({
      url: `${config.apiUrl}api/cards/synergy`,
      method: 'POST',
      data: {
        cardName,
        cardText,
        format,
      },
    });
    const { data } = (response ?? {}) as { [key: string]: any };
    return data as Response<SearchResult<CardSynergies>>;
  } catch (error) {
    console.log('[DEBUG] getSynergySuggestions with error', error);
    return getFailure(ERROR_MESSAGES.UNKNOWN_ERROR);
  }
};
