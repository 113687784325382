import { useEffect, useRef, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { appActions, RootState } from 'store';

import { useReduxHooks } from './useReduxHooks';

export const useScrollTop = () => {
  const articlesMatch = useMatch({ path: '/articles', end: true });
  const searchMatch = useMatch({ path: '/search', end: true });

  const { useReduxDispatch: useDispatch, useReduxSelector: useSelector } =
    useReduxHooks() ?? {};
  const scrollTop =
    useSelector?.((state: RootState) => state.app.scrollTop) ?? 0;
  const dispatch = useDispatch?.();

  const hasListener = useRef(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const [isScrolling, setIsScrolling] = useState(false);

  const handleScroll = () => {
    const appDiv = document.getElementById('app');
    if (!(articlesMatch || searchMatch)) {
      return;
    }
    setIsScrolling(true);
    if (!appDiv) {
      setIsScrolling(false);
      return;
    }
    if (appDiv.scrollTop < 100) {
      setIsScrolling(false);
      return;
    }
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      dispatch?.({
        type: appActions.setScrollTop.type,
        payload: appDiv.scrollTop,
      });
      setIsScrolling(false);
    }, 100);
  };

  useEffect(
    () => {
      if (!hasListener.current) {
        hasListener.current = true;
        const appDiv = document.getElementById('app');
        if (!appDiv) {
          return;
        }
        appDiv.addEventListener('scroll', handleScroll);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    const appDiv = document.getElementById('app');
    if (appDiv) {
      if (appDiv.scrollTop !== scrollTop && scrollTop > 100) {
        appDiv.scrollTop = scrollTop;
      }
    }
  }, [scrollTop]);

  return { isScrolling };
};
