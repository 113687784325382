import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Errors, SearchResult } from 'types';
import { Articles, Cards } from 'store';
import { withPayloadType } from 'helpers';

export type EventsState = {
  errors?: Errors;
  fetchingMostSearchedCards: boolean;
  fetchingMostViewedArticles: boolean;
  fetchingMostViewedCards: boolean;
  lastMostSearchedCardsFetch: number | null;
  lastMostViewedArticlesFetch: number | null;
  lastMostViewedCardsFetch: number | null;
  mostSearchedCards: SearchResult<Cards> | null;
  mostViewedArticles: SearchResult<Articles> | null;
  mostViewedCards: SearchResult<Cards> | null;
};

const initialState: EventsState = {
  errors: undefined,
  fetchingMostSearchedCards: false,
  fetchingMostViewedArticles: false,
  fetchingMostViewedCards: false,
  lastMostSearchedCardsFetch: null,
  lastMostViewedArticlesFetch: null,
  lastMostViewedCardsFetch: null,
  mostSearchedCards: null,
  mostViewedArticles: null,
  mostViewedCards: null,
};

const eventsSlices = createSlice({
  name: 'events',
  initialState,
  reducers: {
    failure: (state, action: PayloadAction<Errors>) => {
      state.errors = action.payload;
      state.fetchingMostSearchedCards = false;
      state.fetchingMostViewedArticles = false;
      state.fetchingMostViewedCards = false;
    },
    requestMostSearchedCards: (state) => {
      state.errors = undefined;
      state.fetchingMostSearchedCards = true;
    },
    requestMostViewedArticles: (state) => {
      state.errors = undefined;
      state.fetchingMostViewedArticles = true;
    },
    requestMostViewedCards: (state) => {
      state.errors = undefined;
      state.fetchingMostViewedCards = true;
    },
    reset: (state) => {
      state.errors = undefined;
      state.fetchingMostSearchedCards = false;
      state.fetchingMostViewedArticles = false;
      state.fetchingMostViewedCards = false;
    },
    setMostSearchedCards: (
      state,
      action: PayloadAction<SearchResult<Cards> | null>
    ) => {
      state.errors = undefined;
      state.fetchingMostSearchedCards = false;
      state.lastMostSearchedCardsFetch = new Date().getTime();
      state.mostSearchedCards = action.payload;
    },
    setMostViewedArticles: (
      state,
      action: PayloadAction<SearchResult<Articles> | null>
    ) => {
      state.errors = undefined;
      state.fetchingMostViewedArticles = false;
      state.lastMostViewedArticlesFetch = new Date().getTime();
      state.mostViewedArticles = action.payload;
    },
    setMostViewedCards: (
      state,
      action: PayloadAction<SearchResult<Cards> | null>
    ) => {
      state.errors = undefined;
      state.fetchingMostViewedCards = false;
      state.lastMostViewedCardsFetch = new Date().getTime();
      state.mostViewedCards = action.payload;
    },
  },
});

export const eventsActions = {
  ...eventsSlices.actions,
  getMostSearchedCards: createAction(
    'events/getMostSearchedCards',
    withPayloadType<number | null>()
  ),
  getMostViewedArticles: createAction(
    'events/getMostViewedArticles',
    withPayloadType<number | null>()
  ),
  getMostViewedCards: createAction(
    'events/getMostViewedCards',
    withPayloadType<number | null>()
  ),
};

export default eventsSlices.reducer;
