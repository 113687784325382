import React, { useCallback } from 'react';
import { Button } from 'components/core';
import { deleteStore } from 'helpers';

import './styles.scss';

const Troubleshoot: React.FC = () => {
  const clearCache = useCallback(() => {
    deleteStore();
    window.location.reload();
  }, []);

  return (
    <div className="troubleshoot hidden" id="troubleshoot">
      <div>
        <p>
          {
            "If you're experiencing issues loading the web app, try clearing the app's cache by pressing the button below."
          }
        </p>
        <Button onClick={clearCache} styles={['troubleshootButton']}>
          <span className="icon-cache" />
          Clear Cache & Reload
        </Button>
      </div>
      <div>
        <p>
          {'To report an issue or bug in the app, please email us at: '}
          <a className="email" href="mailto:help.tapthat.land@gmail.com">
            help.tapthat.land@gmail.com
          </a>
        </p>
      </div>
    </div>
  );
};

export default Troubleshoot;
