import { ERROR_MESSAGES, RESPONSE_CODES } from 'appConstants';
import { call, put } from 'redux-saga/effects';
import { getSets } from 'services';
import { setsActions, Sets } from 'store';
import { Response } from 'types';

/**
 * Saga generator function called to get all Magic: The Gathering sets.
 * @returns {Response} Response object.
 */
export function* getAllSets() {
  try {
    yield put(setsActions.request());

    const response: Response<Sets> = yield call(getSets);
    const { code, data } = response;
    if (code === RESPONSE_CODES.SUCCESS && !!data && typeof data === 'object') {
      if (!Array.isArray(data)) {
        const sets = data.data as Sets;
        if ((sets?.length ?? 0) > 0) {
          yield put(
            setsActions.setSets(
              sets.sort(
                (a, b) =>
                  new Date(b.releaseDate).getTime() -
                  new Date(a.releaseDate).getTime()
              )
            )
          );
          return response;
        }
      }
    }

    const { message } = response;
    yield put(setsActions.failure(message));
    return response;
  } catch (error) {
    yield put(setsActions.failure(ERROR_MESSAGES.UNKNOWN_ERROR));
    console.log('[DEBUG:SAGAS] getAllSets', error);
    return null;
  }
}
